import { DATA_TYPES } from 'common/types';
import { updateSelectedZone, updateSelectedObject } from 'utils/reducerHelpers';

// Table Headers data formaters
const formatNewGaiaObjectData = (item, params, data) => {
  let tempData = data;
  if (tempData === null) {
    tempData = item.old_gaia_object;
    if (tempData === undefined || tempData === null) {
      tempData = '';
    }
  }
  if (typeof tempData === 'object' && tempData !== {}) {
    tempData = tempData.label || tempData.RA_libelle;
  } else {
    tempData = '';
  }
  return tempData;
};

const formatGroupsData = (item, params, data) => data.split(' - ')[1];

const formatUsersData = (item, params, data, itemsList) => {
  let tempData = data;
  if (itemsList.length !== 0 && item[params.key] !== null) {
    const filteredItem = itemsList.filter((itemList) => itemList.user_id === item[params.key]);
    if (filteredItem.length !== 0) {
      tempData = `${filteredItem[0].last_name} ${filteredItem[0].first_name}`;
    }
  } else {
    tempData = '';
  }

  return tempData;
};

export const ZONE_TABLE_HEADERS = [
  {
    key: 'name',
    label: 'Logs.tableHeaders.name',
    data: [{
      type: DATA_TYPES.string,
    }],
    onClick: updateSelectedZone,
  },
  {
    key: 'group',
    label: 'Logs.tableHeaders.group',
    data: [{
      type: DATA_TYPES.string,
      path: ['group', 'name'],
    }],
  },
  {
    key: 'created',
    label: 'Logs.tableHeaders.creationDate',
    data: [{
      type: DATA_TYPES.date,
    }],
  },
  {
    key: 'status',
    label: 'Logs.tableHeaders.status',
    data: [{
      type: DATA_TYPES.status,
    }],
  },
  {
    key: 'owner_id',
    label: 'Logs.tableHeaders.owner',
    data: [{
      type: DATA_TYPES.string,
      formatter: formatUsersData,
    }],
    isAdmin: true,
  },
  {
    key: 'supervisor_id',
    label: 'Logs.tableHeaders.supervisor',
    data: [{
      type: DATA_TYPES.string,
      formatter: formatUsersData,
    }],
    isAdmin: true,
  },
];

export const HISTORY_TABLE_HEADERS = [
  {
    key: 'user',
    label: 'Logs.tableHeaders.user',
    data: [{
      type: DATA_TYPES.string,
      formatter: formatUsersData,
    }],
    isAdmin: true,
  },
  {
    key: 'zone',
    label: 'Logs.tableHeaders.zone',
    data: [{
      type: DATA_TYPES.string,
    }],
    isAdmin: true,
  },
  {
    key: 'new_gaia_object',
    label: 'Logs.tableHeaders.object',
    data: [{
      type: DATA_TYPES.object,
      formatter: formatNewGaiaObjectData,
    }],
    onClick: updateSelectedObject,
  },
  {
    key: 'action_type',
    label: 'Logs.tableHeaders.action',
    data: [{
      type: DATA_TYPES.action,
    }],
  },
  {
    key: 'datetime',
    label: 'Logs.tableHeaders.date',
    data: [{
      type: DATA_TYPES.date,
    }],
  },
];

export const PROFILE_TABLE_HEADERS = [
  {
    key: 'last_name',
    label: 'Logs.tableHeaders.lastName',
    data: [{
      type: DATA_TYPES.string,
    }],
  },
  {
    key: 'first_name',
    label: 'Logs.tableHeaders.firstName',
    data: [{
      type: DATA_TYPES.string,
    }],
  },
  {
    key: 'email',
    label: 'Logs.tableHeaders.email',
    data: [{
      type: DATA_TYPES.string,
    }],
  },
];
