import React from 'react';
import PropTypes from 'prop-types';
import {
  Source, Layer,
} from 'react-map-gl';
import Hover from 'common/Map/Hover';
import Selected from 'common/Map/Selected';
import { MAP_MODES } from 'common/Map/const';
import { connect } from 'react-redux';
import { MIDI_OBJECTS_KEYS, getMidiObjectByKey } from 'common/Map/Consts/MidiObjects';

const HIDE_SIBLINGS_KEYS = [
  MIDI_OBJECTS_KEYS.zep,
  MIDI_OBJECTS_KEYS.sel,
  MIDI_OBJECTS_KEYS.itineraire,
];

class LinearObject extends React.Component {
  static propTypes = {
    map: PropTypes.object.isRequired,
    mapURL: PropTypes.string.isRequired,
    sourceLayer: PropTypes.string.isRequired,
    sourceTable: PropTypes.string.isRequired,
    mapMode: PropTypes.string,
    source: PropTypes.string,
    color: PropTypes.string,
    width: PropTypes.number,
  }

  static defaultProps = {
    color: '#cd0037',
    width: 2,
    source: 'midi',
    mapMode: MAP_MODES.display,
  }

  line = () => {
    const {
      sourceTable, color, width, mapMode, map,
    } = this.props;

    const sourcesToHide = HIDE_SIBLINGS_KEYS.map((key) => getMidiObjectByKey(key).sourceTable);
    let isSourceTableRequired = false;
    if (map.selectedProperty) {
      const selectedPropertySource = getMidiObjectByKey(map.selectedProperty.requiredLayer).sourceTable;
      isSourceTableRequired = selectedPropertySource === sourceTable;
    }

    const hideSiblings = map.selectedObjectLayer !== undefined
                      && map.selectedObjectLayer.sourceTable === sourceTable
                      && map.featureInfoClickID !== undefined
                      && sourcesToHide.includes(sourceTable)
                      && !isSourceTableRequired;

    let paint = {
      'line-color': color,
      'line-width': width,
    };
    if (mapMode === MAP_MODES.modification) {
      paint = {
        'line-color': [
          'case',
          ['!=', ['get', 'isVerifie'], ['string', '[]']], '#82be00',
          color,
        ],
        'line-width': [
          'case',
          ['!=', ['get', 'isVerifie'], ['string', '[]']], 3,
          width,
        ],
      };
    }

    const layerProps = {
      id: `${sourceTable}Layer`,
      type: 'line',
      'source-layer': sourceTable,
      paint,
    };

    if (hideSiblings) {
      layerProps.filter = ['==', 'OP_id', map.popupContent.OP_id];
    }

    return layerProps;
  }

  label = () => {
    const {
      sourceTable, mapMode,
    } = this.props;

    const layout = {
      'symbol-placement': 'line-center',
      'text-font': ['Roboto Condensed'],
      'text-anchor': 'bottom',
      'text-field': '{RA_libelle}',
      'text-size': 16,
    };

    let paint;
    if (mapMode === MAP_MODES.modification) {
      paint = {
        'text-color': [
          'case',
          ['!=', ['get', 'isVerifie'], ['string', '[]']], '#82BE00',
          '#DC143C',
        ],
        'text-halo-width': 2,
        'text-halo-color': 'rgba(255,255,255,1)',
        'text-halo-blur': 2,
      };
    } else {
      paint = {
        'text-color': '#DC143C',
        'text-halo-width': 2,
        'text-halo-color': 'rgba(255,255,255,1)',
        'text-halo-blur': 2,
      };
    }

    const layerProps = {
      id: `${sourceTable}Layer-label`,
      type: 'symbol',
      'source-layer': sourceTable,
      layout,
      paint,
    };

    return layerProps;
  }

  render() {
    const {
      mapURL, sourceLayer, sourceTable, source,
    } = this.props;

    return (
      <Source id={`${sourceTable}-${sourceLayer}-source`} type="vector" url={`${mapURL}/chartisv1/layer/${sourceTable}/mvt/${sourceLayer}/`}>
        <Layer {...this.line()} />
        {sourceTable === 'map_midi_brancheapv' && <Layer {...this.label()} />}
        <Selected sourceLayer={sourceTable} filterField="OP_id" />
        <Hover sourceLayer={sourceTable} filterField="OP_id" />
      </Source>
    );
  }
}

const mapStateToProps = (state) => ({
  map: state.map,
});

const mapDispatchToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(LinearObject);
