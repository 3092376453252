import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Layer } from 'react-map-gl';
import { hoverLayer, hoverCircleLayer } from 'common/Map/Layers/commonlayers.json';

const Hover = ({
  filterField, type, source, sourceLayer,
}) => {
  const map = useSelector((state) => state.map);

  if (map.featureInfoHoverID) {
    const id = map.featureInfoHoverID;
    const hoverLayerRender = type === 'line'
      ? {
        ...hoverLayer,
        id: `${sourceLayer}HoverLayer`,
        filter: ['==', filterField, id],
      }
      : {
        ...hoverCircleLayer,
        id: `${sourceLayer}HoverLayer`,
        filter: ['==', filterField, id],
      };

    return (
      <Layer
        {...hoverLayerRender}
        source={source}
        source-layer={sourceLayer}
      />
    );
  }
  return null;
};

Hover.propTypes = {
  source: PropTypes.string,
  sourceLayer: PropTypes.string.isRequired,
  filterField: PropTypes.string,
  isGeoJSON: PropTypes.bool,
  type: PropTypes.oneOf([
    'line',
    'circle',
  ]),
};

Hover.defaultProps = {
  source: undefined,
  filterField: 'id',
  type: 'line',
  isGeoJSON: false,
};

export default Hover;
