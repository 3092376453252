import React from 'react';
import PropTypes from 'prop-types';
import {
  Source, Layer,
} from 'react-map-gl';
import Hover from 'common/Map/Hover';
import Selected from 'common/Map/Selected';

export default class JointsDeZones extends React.Component {
  static propTypes = {
    mapURL: PropTypes.string.isRequired,
    sourceLayer: PropTypes.string.isRequired,
    sourceTable: PropTypes.string.isRequired,
    data: PropTypes.array,
  }

  static defaultProps = {
    data: undefined,
  }

  config = () => {
    const { sourceLayer, sourceTable, data } = this.props;

    const angleName = (sourceLayer === 'sch') ? 'angleSch' : 'angleGeo';

    return ({
      id: data === undefined ? `${sourceTable}Layer` : `${sourceTable}Layer-geojson`,
      type: 'symbol',
      minzoom: 12,
      layout: {
        'text-field': '{RA_libelle}',
        'text-font': [
          'Roboto Condensed',
        ],
        'text-size': 10,
        'text-offset': [2, 0],
        'icon-image': 'JDZB',
        'icon-size': 0.1,
        'text-anchor': 'center',
        'icon-rotation-alignment': 'map',
        'icon-rotate': ['get', angleName],
        'text-rotate': ['get', angleName],
        'icon-allow-overlap': false,
        'icon-ignore-placement': false,
        'text-allow-overlap': false,
        visibility: 'visible',
      },
      paint: {
        'text-color': '#555',
        'text-halo-width': 2,
        'text-halo-color': 'rgba(255,255,255,0.75)',
        'text-halo-blur': 1,
      },
    });
  }

  verified = () => {
    const { sourceTable } = this.props;

    return ({
      id: `${sourceTable}-verified-layer`,
      type: 'circle',
      'source-layer': sourceTable,
      paint: {
        'circle-color': '#82be00',
        'circle-radius': [
          'case',
          ['!=', ['get', 'isVerifie'], ['string', '[]']], 5,
          0,
        ],
      },
    });
  }


  render() {
    const {
      mapURL, sourceLayer, sourceTable, data
    } = this.props;

    if (data !== undefined) {
      return (
        <Source
          id={`${sourceTable}-${sourceLayer}-source-geojson`}
          type="geojson"
          data={{
            type: 'FeatureCollection',
            features: data,
          }}
        >
          <Layer {...this.config()} />
          {/* <Hover type="circle" sourceLayer={sourceTable} filterField="OP_id" /> */}
        </Source>
      )
    }

    const layerProps = {
      ...this.config(),
      'source-layer': sourceTable,
    };

    return (
      <Source
        id={`${sourceTable}-${sourceLayer}-source`}
        type="vector"
        url={`${mapURL}/chartisv1/layer/${sourceTable}/mvt/${sourceLayer}/`}
      >
        <Layer {...this.verified()} />
        <Layer {...layerProps} />
        <Selected type="circle" sourceLayer={sourceTable} filterField="OP_id" />
        <Hover type="circle" sourceLayer={sourceTable} filterField="OP_id" />
      </Source>
    );
  }
}
