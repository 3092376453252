import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import * as allMapActions from 'reducers/map';
import * as allProfileActions from 'reducers/profile';
import * as allZonesActions from 'reducers/zones';
import * as allLogsActions from 'reducers/logs';

import SelectSNCF from 'common/BootstrapSNCF/SelectSNCF';
import ModalSNCF from 'common/BootstrapSNCF/ModalSNCF/ModalSNCF';
import ModalHeaderSNCF from 'common/BootstrapSNCF/ModalSNCF/ModalHeaderSNCF';
import ModalBodySNCF from 'common/BootstrapSNCF/ModalSNCF/ModalBodySNCF';
import ModalFooterSNCF from 'common/BootstrapSNCF/ModalSNCF/ModalFooterSNCF';
import { QUALITY_STATUS } from 'common/status';
import { MAP_URL } from 'common/Map/const';
import { ZONE_TABLE_HEADERS, HISTORY_TABLE_HEADERS, PROFILE_TABLE_HEADERS } from 'views/tableHeaders';
import ErrorBoundary from 'common/ErrorBoundaries/ErrorBoundary';
import History from '../components/History';
import Zones from '../components/Zones';
import ProfileManagement from '../components/ProfileManagement';

class RawAdmin extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    map: PropTypes.object.isRequired,
    mapActions: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    profileActions: PropTypes.object.isRequired,
    zonesActions: PropTypes.object.isRequired,
    logsActions: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      modalHeader: '',
      modalBody: '',
      modalFooter: '',
      selectedValue: {},
      selectedGroup: {},
    };
    this.modalHistoryId = 'admin-history-modal';
    this.modalZoneId = 'admin-zone-modal';
  }

  componentDidMount = async () => {
    const {
      profileActions, mapActions, logsActions, zonesActions,
    } = this.props;
    try {
      await profileActions.getUsers();
    } catch (e) {
      console.log(e);
    }
    mapActions.updateError(null, null);
    zonesActions.updateError(null, null);
    logsActions.updateError(null, null);
  }

  resetErrors = () => {
    const {
      logsActions, zonesActions, profileActions,
    } = this.props;

    zonesActions.updateError(null, null);
    profileActions.updateError(null, null);
    logsActions.updateError(null, null);
  }

  onZoneNameClick = (zone) => {
    const { mapActions, map } = this.props;

    const viewport = {
      ...map.viewport,
      latitude: zone.center_x,
      longitude: zone.center_y,
      zoom: zone.center_z,
    };

    const zoneGeoJSON = {
      type: 'Feature',
      geometry: zone.zone,
    };

    // ajouter la partie de la zone dans la map
    mapActions.updateSelectedZone(zoneGeoJSON);

    // rediriger vers la map
    mapActions.updateViewport(viewport, MAP_URL);
  }

  getUsersSelectOptions = (defaultValue, isFilter) => {
    const { profile } = this.props;
    const selectedOptions = [];
    selectedOptions.push(defaultValue);

    if (!isFilter) {
      selectedOptions.push({ user_id: 'unassigned', name: 'Non attribué' });
    }

    profile.users.forEach((user) => {
      selectedOptions.push(user);
    });
    return selectedOptions;
  };

  onHistorySelect = (e) => {
    const { logsActions } = this.props;
    logsActions.updateSelectedUserId(JSON.parse(e.target.value).id);
    this.setState({ selectedValue: JSON.parse(e.target.value) });
  }

  getHistoryFilters = () => {
    const { t } = this.props;
    const { selectedValue } = this.state;
    const defaultValue = { id: 'defaultUsersValue', name: `${t('Logs.filters.defaultSelectAdminValue')}` };

    return (
      <div className="col">
        <SelectSNCF
          title={t('Logs.filters.user')}
          options={this.getUsersSelectOptions(defaultValue, true)}
          selectedValue={selectedValue}
          onChange={this.onHistorySelect}
        />
      </div>
    );
  }

  getStatusList = (defaultValue) => {
    const { t } = this.props;
    const statusList = [];
    statusList.push(defaultValue);
    Object.keys(QUALITY_STATUS).forEach((key) => statusList.push({ id: `${key}`, name: `${t(QUALITY_STATUS[key].label)}` }));
    return statusList;
  }

  onZoneSelect = (e) => {
    const { zonesActions } = this.props;
    zonesActions.updateSelectedStatus(JSON.parse(e.target.value).id);
    this.setState({ selectedValue: JSON.parse(e.target.value) });
  }

  getZonesFilters = () => {
    const { t } = this.props;
    const { selectedValue } = this.state;
    const defaultValue = { id: 'defaultStatusValue', name: `${t('Logs.filters.defaultSelectStatusValue')}` };
    return (
      <div className="col">
        <SelectSNCF
          title={t('Logs.filters.status')}
          options={this.getStatusList(defaultValue)}
          selectedValue={selectedValue}
          onChange={this.onZoneSelect}
        />
      </div>
    );
  }

  AssociateValidator = async (id) => {
    const { t, zonesActions } = this.props;
    const { selectedValue } = this.state;

    const userId = selectedValue.user_id === 'unassigned' ? null : selectedValue.user_id;

    const res = await zonesActions.updateSupervisor(id, { supervisor_id: userId });
    if (res === undefined) {
      if (userId === null) {
        await zonesActions.updateStatus(false, id, 0);
      } else {
        await zonesActions.updateStatus(false, id, 2);
      }
      this.setState({ selectedValue: {} });
      const modalHeader = (
        <strong>{t('Logs.zones.verificationSuccess')}</strong>
      );
      const modalBody = (<></>);
      const modalFooter = (
        <div className="d-flex align-items-end justify-content-start" style={{ width: '420px' }}>
          <div className="btn-group dropdown">
            <button
              type="button"
              className="btn btn-sm btn-primary"
              data-dismiss="modal"
            >
              <span>Ok</span>
            </button>
          </div>
        </div>
      );

      this.setState({
        modalHeader,
        modalBody,
        modalFooter,
      });
    } else {
      this.setState({ modalBody: '' });
    }
  }

  onAssociate = (zone) => {
    const { t } = this.props;
    const { selectedValue } = this.state;

    const defaultValue = { id: 'defaultValidatorValue', name: `${t('Logs.filters.defaultSelectAdminValue')}` };

    const modalHeader = (
      <div>
        <strong>{t('Logs.zones.verificationNotice')}</strong>
      </div>
    );

    const modalBody = (
      <div className="col">
        <SelectSNCF
          title="Validateur"
          options={this.getUsersSelectOptions(defaultValue)}
          selectedValue={JSON.stringify(selectedValue) === '{}' ? defaultValue : selectedValue}
          onChange={(e) => this.setState({ selectedValue: JSON.parse(e.target.value) })}
        />
      </div>
    );
    const modalFooter = (
      <ErrorBoundary>
        <div className="d-flex align-items-end justify-content-end" style={{ width: '420px' }}>
          <div className="btn-group dropdown">
            <button
              type="button"
              className="btn btn-sm btn-secondary mr-2"
              data-dismiss="modal"
              onClick={() => this.setState({ selectedValue: {} })}
            >
              <span>{t('Logs.zones.cancel')}</span>
            </button>
          </div>
          <div className="btn-group dropdown">
            <button
              type="button"
              className="btn btn-sm btn-primary"
              onClick={() => this.AssociateValidator(zone.id)}
            >
              <span>{t('Logs.zones.verify')}</span>
            </button>
          </div>
        </div>
      </ErrorBoundary>
    );

    this.setState({ modalHeader, modalBody, modalFooter });
  }

  AssignVerificator = async (id) => {
    const { t, zonesActions } = this.props;
    const { selectedValue } = this.state;

    const userId = selectedValue.user_id === 'unassigned' ? '' : selectedValue.user_id;

    const res = await zonesActions.updateOwner(id, { owner_id: userId });
    if (res === undefined) {
      await zonesActions.updateStatus(false, id, 0);
      this.setState({ selectedValue: {} });
      const modalHeader = (
        <strong>{t('Logs.zones.assignSuccess')}</strong>
      );
      const modalBody = (<></>);
      const modalFooter = (
        <div className="d-flex align-items-end justify-content-start" style={{ width: '420px' }}>
          <div className="btn-group dropdown">
            <button
              type="button"
              className="btn btn-sm btn-primary"
              data-dismiss="modal"
            >
              <span>Ok</span>
            </button>
          </div>
        </div>
      );

      this.setState({
        modalHeader,
        modalBody,
        modalFooter,
      });
    } else {
      this.setState({ modalBody: '' });
    }
  }

  onAssign = (zone) => {
    const { t } = this.props;
    const { selectedValue } = this.state;

    const defaultValue = { id: 'defaultVerificatorValue', name: `${t('Logs.filters.defaultSelectAdminValue')}` };

    const modalHeader = (
      <div>
        <strong>{t('Logs.zones.assignNotice')}</strong>
      </div>
    );
    const modalBody = (
      <div className="col">
        <SelectSNCF
          title="Vérificateur"
          options={this.getUsersSelectOptions(defaultValue, 'Midi')}
          selectedValue={JSON.stringify(selectedValue) === '{}' ? defaultValue : selectedValue}
          onChange={(e) => this.setState({ selectedValue: JSON.parse(e.target.value) })}
        />
      </div>
    );
    const modalFooter = (
      <ErrorBoundary>
        <div className="d-flex align-items-end justify-content-end" style={{ width: '420px' }}>
          <div className="btn-group dropdown">
            <button
              type="button"
              className="btn btn-sm btn-secondary mr-2"
              data-dismiss="modal"
              onClick={() => this.setState({ selectedValue: {} })}
            >
              <span>{t('Logs.zones.cancel')}</span>
            </button>
          </div>
          <div className="btn-group dropdown">
            <button
              type="button"
              className="btn btn-sm btn-primary"
              onClick={() => this.AssignVerificator(zone.id)}
            >
              <span>{t('Logs.zones.assign')}</span>
            </button>
          </div>
        </div>
      </ErrorBoundary>
    );

    this.setState({ modalHeader, modalBody, modalFooter });
  }

  onProfileChange = async (user) => {
    const { t, profileActions } = this.props;
    const { selectedGroup } = this.state;
    const res = await profileActions.updateProfile(user.id, { group_id: user.groups[0].id },
      { group_id: selectedGroup.id });

    if (res === undefined) {
      const modalHeader = (
        <strong>{t('Logs.popupDetails.updateNotice')}</strong>
      );
      const modalBody = (<></>);
      const modalFooter = (
        <div className="d-flex align-items-end justify-content-start" style={{ width: '420px' }}>
          <div className="btn-group dropdown">
            <button
              type="button"
              className="btn btn-sm btn-primary"
              data-dismiss="modal"
            >
              <span>Ok</span>
            </button>
          </div>
        </div>
      );

      this.setState({
        modalHeader,
        modalBody,
        modalFooter,
      });
    } else {
      this.setState({ modalBody: '' });
    }
  }

  updateProfile = (user) => {
    const { t, profile } = this.props;

    const { selectedGroup } = this.state;

    const defaultValue = { id: 'defaultUpdateProfile', name: `${t('Logs.profile.defaultValue')}` };
    const selectedOptions = [];
    selectedOptions.push(defaultValue);
    profile.groups.forEach((group) => {
      selectedOptions.push(group);
    });

    const modalHeader = (
      <div>
        <strong>{t('Logs.profile.updateProfile')}</strong>
      </div>
    );
    const modalBody = (
      <div className="col my-3">
        <SelectSNCF
          title="Profils"
          options={selectedOptions}
          selectedValue={JSON.stringify(selectedGroup) === '{}' ? defaultValue : selectedGroup}
          onChange={(e) => this.setState({ selectedGroup: JSON.parse(e.target.value) })}
        />
      </div>
    );
    const modalFooter = (
      <ErrorBoundary>
        <div className="d-flex align-items-end justify-content-end" style={{ width: '420px' }}>
          <div className="btn-group dropdown">
            <button type="button" className="btn btn-sm btn-secondary mr-2" data-dismiss="modal">
              <span>{t('Logs.profile.cancel')}</span>
            </button>
          </div>
          <div className="btn-group dropdown">
            <button
              type="button"
              className="btn btn-sm btn-primary"
              onClick={() => this.onProfileChange(user)}
            >
              <span>{t('Logs.profile.change')}</span>
            </button>
          </div>
        </div>
      </ErrorBoundary>
    );

    this.setState({ modalHeader, modalBody, modalFooter });
  }

  render() {
    const { t } = this.props;

    const {
      modalHeader, modalBody, modalFooter,
    } = this.state;

    const zoneTableHeaders = ZONE_TABLE_HEADERS.map((head) => {
      switch (head.key) {
        case 'owner_id':
          return {
            ...head,
            onClick: this.onAssign,
          };
        case 'supervisor_id':
          return {
            ...head,
            onClick: this.onAssociate,
          };
        default:
          return head;
      }
    });

    const profileTableHeaders = PROFILE_TABLE_HEADERS.map((head) => {
      if (head.key === 'groups') {
        return {
          ...head,
          onClick: this.updateProfile,
        };
      }
      return head;
    });

    return (
      <>
        <div className="actionbar has-tabs">
          <div className="actionbar-head">
            <h1 className="mb-0">{t('Logs.titleAdmin')}</h1>
          </div>
          <nav role="navigation" className="position-relative mt-2">
            <ul className="nav navtabs mb-0 dragscroll">
              <li className="navtabs-item pr-4">
                <a href="#adminZones" className="active" role="tab" data-toggle="tab" onClick={this.resetErrors}>{t('Logs.tabs.zones')}</a>
              </li>
              <li className="navtabs-item pr-4">
                <a href="#adminHistorique" data-toggle="tab" onClick={this.resetErrors}>{t('Logs.tabs.history')}</a>
              </li>
              {/* <li className="navtabs-item pr-4">
                <a href="#adminProfile" data-toggle="tab" onClick={this.resetErrors}>{t('Logs.tabs.profile')}</a>
              </li> */}
            </ul>
          </nav>
        </div>

        <main role="main" className="mastcontainer bg-white">
          <div className="p-3 tab-content">
            <div className="tab-pane fade show active" id="adminZones" role="tabpanel">
              <Zones
                tableHeaders={zoneTableHeaders}
                filters={this.getZonesFilters()}
                modalId={this.modalZoneId}
                pathName={window.location.pathname}
                htmlId="zones-search-admin"
              />
            </div>
            <div className="tab-pane fade" id="adminHistorique" role="tabpanel">
              <History
                tableHeaders={HISTORY_TABLE_HEADERS}
                filters={this.getHistoryFilters()}
                modalId={this.modalHistoryId}
                pathName={window.location.pathname}
                htmlId="history-search-admin"
              />
            </div>
            {/* <div className="tab-pane fade" id="adminProfile" role="tabpanel">
              <ProfileManagement
                tableHeaders={profileTableHeaders}
                htmlId="profile-management-search"
              />
            </div> */}
          </div>
          <ModalSNCF htmlID="affectation-modal">
            <ModalHeaderSNCF>
              {modalHeader}
            </ModalHeaderSNCF>
            <ModalBodySNCF>
              {modalBody}
            </ModalBodySNCF>
            <ModalFooterSNCF>
              {modalFooter}
            </ModalFooterSNCF>
          </ModalSNCF>
        </main>
      </>
    );
  }
}

const Admin = withTranslation()(RawAdmin);

const mapStateToProps = (state) => ({
  map: state.map,
  profile: state.profile,
  zones: state.zones,
  logs: state.logs,
});

const mapDispatchToProps = (dispatch) => ({
  mapActions: bindActionCreators(allMapActions, dispatch),
  profileActions: bindActionCreators(allProfileActions, dispatch),
  zonesActions: bindActionCreators(allZonesActions, dispatch),
  logsActions: bindActionCreators(allLogsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
