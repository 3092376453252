import config from 'config/config';
import { MIDI_OBJECTS_KEYS } from 'common/Map/Consts/MidiObjects';
import { updateSelectedProperty } from 'utils/reducerHelpers';

export { midiObjects, midiObjectsGroups } from 'common/Map/Consts/MidiObjects';
export { LAYER_VARIABLES } from 'common/Map/Consts/LayerVariables';
export * from 'common/Map/Consts/SignalsNames';

// Map constants
export const MAP_URL = config.proxy;

export const DEFAULT_VIEWPORT = {
  latitude: 46.88731499073388,
  longitude: 2.5682289198122756,
  zoom: 5.6650,
  bearing: 0,
  pitch: 0,
};

export const MAP_MODES = {
  display: 'display',
  modification: 'modification',
  creation: 'creation',
};

export const MAP_TRACK_SOURCES = {
  schematic: 'map_midi_tronconditinerairevoie_schematic',
  geographic: 'map_midi_tronconditinerairevoie_geographic',
  lineGeographic: 'ligne',
};

export const ELEMENT_TYPES = {
  objects: 'objects',
  groups: 'groups',
};

export const ITEMS_TYPES = {
  postesMetier: 'postesMetier',
  itineraires: 'itineraires',
  tablesResponsables: 'tables_responsables',
  tablesAssociees: 'tables_associees',
};

export const MAP_POSTE_MIN_ZOOM = 9;
export const MAP_ITI_MIN_ZOOM = 12;

// Objects constants
// Labels
export const FIELDS_LABEL = {
  L_code: 'Code ligne',
  V_nom: 'Nom voie',
  pk_sncf: 'PK',
  pk_sncf_debut: 'PK début',
  pk_sncf_fin: 'PK fin',
  LP_sensLecture: 'Sens de lecture',
  TIF_mnemo: 'Type',
  RA_libelle: 'Libellé',
  RA_libelle_ligne: 'Libellé ligne',
  RA_libelle_voie: 'Libellé voie',
  RA_poste: 'Poste',
  RA_gare: 'Gare',
  extremites: 'Extrémités',
  RLJDZ_sens: 'RLJDZ_sens',
  RLE_sens: 'RLE_sens',
  is_utilisable_isolement: 'Utilisable isolement',
  Z_ttx: 'Trains travaux interdits',
  RA_libelle_signal_destination: 'Signal de destination',
  RA_libelle_signal_origine: 'Signal d\'origine',
  BAPV_direction: 'Direction',
  BAPV_codeBranche: 'Numéro de branche',
  branches: 'Branches',
  adv_libelle: 'Libellé',
  adv_positionnes: 'Aiguilles',
  L_ordre: 'Ordre',
  CAV_rang: 'Rang',
  codeBranche_debut: 'Code branche début',
  codeBranche_fin: 'Code branche fin',
  codeBranche: 'Code branche',
  BAPV_pointObservation: 'Point d\'observation',
  cles_protections: 'Clé protections',
  itineraires_impactes: 'Itinéraire impactés',
  sels: 'Él caténaire',
  zeps: 'Zeps',
  perimetres_associes: 'Périmètre associés',
  perimetres_responsables: 'Périmètre responsables',
  tables_responsables: 'Table responsables',
  is_neutralise: 'Neutralisé',
  is_mgpt: 'MGPT',
  RA_libelle_centralsoustation: 'Central sous station',
  RA_libelle_macaron_origine: 'Macaron origine',
  RA_libelle_macaron_destination: 'Macaron destination',
  jalon: 'Jalon',
  object: 'Objet appartenant au poste',
  tables_associees: 'Tables associées',
  TDV_mnemonique: 'Catégorie voie',
  TDIV_numerotronconLigne: 'N° tronçon ligne',
  TDIV_numeroTronconVoie: 'N° tronçon voie',
  type_voie: 'Type voie',
  profondeur: 'Profondeur',
  type: 'Type',
  groupements_catenaires: 'Groupement caténaire',
  consigne: 'Consigne',
};

export const DETAILS_TABLE_FIELDS = {
  RA_libelle: 'RA_libelle',
  V_nom: 'V_nom',
  L_code: 'L_code',
  pk_sncf: 'pk_sncf',
  adv_positionnes: 'adv_positionnes',
  cles_protections: 'cles_protections',
  extremites: 'extremites',
  RLE_sens: 'RLE_sens',
  RLJDZ_sens: 'RLJDZ_sens',
  L_ordre: 'L_ordre',
  branches: 'branches',
  RA_libelle_signal_origine: 'RA_libelle_signal_origine',
  RA_libelle_signal_destination: 'RA_libelle_signal_destination',
  BAPV_direction: 'BAPV_direction',
  BAPV_codeBranche: 'BAPV_codeBranche',
  perimetres_associes: 'perimetres_associes',
  perimetres_responsables: 'perimetres_responsables',
  tables_associees: 'tables_associees',
  tables_responsables: 'tables_responsables',
  RA_libelle_poste: 'RA_libelle_poste',
  RA_libelle_gare: 'RA_libelle_gare',
  RA_libelle_voie: 'RA_libelle_voie',
  RA_libelle_ligne: 'RA_libelle_ligne',
  OP_id_poste: 'OP_id_poste',
  OP_id_gare_G_postesId: 'OP_id_gare_G_postesId',
  OP_id_nyx_gare_G_postesId: 'OP_id_nyx_gare_G_postesId',
  jalon: 'jalon',
  OP_id_poste_metier: 'OP_id_poste_metier',
  RA_libelle_poste_metier: 'RA_libelle_poste_metier',
  itineraires_impactes: 'itineraires_impactes',
  table: 'table',
  pk_sncf_debut: 'pk_sncf_debut',
  pk_sncf_fin: 'pk_sncf_fin',
  sels: 'sels',
  groupements_catenaires: 'groupements_catenaires',
};

// Common values
const BOOLEAN_VALUES = [
  {
    key: true,
    label: 'Oui',
  },
  {
    key: false,
    label: 'Non',
  },
];

const IS_MGPT_VALUES = [
  {
    key: true,
    label: '0',
  },
  {
    key: false,
    label: '2',
  },
];

const YES_NO_VALUES = [
  {
    key: 'O',
    label: 'Oui',
  },
  {
    key: 'N',
    label: 'Non',
  },
];

// Signals
const SENS_LECTURE = [
  {
    key: 'C',
    label: 'C',
  },
  {
    key: 'D',
    label: 'D',
  },
];

const SIGNAL_TYPES = [
  {
    key: 'CARRE',
    label: 'Carré',
    id: '45cd67c2-6665-11e3-afff-01f464e0362d',
  },
  {
    key: 'CV',
    label: 'Carré violet',
    id: '45cd6bba-6665-11e3-afff-01f464e0362d',
  },
  {
    key: 'SG HEURT',
    label: 'Heurtoir',
    id: '4c0c5a9a-d492-11e5-94ff-01dc64e0362d',
  },
  {
    key: 'ATC',
    label: 'ATC',
    id: '45ce85bc-6665-11e3-afff-01f464e0362d',
  },
  {
    key: 'D',
    label: 'Disque',
    id: '45cd7388-6665-11e3-afff-01f464e0362d',
  },
  {
    key: 'GA',
    label: 'Guidon d\'arrêt',
    id: '45cd6f9e-6665-11e3-afff-01f464e0362d',
  },
  {
    key: 'SG TGV',
    label: 'Signal TGV',
    id: '45cd5c0c-6665-11e3-afff-01f464e0362d',
  },
  {
    key: 'REP TGV',
    label: 'Repère TGV',
    id: '45cdd588-6665-11e3-afff-01f464e0362d',
  },
  {
    key: 'SIG FICTIF',
    label: 'Signal Fictif',
    id: 'e5cd442c-a805-11eb-938f-068b232d2ec6',
  },
];

// ZEP
const ZEP_TYPES = [
  {
    key: 'ZEP L',
    label: 'ZEP L',
    id: '45ceb0b0-6665-11e3-afff-01f464e0362d',
  },
  {
    key: 'ZEP G',
    label: 'ZEP G',
    id: '45ceacbe-6665-11e3-afff-01f464e0362d',
  },
  {
    key: 'ZE',
    label: 'ZE',
    id: '45ceb498-6665-11e3-afff-01f464e0362d',
  },
];

// ADV
const ADV_DIRECTION_VALUES = [
  {
    key: 'D',
    label: 'D',
  },
  {
    key: 'F',
    label: 'F',
  },
];

const CODE_BRANCHES_VALUES_ITI = [
  {
    key: 2,
    label: '02',
  },
  {
    key: 3,
    label: '03',
  },
  {
    key: 4,
    label: '04',
  },
  {
    key: 5,
    label: '05',
  },
];

const CODE_BRANCHES_VALUES_ADV = [
  {
    key: '02',
    label: '02',
  },
  {
    key: '03',
    label: '03',
  },
  {
    key: '04',
    label: '04',
  },
  {
    key: '05',
    label: '05',
  },
];

const ADV_TYPES = [
  {
    key: 'B2VD',
    label: 'B2VD',
    id: '45cef8b4-6665-11e3-afff-01f464e0362d',
  },
  {
    key: 'B2VG',
    label: 'B2VG',
    id: '45cf2854-6665-11e3-afff-01f464e0362d',
  },
  {
    key: 'B2VS',
    label: 'B2VS',
    id: '45cf09d6-6665-11e3-afff-01f464e0362d',
  },
  {
    key: 'TJSDD',
    label: 'TJSDD',
    id: '45ceee76-6665-11e3-afff-01f464e0362d',
  },
  {
    key: 'TJSGD',
    label: 'TJSGD',
    id: '45cef550-6665-11e3-afff-01f464e0362d',
  },
  {
    key: 'TJSDG',
    label: 'TJSDG',
    id: '45ceff82-6665-11e3-afff-01f464e0362d',
  },
  {
    key: 'TJSGG',
    label: 'TJSGG',
    id: '45cf2182-6665-11e3-afff-01f464e0362d',
  },
  {
    key: 'TJDD',
    label: 'TJDD',
    id: '45cf13fe-6665-11e3-afff-01f464e0362d',
  },
  {
    key: 'TJDG',
    label: 'TJDG',
    id: '45ceeb0e-6665-11e3-afff-01f464e0362d',
  },
  {
    key: 'B3VDD',
    label: 'B3VDD',
    id: '45cefc18-6665-11e3-afff-01f464e0362d',
  },
  {
    key: 'B3VGG',
    label: 'B3VGG',
    id: '45cee356-6665-11e3-afff-01f464e0362d',
  },
  {
    key: 'B3VGD',
    label: 'B3VGD',
    id: '45cf175c-6665-11e3-afff-01f464e0362d',
  },
  {
    key: 'B3VDG',
    label: 'B3VDG',
    id: '45cf0d38-6665-11e3-afff-01f464e0362d',
  },
  {
    key: 'TOCD',
    label: 'TOCD',
    id: '45cf1abc-6665-11e3-afff-01f464e0362d',
  },
  {
    key: 'TOCG',
    label: 'TOCG',
    id: '45cf109e-6665-11e3-afff-01f464e0362d',
  },
  {
    key: 'TOCS',
    label: 'TOCS',
    id: '45cf02ee-6665-11e3-afff-01f464e0362d',
  },
  {
    key: 'BEC',
    label: 'BEC',
    id: '45cee798-6665-11e3-afff-01f464e0362d',
  },
  {
    key: 'F',
    label: 'F',
    id: '45cf24e4-6665-11e3-afff-01f464e0362d',
  },
  {
    key: 'I',
    label: 'I',
    id: '45cf0664-6665-11e3-afff-01f464e0362d',
  },
  {
    key: 'P',
    label: 'P',
    id: '45cef1da-6665-11e3-afff-01f464e0362d',
  },
  {
    key: 'T',
    label: 'T',
    id: '45cf1e14-6665-11e3-afff-01f464e0362d',
  },
];

// SEL
const SEL_ORDERS = [
  {
    key: 1,
    label: 'C',
  },
  {
    key: 2,
    label: 'D',
  },
];

const SEL_TYPES = [
  {
    key: 'FEEDER',
    label: 'FEEDER',
  },
  {
    key: 'SEL',
    label: 'SEL',
  },
];

// Groupement SEL
const GRP_SEL_TYPES = [
  {
    key: 'GRP',
    label: 'GRP',
  },
  {
    key: 'SSR',
    label: 'SSR',
  },
  {
    key: 'SR',
    label: 'SR',
  },
];

// TIV
const TIV_TYPES = [
  {
    key: 'VP',
    label: 'VP',
  },
  {
    key: 'VS',
    label: 'VS',
  },
];

const TIV_CATEGORIES = [
  {
    key: 'VPL',
    label: 'VPL',
  },
  {
    key: 'VPA',
    label: 'VPA',
  },
  {
    key: 'VS',
    label: 'VS',
  },
];

// Modification and creation fields
export const NEW_OBJECT_REF = {
  RA_libelle: '',
  OP_id: '',
};

export const NEW_PROTECTION_KEY = {
  cle: '',
  itineraires: [NEW_OBJECT_REF],
};

export const NEW_POSTE = {
  OP_id_poste: '',
  RA_libelle_poste: '',
};

export const NEW_PERIMETRE = {
  OP_id_poste_metier: '',
  table: '',
};

export const NEW_SIGNAL_ORIGIN = {
  RA_libelle_signal_origine: '',
  pk_sncf_signalOrigine: '',
  L_code_signalOrigine: '',
  V_nom_signalOrigine: '',
  OP_id_localisationpk_signalOrigine: '',
  LP_sensLecture_signalOrigine: '',
  OP_id_tronconditinerairevoie_signalOrigine: '',
};

export const NEW_SIGNAL_DESTINATION = {
  RA_libelle_signal_destination: '',
  pk_sncf_signalDestination: '',
  L_code_signalDestination: '',
  V_nom_signalDestination: '',
  OP_id_localisationpk_signalDestination: '',
  LP_sensLecture_signalDestination: '',
  OP_id_tronconditinerairevoie_signalDestination: '',
};

export const NEW_GROUPEMENT_CATENAIRE = {
  OP_id: '',
  RA_libelle: '',
};

export const GEOM_FIELDS = {
  // Mettre le TIF Mnemo dans le titre avant le RA_libelle
  [MIDI_OBJECTS_KEYS.signal]: [
    {
      key: 'L_code',
      label: FIELDS_LABEL.L_code,
      onClick: (params) => {
        updateSelectedProperty(
          'L_code',
          MIDI_OBJECTS_KEYS.tivSch,
          ['L_code'],
          params.path,
        );
      },
    },
    {
      key: 'V_nom',
      label: FIELDS_LABEL.V_nom,
    },
    {
      key: 'pk_sncf',
      label: FIELDS_LABEL.pk_sncf,
      isEditable: true,
    },
    {
      key: 'LP_sensLecture',
      label: FIELDS_LABEL.LP_sensLecture,
      isEditable: true,
      values: SENS_LECTURE,
    },
  ],
  [MIDI_OBJECTS_KEYS.jdz]: [
    {
      key: 'L_code',
      label: FIELDS_LABEL.L_code,
      onClick: (params) => {
        updateSelectedProperty(
          'L_code',
          MIDI_OBJECTS_KEYS.tivSch,
          ['L_code'],
          params.path,
        );
      },
    },
    {
      key: 'V_nom',
      label: FIELDS_LABEL.V_nom,
    },
    {
      key: 'pk_sncf',
      label: FIELDS_LABEL.pk_sncf,
      isEditable: true,
    },
  ],
  [MIDI_OBJECTS_KEYS.cdv]: [
    {
      key: 'extremites',
      label: FIELDS_LABEL.extremites,
      isEditable: true,
      onClick: (params) => {
        updateSelectedProperty(
          'extremites',
          MIDI_OBJECTS_KEYS.jdz,
          ['L_code', 'V_nom', 'pk_sncf', 'RLJDZ_sens'],
          params.path,
        );
      },
      fields: [
        {
          key: 'L_code',
          label: FIELDS_LABEL.L_code,
        },
        {
          key: 'V_nom',
          label: FIELDS_LABEL.V_nom,
        },
        {
          key: 'pk_sncf',
          label: FIELDS_LABEL.pk_sncf,
          isEditable: true,
        },
        {
          key: 'RLJDZ_sens',
          label: FIELDS_LABEL.RLJDZ_sens,
          isEditable: true,
          values: SENS_LECTURE,
        },
      ],
    },
  ],
  [MIDI_OBJECTS_KEYS.adv]: [
    {
      key: 'L_code',
      label: FIELDS_LABEL.L_code,
      onClick: (params) => {
        updateSelectedProperty(
          'L_code',
          MIDI_OBJECTS_KEYS.tivSch,
          ['L_code'],
          params.path,
        );
      },
    },
    {
      key: 'V_nom',
      label: FIELDS_LABEL.V_nom,
    },
    {
      key: 'pk_sncf',
      label: FIELDS_LABEL.pk_sncf,
      isEditable: true,
    },
    {
      key: 'BAPV_direction',
      label: FIELDS_LABEL.BAPV_direction,
      isEditable: true,
      values: ADV_DIRECTION_VALUES,
    },
    {
      key: 'BAPV_codeBranche',
      label: FIELDS_LABEL.BAPV_codeBranche,
      isEditable: true,
      values: CODE_BRANCHES_VALUES_ADV,
    },
    {
      key: 'BAPV_pointObservation',
      label: FIELDS_LABEL.BAPV_pointObservation,
      isEditable: true,
      values: BOOLEAN_VALUES,
    },
  ],
  [MIDI_OBJECTS_KEYS.zep]: [
    {
      key: 'extremites',
      label: FIELDS_LABEL.extremites,
      isEditable: true,
      onClick: (params) => {
        updateSelectedProperty(
          'extremites',
          MIDI_OBJECTS_KEYS.tivSch,
          ['L_code', 'V_nom', 'pk_sncf', 'RLE_sens'],
          params.path,
        );
      },
      fields: [
        {
          key: 'L_code',
          label: FIELDS_LABEL.L_code,
        },
        {
          key: 'V_nom',
          label: FIELDS_LABEL.V_nom,
        },
        {
          key: 'pk_sncf',
          label: FIELDS_LABEL.pk_sncf,
          isEditable: true,
        },
        {
          key: 'RLE_sens',
          label: FIELDS_LABEL.RLE_sens,
          isEditable: true,
          values: SENS_LECTURE,
        },
      ],
    },
  ],
  [MIDI_OBJECTS_KEYS.groupementZep]: [
    {
      key: 'zeps',
      label: FIELDS_LABEL.zeps,
      isEditable: true,
      onClick: (params) => {
        updateSelectedProperty(
          'zeps',
          MIDI_OBJECTS_KEYS.zep,
          ['OP_id', 'RA_libelle'],
          params.path,
        );
      },
      fields: [
        {
          key: 'RA_libelle',
          label: FIELDS_LABEL.RA_libelle,
        },
      ],
    },
  ],
  [MIDI_OBJECTS_KEYS.sel]: [
    {
      key: 'extremites',
      label: FIELDS_LABEL.extremites,
      isEditable: true,
      onClick: (params) => {
        updateSelectedProperty(
          'extremites',
          MIDI_OBJECTS_KEYS.tivSch,
          ['L_code', 'V_nom', 'pk_sncf', 'L_ordre'],
          params.path,
        );
      },
      fields: [
        {
          key: 'L_code',
          label: FIELDS_LABEL.L_code,
        },
        {
          key: 'V_nom',
          label: FIELDS_LABEL.V_nom,
        },
        {
          key: 'pk_sncf',
          label: FIELDS_LABEL.pk_sncf,
          isEditable: true,
        },
        {
          key: 'L_ordre',
          label: FIELDS_LABEL.L_ordre,
          isEditable: true,
          values: SEL_ORDERS,
        },
      ],
    },
  ],
  [MIDI_OBJECTS_KEYS.groupementSel]: [
    {
      key: 'sels',
      label: FIELDS_LABEL.sels,
      isEditable: true,
      onClick: (params) => {
        updateSelectedProperty(
          'sels',
          MIDI_OBJECTS_KEYS.sel,
          ['OP_id', 'RA_libelle'],
          params.path,
        );
      },
      fields: [
        {
          key: 'RA_libelle',
          label: FIELDS_LABEL.RA_libelle,
        },
      ],
    },
    {
      key: 'groupements_catenaires',
      label: FIELDS_LABEL.groupements_catenaires,
      isEditable: true,
      onClick: (params) => {
        updateSelectedProperty(
          'groupements_catenaires',
          MIDI_OBJECTS_KEYS.groupementSel,
          ['OP_id', 'RA_libelle'],
          params.path,
        );
      },
      fields: [
        {
          key: 'RA_libelle',
          label: FIELDS_LABEL.RA_libelle,
        },
      ],
    },
  ],
  [MIDI_OBJECTS_KEYS.itineraire]: [
    {
      key: 'RA_libelle_signal_origine',
      label: FIELDS_LABEL.RA_libelle_signal_origine,
      onClick: () => {
        updateSelectedProperty(
          'RA_libelle_signal_origine',
          MIDI_OBJECTS_KEYS.signal,
          ['RA_libelle_signal_origine',
            'pk_sncf_signalOrigine',
            'L_code_signalOrigine',
            'V_nom_signalOrigine',
            'OP_id_localisationpk_signalOrigine',
            'LP_sensLecture_signalOrigine',
            'OP_id_canton_origine',
            'OP_id_tronconditinerairevoie_signalOrigine'],

        );
      },
    },
    {
      key: 'RA_libelle_signal_destination',
      label: FIELDS_LABEL.RA_libelle_signal_destination,
      onClick: () => {
        updateSelectedProperty(
          'RA_libelle_signal_destination',
          MIDI_OBJECTS_KEYS.signal,
          ['RA_libelle_signal_destination',
            'pk_sncf_signalDestination',
            'L_code_signalDestination',
            'V_nom_signalDestination',
            'OP_id_localisationpk_signalDestination',
            'LP_sensLecture_signalDestination',
            'OP_id_canton_destination',
            'OP_id_tronconditinerairevoie_signalDestination'],
        );
      },
    },
    {
      key: 'adv_positionnes',
      label: FIELDS_LABEL.adv_positionnes,
      isEditable: true,
      fields: [
        {
          key: 'RA_libelle',
          label: FIELDS_LABEL.adv_libelle,
        },
        {
          key: 'branches',
          label: FIELDS_LABEL.branches,
          hideDeleteButton: true,
          onClick: (params) => {
            updateSelectedProperty(
              'branches',
              MIDI_OBJECTS_KEYS.adv,
              ['BAPV_codeBranche', 'OP_id'],
              params.path,
            );
          },
          fields: [
            {
              key: 'BAPV_codeBranche',
              label: FIELDS_LABEL.codeBranche,
              values: CODE_BRANCHES_VALUES_ITI,
            },
          ],
        },
      ],
    },
  ],
  [MIDI_OBJECTS_KEYS.tivSch]: [
    {
      key: 'L_code',
      label: FIELDS_LABEL.L_code,
      isEditable: true,
    },
    {
      key: 'V_nom',
      label: FIELDS_LABEL.V_nom,
      isEditable: true,
    },
    {
      key: 'pk_sncf_debut',
      label: FIELDS_LABEL.pk_sncf_debut,
      isEditable: true,
    },
    {
      key: 'pk_sncf_fin',
      label: FIELDS_LABEL.pk_sncf_fin,
      isEditable: true,
    },
  ],
};

export const GEOM_CREATION = {
  [MIDI_OBJECTS_KEYS.adv]: [
    {
      key: 'branches',
      label: FIELDS_LABEL.branches,
      isEditable: true,
      onClick: (params) => {
        updateSelectedProperty(
          'branches',
          MIDI_OBJECTS_KEYS.tivSch,
          ['L_code', 'V_nom', 'pk_sncf', 'BAPV_direction', 'BAPV_codeBranche'],
          params.path,
        );
      },
      fields: [
        {
          key: 'L_code',
          label: FIELDS_LABEL.L_code,
        },
        {
          key: 'V_nom',
          label: FIELDS_LABEL.V_nom,
        },
        {
          key: 'pk_sncf',
          label: FIELDS_LABEL.pk_sncf,
        },
        {
          key: 'BAPV_direction',
          label: FIELDS_LABEL.BAPV_direction,
          isEditable: true,
          values: ADV_DIRECTION_VALUES,
        },
        {
          key: 'BAPV_codeBranche',
          label: FIELDS_LABEL.BAPV_codeBranche,
          isEditable: true,
          values: CODE_BRANCHES_VALUES_ADV,
        },
      ],
    },
  ],
  [MIDI_OBJECTS_KEYS.poste]: [
    {
      key: 'object',
      label: FIELDS_LABEL.object,
    },
  ],
};

export const ATTRIB_FIELDS = {
  [MIDI_OBJECTS_KEYS.signal]: [
    {
      key: 'TIF_mnemo',
      label: FIELDS_LABEL.TIF_mnemo,
      isEditable: true, // Déroulant avec les 4 types
      values: SIGNAL_TYPES,
    },
    {
      key: 'RA_libelle',
      label: FIELDS_LABEL.RA_libelle,
      isEditable: true,
    },
    {
      key: 'RA_libelle_poste',
      label: FIELDS_LABEL.RA_poste,
      hideModification: true,
    },
    {
      key: 'RA_libelle_gare',
      label: FIELDS_LABEL.RA_gare,
      hideModification: true,
    },
    {
      key: 'consigne',
      label: FIELDS_LABEL.consigne,
      isEditable: true,
    },
    {
      key: 'perimetres_responsables',
      label: FIELDS_LABEL.perimetres_responsables,
      isEditable: true,
      fields: [
        {
          key: 'RA_libelle_poste_metier',
          label: FIELDS_LABEL.RA_libelle_poste_metier,
        },
      ],
    },
    {
      key: 'tables_responsables',
      label: FIELDS_LABEL.tables_responsables,
      isEditable: true,
    },
  ],
  [MIDI_OBJECTS_KEYS.jdz]: [
    {
      key: 'consigne',
      label: FIELDS_LABEL.consigne,
      isEditable: true,
    },
    {
      key: 'perimetres_responsables',
      label: FIELDS_LABEL.perimetres_responsables,
      isEditable: true,
      fields: [
        {
          key: 'RA_libelle_poste_metier',
          label: FIELDS_LABEL.RA_libelle_poste_metier,
        },
      ],
    },
    {
      key: 'tables_responsables',
      label: FIELDS_LABEL.tables_responsables,
      isEditable: true,
    },
  ],
  [MIDI_OBJECTS_KEYS.cdv]: [
    {
      key: 'RA_libelle',
      label: FIELDS_LABEL.RA_libelle,
      isEditable: true,
    },
    {
      key: 'RA_libelle_poste',
      label: FIELDS_LABEL.RA_poste,
      hideModification: true,
    },
    {
      key: 'RA_libelle_gare',
      label: FIELDS_LABEL.RA_gare,
      hideModification: true,
    },
    {
      key: 'consigne',
      label: FIELDS_LABEL.consigne,
      isEditable: true,
    },
    {
      key: 'RA_libelle_poste_metier',
      label: FIELDS_LABEL.RA_libelle_poste_metier,
    },
    {
      key: 'table_responsable',
      label: FIELDS_LABEL.table_responsable,
      isEditable: true,
    },
  ],
  [MIDI_OBJECTS_KEYS.adv]: [
    {
      key: 'TADV_mnemonique',
      label: FIELDS_LABEL.TIF_mnemo,
      isEditable: true,
      values: ADV_TYPES,
    },
    {
      key: 'RA_libelle',
      label: FIELDS_LABEL.RA_libelle,
      isEditable: true,
    },
    {
      key: 'is_neutralise',
      label: FIELDS_LABEL.is_neutralise,
      isEditable: true,
      values: BOOLEAN_VALUES,
    },
    {
      key: 'RA_libelle_poste',
      label: FIELDS_LABEL.RA_poste,
      hideModification: true,
    },
    {
      key: 'RA_libelle_gare',
      label: FIELDS_LABEL.RA_gare,
      hideModification: true,
    },
    {
      key: 'consigne',
      label: FIELDS_LABEL.consigne,
      isEditable: true,
    },
    {
      key: 'perimetres_responsables',
      label: FIELDS_LABEL.perimetres_responsables,
      isEditable: true,
      fields: [
        {
          key: 'RA_libelle_poste_metier',
          label: FIELDS_LABEL.RA_libelle_poste_metier,
        },
      ],
    },
    {
      key: 'tables_responsables',
      label: FIELDS_LABEL.tables_responsables,
      isEditable: true,
    },
  ],
  [MIDI_OBJECTS_KEYS.zep]: [
    {
      key: 'TIF_mnemo',
      label: FIELDS_LABEL.TIF_mnemo,
      isEditable: true, // Déroulant avec les 4 types
      values: ZEP_TYPES,
    },
    {
      key: 'RA_libelle',
      label: FIELDS_LABEL.RA_libelle,
      isEditable: true,
    },
    {
      key: 'is_utilisable_isolement',
      label: FIELDS_LABEL.is_utilisable_isolement,
      isEditable: true,
      values: BOOLEAN_VALUES,
    },
    {
      key: 'Z_trainTravauxInterdits',
      label: FIELDS_LABEL.Z_ttx,
      isEditable: true,
      values: YES_NO_VALUES,
    },
    {
      key: 'RA_libelle_poste',
      label: FIELDS_LABEL.RA_poste,
      hideModification: true,
    },
    {
      key: 'RA_libelle_gare',
      label: FIELDS_LABEL.RA_gare,
      hideModification: true,
    },
    {
      key: 'consigne',
      label: FIELDS_LABEL.consigne,
      isEditable: true,
    },
    {
      key: 'is_mgpt',
      label: FIELDS_LABEL.is_mgpt,
      isEditable: true,
      values: IS_MGPT_VALUES,
    },
    {
      key: 'perimetres_responsables',
      label: FIELDS_LABEL.perimetres_responsables,
      isEditable: true,
      fields: [
        {
          key: 'RA_libelle_poste_metier',
          label: FIELDS_LABEL.RA_libelle_poste_metier,
        },
      ],
    },
    {
      key: 'tables_responsables',
      label: FIELDS_LABEL.tables_responsables,
      isEditable: true,
    },
    {
      key: 'perimetres_associes',
      label: FIELDS_LABEL.perimetres_associes,
      isEditable: true,
      fields: [
        {
          key: 'RA_libelle_poste_metier',
          label: FIELDS_LABEL.RA_libelle_poste_metier,
        },
      ],
    },
    {
      key: 'tables_associees',
      label: FIELDS_LABEL.tables_associees,
      isEditable: true,
    },
    {
      key: 'cles_protections',
      label: FIELDS_LABEL.cles_protections,
      isEditable: true,
    },
    {
      key: 'itineraires_impactes',
      label: FIELDS_LABEL.itineraires_impactes,
      hideDeleteButton: true,
      fields: [
        {
          key: 'RA_libelle',
          label: FIELDS_LABEL.RA_libelle,
        },
      ],
    },
  ],
  [MIDI_OBJECTS_KEYS.groupementZep]: [
    {
      key: 'RA_libelle',
      label: FIELDS_LABEL.RA_libelle,
      isEditable: true,
    },
    {
      key: 'GDZEP_trainTravauxInterdits',
      label: FIELDS_LABEL.Z_ttx,
      isEditable: true,
      values: YES_NO_VALUES,
    },
    {
      key: 'RA_libelle_poste',
      label: FIELDS_LABEL.RA_poste,
      hideModification: true,
    },
    {
      key: 'RA_libelle_gare',
      label: FIELDS_LABEL.RA_gare,
      hideModification: true,
    },
    {
      key: 'is_mgpt',
      label: FIELDS_LABEL.is_mgpt,
      isEditable: true,
      values: IS_MGPT_VALUES,
    },
    {
      key: 'consigne',
      label: FIELDS_LABEL.consigne,
      isEditable: true,
    },
    {
      key: 'perimetres_responsables',
      label: FIELDS_LABEL.perimetres_responsables,
      isEditable: true,
      fields: [
        {
          key: 'RA_libelle_poste_metier',
          label: FIELDS_LABEL.RA_libelle_poste_metier,
        },
      ],
    },
    {
      key: 'tables_responsables',
      label: FIELDS_LABEL.tables_responsables,
      isEditable: true,
    },
    {
      key: 'perimetres_associes',
      label: FIELDS_LABEL.perimetres_associes,
      isEditable: true,
      fields: [
        {
          key: 'RA_libelle_poste_metier',
          label: FIELDS_LABEL.RA_libelle_poste_metier,
        },
      ],
    },
    {
      key: 'tables_associees',
      label: FIELDS_LABEL.tables_associees,
      isEditable: true,
    },
    {
      key: 'cles_protections',
      label: FIELDS_LABEL.cles_protections,
      isEditable: true,
    },
    {
      key: 'itineraires_impactes',
      label: FIELDS_LABEL.itineraires_impactes,
      hideDeleteButton: true,
      fields: [
        {
          key: 'RA_libelle',
          label: FIELDS_LABEL.RA_libelle,
        },
      ],
    },
  ],
  [MIDI_OBJECTS_KEYS.sel]: [
    {
      key: 'RA_libelle',
      label: FIELDS_LABEL.RA_libelle,
      isEditable: true,
    },
    {
      key: 'RA_libelle_centralsoustation',
      label: FIELDS_LABEL.RA_libelle_centralsoustation,
      isEditable: true,
    },
    {
      key: 'is_utilisable_isolement',
      label: FIELDS_LABEL.is_utilisable_isolement,
      isEditable: true,
      values: BOOLEAN_VALUES,
    },
    {
      key: 'RA_libelle_poste',
      label: FIELDS_LABEL.RA_poste,
      hideModification: true,
    },
    {
      key: 'RA_libelle_gare',
      label: FIELDS_LABEL.RA_gare,
      hideModification: true,
    },
    {
      key: 'TIF_mnemo',
      label: FIELDS_LABEL.TIF_mnemo,
      isEditable: true,
      values: SEL_TYPES,
    },
    {
      key: 'consigne',
      label: FIELDS_LABEL.consigne,
      isEditable: true,
    },
    {
      key: 'perimetres_responsables',
      label: FIELDS_LABEL.perimetres_responsables,
      isEditable: true,
      fields: [
        {
          key: 'RA_libelle_poste_metier',
          label: FIELDS_LABEL.RA_libelle_poste_metier,
        },
      ],
    },
    {
      key: 'tables_responsables',
      label: FIELDS_LABEL.tables_responsables,
      isEditable: true,
    },
    {
      key: 'perimetres_associes',
      label: FIELDS_LABEL.perimetres_associes,
      isEditable: true,
      fields: [
        {
          key: 'RA_libelle_poste_metier',
          label: FIELDS_LABEL.RA_libelle_poste_metier,
        },
      ],
    },
    {
      key: 'tables_associees',
      label: FIELDS_LABEL.tables_associees,
      isEditable: true,
    },
    {
      key: 'cles_protections',
      label: FIELDS_LABEL.cles_protections,
      isEditable: true,
    },
    {
      key: 'itineraires_impactes',
      label: FIELDS_LABEL.itineraires_impactes,
      hideDeleteButton: true,
      fields: [
        {
          key: 'RA_libelle',
          label: FIELDS_LABEL.RA_libelle,
        },
      ],
    },
  ],
  [MIDI_OBJECTS_KEYS.groupementSel]: [
    {
      key: 'RA_libelle',
      label: FIELDS_LABEL.RA_libelle,
      isEditable: true,
    },
    {
      key: 'RA_libelle_centralsoustation',
      label: FIELDS_LABEL.RA_libelle_centralsoustation,
      isEditable: true,
    },
    {
      key: 'RA_libelle_poste',
      label: FIELDS_LABEL.RA_poste,
      hideModification: true,
    },
    {
      key: 'RA_libelle_gare',
      label: FIELDS_LABEL.RA_gare,
      hideModification: true,
    },
    {
      key: 'type',
      label: FIELDS_LABEL.type,
      isEditable: true,
      values: GRP_SEL_TYPES,
    },
    {
      key: 'consigne',
      label: FIELDS_LABEL.consigne,
      isEditable: true,
    },
    {
      key: 'perimetres_responsables',
      label: FIELDS_LABEL.perimetres_responsables,
      isEditable: true,
      fields: [
        {
          key: 'RA_libelle_poste_metier',
          label: FIELDS_LABEL.RA_libelle_poste_metier,
        },
      ],
    },
    {
      key: 'tables_responsables',
      label: FIELDS_LABEL.tables_responsables,
      isEditable: true,
    },
    {
      key: 'perimetres_associes',
      label: FIELDS_LABEL.perimetres_associes,
      isEditable: true,
      fields: [
        {
          key: 'RA_libelle_poste_metier',
          label: FIELDS_LABEL.RA_libelle_poste_metier,
        },
      ],
    },
    {
      key: 'tables_associees',
      label: FIELDS_LABEL.tables_associees,
      isEditable: true,
    },
    {
      key: 'cles_protections',
      label: FIELDS_LABEL.cles_protections,
      isEditable: true,
    },
    {
      key: 'itineraires_impactes',
      label: FIELDS_LABEL.itineraires_impactes,
      hideDeleteButton: true,
      fields: [
        {
          key: 'RA_libelle',
          label: FIELDS_LABEL.RA_libelle,
        },
      ],
    },
  ],
  [MIDI_OBJECTS_KEYS.itineraire]: [
    {
      key: 'RA_libelle',
      label: FIELDS_LABEL.RA_libelle,
      isEditable: true,
    },
    {
      key: 'RA_libelle_macaron_origine',
      label: FIELDS_LABEL.RA_libelle_macaron_origine,
    },
    {
      key: 'RA_libelle_macaron_destination',
      label: FIELDS_LABEL.RA_libelle_macaron_destination,
    },
    {
      key: 'jalon',
      label: FIELDS_LABEL.jalon,
      isEditable: true,
    },
    {
      key: 'RA_libelle_poste',
      label: FIELDS_LABEL.RA_poste,
      hideModification: true,
    },
    {
      key: 'RA_libelle_gare',
      label: FIELDS_LABEL.RA_gare,
      hideModification: true,
    },
    {
      key: 'consigne',
      label: FIELDS_LABEL.consigne,
      isEditable: true,
    },
    {
      key: 'perimetres_responsables',
      label: FIELDS_LABEL.perimetres_responsables,
      isEditable: true,
      fields: [
        {
          key: 'RA_libelle_poste_metier',
          label: FIELDS_LABEL.RA_libelle_poste_metier,
        },
      ],
    },
    {
      key: 'tables_responsables',
      label: FIELDS_LABEL.tables_responsables,
      isEditable: true,
    },
    {
      key: 'itineraires_impactes',
      label: FIELDS_LABEL.itineraires_impactes,
      isEditable: true,
      fields: [
        {
          key: 'RA_libelle',
          label: FIELDS_LABEL.RA_libelle,
        },
      ],
    },
  ],
  [MIDI_OBJECTS_KEYS.tivSch]: [
    {
      key: 'RA_libelle_ligne',
      label: FIELDS_LABEL.RA_libelle_ligne,
      isEditable: true,
    },
    {
      key: 'RA_libelle_voie',
      label: FIELDS_LABEL.RA_libelle_voie,
      isEditable: true,
    },
    {
      key: 'TDV_mnemonique',
      label: FIELDS_LABEL.TDV_mnemonique,
      isEditable: true,
      values: TIV_CATEGORIES,
    },
    {
      key: 'TDIV_numerotronconLigne',
      label: FIELDS_LABEL.TDIV_numerotronconLigne,
    },
    {
      key: 'TDIV_numeroTronconVoie',
      label: FIELDS_LABEL.TDIV_numeroTronconVoie,
    },
    {
      key: 'type_voie',
      label: FIELDS_LABEL.type_voie,
      isEditable: true,
      values: TIV_TYPES,
    },
    {
      key: 'profondeur',
      label: FIELDS_LABEL.profondeur,
      isEditable: true,
    },
    {
      key: 'consigne',
      label: FIELDS_LABEL.consigne,
      isEditable: true,
    },
  ],
};

export const ATTRIB_CREATION = {
  [MIDI_OBJECTS_KEYS.adv]: [
    {
      key: 'TADV_mnemonique',
      label: FIELDS_LABEL.TIF_mnemo,
      isEditable: true,
      values: ADV_TYPES,
    },
    {
      key: 'RA_libelle',
      label: FIELDS_LABEL.RA_libelle,
      isEditable: true,
    },
    {
      key: 'consigne',
      label: FIELDS_LABEL.consigne,
      isEditable: true,
    },
    {
      key: 'is_neutralise',
      label: FIELDS_LABEL.is_neutralise,
      isEditable: true,
      values: BOOLEAN_VALUES,
    },
    {
      key: 'RA_libelle_poste_metier',
      label: FIELDS_LABEL.RA_libelle_poste_metier,
    },
  ],
  [MIDI_OBJECTS_KEYS.itineraire]: [
    {
      key: 'RA_libelle',
      label: FIELDS_LABEL.RA_libelle,
      isEditable: true,
    },
    {
      key: 'RA_libelle_macaron_origine',
      label: FIELDS_LABEL.RA_libelle_macaron_origine,
      isEditable: true,
    },
    {
      key: 'RA_libelle_macaron_destination',
      label: FIELDS_LABEL.RA_libelle_macaron_destination,
      isEditable: true,
    },
    {
      key: 'jalon',
      label: FIELDS_LABEL.jalon,
      isEditable: true,
    },
    {
      key: 'RA_libelle_poste',
      label: FIELDS_LABEL.RA_poste,
      hideModification: true,
    },
    {
      key: 'RA_libelle_gare',
      label: FIELDS_LABEL.RA_gare,
      hideModification: true,
    },
    {
      key: 'consigne',
      label: FIELDS_LABEL.consigne,
      isEditable: true,
    },
    {
      key: 'perimetres_responsables',
      label: FIELDS_LABEL.perimetres_responsables,
      isEditable: true,
      fields: [
        {
          key: 'RA_libelle_poste_metier',
          label: FIELDS_LABEL.RA_libelle_poste_metier,
        },
      ],
    },
    {
      key: 'tables_responsables',
      label: FIELDS_LABEL.tables_responsables,
      isEditable: true,
    },
    {
      key: 'itineraires_impactes',
      label: FIELDS_LABEL.itineraires_impactes,
      isEditable: true,
      fields: [
        {
          key: 'RA_libelle',
          label: FIELDS_LABEL.RA_libelle,
        },
      ],
    },
  ],
  [MIDI_OBJECTS_KEYS.poste]: [
    {
      key: 'RA_libelle',
      label: FIELDS_LABEL.RA_libelle,
      isEditable: true,
    },
    {
      key: 'consigne',
      label: FIELDS_LABEL.consigne,
      isEditable: true,
    },
  ],
};

export const SUBTITLES = [
  {
    key: 'type',
    label: 'Type',
    dataPath: ['document_type', 'name'],
  },
];

export const DOCUMENT_TYPES = [
  {
    key: 'all',
    label: 'Tous les types',
  },
  {
    key: 's6',
    label: 'S6',
  },
  {
    key: 's9c',
    label: 'S9C',
  },
  {
    key: 's11',
    label: 'S11',
  },
];
