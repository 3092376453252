import { store } from 'Store';
import bearing from '@turf/bearing';
import pointToLineDistance from '@turf/point-to-line-distance';
import distance from '@turf/distance';
import { point } from '@turf/helpers';

import { MAP_MODES } from 'common/Map/const';
import { get } from 'common/requests';
import { getMidiObjectByKey, MIDI_OBJECTS_KEYS, MIDI_OBJECTS_LAYERS } from 'common/Map/Consts/MidiObjects';

import {
  updateData, updateDataPoints, updateJdz, updateMouseCoords, updateSelectedData, updateSignals,
  updateSnappedGeometry,
  updateSnappingLayers, updateTivPoints,
} from 'reducers/geoEditor';
import { roundNumber } from 'utils/helpers';

// Define the angle so that created TIVs are parallels to the other
export const getRotateAngle = (rawAngle, baseAngle, pointsCoordsRef = undefined) => {
  const refBearing = bearing(pointsCoordsRef[0], pointsCoordsRef[1]);
  const shiftAngle = pointsCoordsRef !== undefined
    ? refBearing % baseAngle
    : 0;
  const allowedAnglesLength = 360 / baseAngle;
  const allowedAngles = [shiftAngle - 180];
  for (let i = 1; i < allowedAnglesLength + 1; i += 1) {
    allowedAngles.push((allowedAngles[i - 1] + baseAngle) % 360);
  }

  const snappingAngle = allowedAngles.reduce(
    (a, b) => (Math.abs(b - rawAngle) < Math.abs(a - rawAngle) ? b : a),
  );

  return snappingAngle - rawAngle;
};

// Format MultiLineStrings in LineStrings
export const formatFeatures = (features, allPointsCoords, tivResults) => {
  const { map } = store.getState();
  const tivPointsResults = [];
  const tempData = [];

  features.forEach((feature) => {
    if (feature.geometry.type === 'MultiLineString') {
      feature.geometry.coordinates.forEach((coord, i) => {
        tempData.push({
          ...feature,
          geometry: {
            type: 'LineString',
            coordinates: coord,
          },
          properties: {
            ...feature.properties,
            renderType: 'LineString',
            multiLineStringIndex: i,
          },
        });
      });
    } else {
      tempData.push({
        ...feature,
        properties: {
          ...feature.properties,
          renderType: feature.geometry.type,
        },
      });
    }
  });

  if (map.mode !== MAP_MODES.creation) store.dispatch(updateData(tempData));

  tempData.forEach((t) => {
    t.geometry.coordinates.forEach((coord, index) => {
      if (!allPointsCoords.includes(JSON.stringify(coord))) {
        allPointsCoords.push(JSON.stringify(coord));
        tivPointsResults.push({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: coord,
          },
          properties: {
            ...t.properties,
            pointIndex: index,
          },
        });
      }
    });
  });

  tivResults.forEach((tivResult) => {
    tivResult.geometry.coordinates.forEach((coord, index) => {
      if (!allPointsCoords.includes(JSON.stringify(coord))) {
        allPointsCoords.push(JSON.stringify(coord));
        tivPointsResults.push({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: coord,
          },
          properties: {
            ...tivResult.properties,
            pointIndex: index,
          },
        });
      }
    });
  });
  return [tempData, tivPointsResults];
};

// Update selectedData MultiLineString as several LineString for display purposes
const updateClickedFeatures = (clickedFeatures) => {
  if (clickedFeatures?.length === 1) {
    const clickedFeature = clickedFeatures[0];
    const newSelectedData = {
      ...clickedFeature,
      id: 0,
      geometry: clickedFeature.geometry,
      properties: {
        ...clickedFeature.properties,
        renderType: clickedFeature.geometry.type,
      },
    };
    store.dispatch(updateSelectedData([newSelectedData]));
  } else {
    const newSelectedData = clickedFeatures.map((f) => ({
      ...f,
      id: f.properties.multiLineStringIndex,
      properties: {
        ...f.properties,
        renderType: 'LineString',
      },
    }));
    store.dispatch(updateSelectedData(newSelectedData));
  }
};

export const getGeoJSONData = async (layerSlug, getParams) => {
  let res = await get(`/chartisv1/layer/${layerSlug}/geojson/sch/`, getParams);
  let geoJsonObjects = res.features;
  let page = 1;
  while (res.next !== null) {
    page += 1;
    // eslint-disable-next-line no-await-in-loop
    res = await get(`/chartisv1/layer/${layerSlug}/geojson/sch/`, {
      ...getParams,
      page,
    });

    geoJsonObjects = geoJsonObjects.concat(res.features);
  }

  return geoJsonObjects;
};

export const addSnappingLayers = async (selectedElements, chartisParams = undefined) => {
  const { geoEditor } = store.getState();
  let snappingPoints = [];

  const jdzLayerName = `${MIDI_OBJECTS_LAYERS.jdz}Layer-geojson`;
  if (selectedElements.includes(MIDI_OBJECTS_KEYS.jdz)
    && !geoEditor.snappingLayers.includes(jdzLayerName)
    && chartisParams !== undefined) {
    const jdzResults = await getGeoJSONData(MIDI_OBJECTS_LAYERS.jdz, chartisParams);
    const formattedJdz = jdzResults.map((obj) => ({
      ...obj,
      properties: {
        ...obj.properties,
        renderType: 'Point',
      },
    }));
    store.dispatch(updateJdz(formattedJdz));
    snappingPoints = [...snappingPoints, ...formattedJdz];
    store.dispatch(updateSnappingLayers([...geoEditor.snappingLayers, jdzLayerName]));
  }

  const signalLayerName = `${MIDI_OBJECTS_LAYERS.signal}Layer-geojson`;
  if (selectedElements.includes(MIDI_OBJECTS_KEYS.signal)) {
    const signalsResults = await getGeoJSONData(MIDI_OBJECTS_LAYERS.signal, chartisParams);
    const formattedSignals = signalsResults.map((obj) => ({
      ...obj,
      properties: {
        ...obj.properties,
        renderType: 'Point',
      },
    }));
    store.dispatch(updateSignals(formattedSignals));
    snappingPoints = [...snappingPoints, ...formattedSignals];
    store.dispatch(updateSnappingLayers([...geoEditor.snappingLayers, signalLayerName]));
    // } else {
    // updateSignals();
    // dispatch(updateSnappingLayers(snappingLayers.filter((layer) => layer !== signalLayerName)));
  }

  store.dispatch(updateDataPoints([...geoEditor.tivPoints, ...snappingPoints]));
};

export const getChartisParams = (mapRef) => {
  const { _ne: ne, _sw: sw } = mapRef.current.getMap().getBounds();
  const windowBbox = {
    type: 'Polygon',
    coordinates: [[
      [sw.lng, sw.lat],
      [ne.lng, sw.lat],
      [ne.lng, ne.lat],
      [sw.lng, ne.lat],
      [sw.lng, sw.lat],
    ]],
  };

  return {
    srid: 4326,
    page_size: 8000,
    bbox: windowBbox,
  };
};

export const refreshLinearData = async (
  tivResults, chartisParams, featureInfoClick, forceRefresh = false,
) => {
  const { geoEditor, map } = store.getState();
  const { selectedData } = geoEditor;
  const allPointsCoords = [];

  const features = await getGeoJSONData(map.selectedObjectLayer.sourceTable, chartisParams);

  const selectedKeys = map.elements.selected.objects.map((el) => el.key);

  await addSnappingLayers(selectedKeys, chartisParams);

  // Dealing with MultiLineStrings
  if (features !== null) {
    const [tempData, tivPointsResults] = formatFeatures(features, allPointsCoords, tivResults);
    store.dispatch(updateTivPoints(tivPointsResults));
    store.dispatch(updateDataPoints(tivPointsResults));

    const shouldUpdateSelectedData = featureInfoClick?.features?.length > 0
            && (selectedData === undefined
              || selectedData[0].properties.OP_id !== map.popupContent.OP_id);
    if (shouldUpdateSelectedData || forceRefresh) {
      const clickedFeatures = tempData.filter(
        (el) => el.properties.OP_id === map.popupContent.OP_id,
      );

      updateClickedFeatures(clickedFeatures);
    }
  }
};

export const refreshTivData = async (tivResults, featureInfoClick) => {
  const { geoEditor, map } = store.getState();
  const { selectedData } = geoEditor;

  const tivPointsResults = [];

  const [
    formatedTivResults, formatedTivPointsResults,
  ] = formatFeatures(tivResults, tivPointsResults, tivResults);

  store.dispatch(updateTivPoints(formatedTivPointsResults));
  store.dispatch(updateData(formatedTivResults));
  store.dispatch(updateDataPoints(formatedTivPointsResults));
  const shouldUpdateSelectedData = featureInfoClick?.features?.length > 0
    && (selectedData === undefined
      // eslint-disable-next-line camelcase
      || selectedData[0].properties.OP_id !== map.popupContent.OP_id);
  if (shouldUpdateSelectedData) {
    const clickedFeatures = formatedTivResults.filter(
      (el) => el.properties.OP_id === map.popupContent.OP_id,
    );
    updateClickedFeatures(clickedFeatures);
  }
};

export const refreshCreationData = async (tivResults, chartisParams) => {
  const { map } = store.getState();
  const allPointsCoords = [];

  const selectedObject = getMidiObjectByKey(map.selectedObjectToCreate.id);
  if (selectedObject.isLinear) {
    const newObjectTable = selectedObject.sourceTable;
    const features = await getGeoJSONData(newObjectTable, chartisParams);

    if (map.elements.selected.objects.length > 0) {
      const selectedKeys = map.elements.selected.objects.map((el) => el.key);

      addSnappingLayers(selectedKeys, chartisParams);
    }

    if (features !== null) {
      const [, tivPointsResults] = formatFeatures(features, allPointsCoords, tivResults);

      store.dispatch(updateTivPoints(tivPointsResults));
      store.dispatch(updateDataPoints(tivPointsResults));
    }
  }
};

export const buildCustomGeom = () => {
  const { map, geoEditor } = store.getState();
  const { selectedData } = geoEditor;

  let geomSch;
  const isCreationMode = map.mode === MAP_MODES.creation;
  const selectedObject = map.selectedObjectToCreate !== undefined
    ? getMidiObjectByKey(map.selectedObjectToCreate.id)
    : undefined;

  if (isCreationMode
    && (selectedObject === undefined || !selectedObject.isLinear)) {
    return undefined;
  }

  if (selectedData === undefined) {
    return undefined;
  }

  if (selectedData.length === 1) {
    const roundedCoordinates = selectedData[0].geometry.coordinates
      .map((pointCoords) => pointCoords
        .map((coord) => roundNumber(coord, 10)));
    geomSch = {
      ...selectedData[0].geometry,
      coordinates: roundedCoordinates,
    };
  } else {
    geomSch = selectedData.reduce((acc, lineString) => ({
      ...acc,
      coordinates: [...acc.coordinates, lineString.geometry.coordinates]
        .map((lineCoords) => lineCoords
          .map((pointCoords) => pointCoords
            .map((coord) => roundNumber(coord, 10)))),
    }), {
      type: 'MultiLineString',
      coordinates: [],
    });
    geomSch.coordinates.filter((lineString) => lineString.length > 1);
  }

  if (map.mode === MAP_MODES.creation) store.dispatch(updateSelectedData());

  return geomSch;
};

export const pointToFeatureDistance = (feature, refPoint) => {
  if (feature.geometry.type === 'Point') return distance(feature.geometry, refPoint);

  return pointToLineDistance(refPoint, feature);
};

export const snapOnHover = (e, displaySideBar, editorRef) => {
  const { geoEditor, map } = store.getState();
  const { snappingLayers } = geoEditor;

  const snappingFeatures = e.features.filter((f) => snappingLayers.includes(f.layer.id));
  if ((displaySideBar || map.mode === MAP_MODES.creation) && snappingFeatures.length > 0) {
    let chosenFeature = snappingFeatures[0];
    if (e.lngLat) {
      const snappingPoints = snappingFeatures.filter((f) => f.geometry.type === 'Point');
      const chosenSnappingFeatures = snappingPoints.length > 0
        ? snappingPoints
        : snappingFeatures;
      const mousePoint = point(e.lngLat);
      let minDist = pointToFeatureDistance(chosenFeature, mousePoint);
      chosenSnappingFeatures.forEach((snappingFeature) => {
        const dist = pointToFeatureDistance(snappingFeature, mousePoint);
        if (dist < minDist) {
          minDist = dist;
          chosenFeature = snappingFeature;
        }
      });
    }

    store.dispatch(updateSnappedGeometry(chosenFeature));
    if (editorRef.current && editorRef.current !== null && editorRef.current.state.isDragging) {
      store.dispatch(updateMouseCoords(e.lngLat));
    }
  }
};
