import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import jwtDecode from 'jwt-decode';

// Assets and Styles
import * as allMapActions from 'reducers/map';
import * as allZonesActions from 'reducers/zones';
import * as allDrawActions from 'reducers/draw';
import {
  EditingMode, DrawPolygonMode, Editor,
} from 'react-map-gl-draw';
import InputSNCF from 'common/BootstrapSNCF/InputSNCF';
import DrawTool from 'common/Map/DrawTool/DrawTool';
import nextId from 'react-id-generator';
import { stringify } from 'wellknown';
import { midiObjects } from 'common/Map/const';
import ErrorBoundary from 'common/ErrorBoundaries/ErrorBoundary';
import BaseMode from 'react-map-gl-draw/dist/edit-modes/base-mode';

const groups = [
  {
    id: 1,
    name: 'LeBonIt',
  },
];

const objects = midiObjects.filter((obj) => obj.isEditable);

export class RawDrawToolLayer extends Component {
  static propTypes = {
    map: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    draw: PropTypes.object.isRequired,
    mapActions: PropTypes.object.isRequired,
    zonesActions: PropTypes.object.isRequired,
    drawActions: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    modalId: PropTypes.string.isRequired,
    updateModal: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      saveZone: {
        drawZoneName: '',
        selectedGroup: groups[0],
        selectedObjects: objects,
      },
      modalHeader: '',
      modalBody: '',
      modalFooter: '',
    };

    this.editorRef = React.createRef();
  }

  componentDidMount = () => {
    const { draw: { feature, mode, zone }, drawActions } = this.props;
    const { saveZone } = this.state;
    if (feature && this.editorRef.current !== null && mode instanceof EditingMode) {
      this.editorRef.current.addFeatures(feature);
      drawActions.updateSelectedFeatureIndex(0);
      this.setState({
        saveZone: {
          ...saveZone,
          drawZoneName: zone.name,
        },
      });
    }
  }

  componentWillUnmount = () => {
    const { drawActions } = this.props;
    drawActions.updateZone();
    drawActions.updateFeature();
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { updateModal, draw: { feature, zone } } = this.props;
    const {
      modalHeader, modalBody, modalFooter, saveZone,
    } = this.state;

    if (prevState.saveZone !== saveZone) {
      const saveModalBody = this.renderSaveBody();
      updateModal(
        modalHeader,
        saveModalBody,
        modalFooter,
      );
    } else if (
      prevState.modalHeader !== modalHeader
        || prevState.modalBody !== modalBody
        || prevState.modalFooter !== modalFooter
      ) {
      updateModal(
        modalHeader,
        modalBody,
        modalFooter,
      );
    }
  }

  toggleObject = (object) => {
    const { saveZone } = this.state;
    const { selectedObjects } = saveZone;

    let newSelectedObjects;

    if (selectedObjects.includes(object)) {
      newSelectedObjects = selectedObjects.filter((obj) => obj.key !== object.key);
    } else {
      newSelectedObjects = [...selectedObjects, object];
    }

    this.setState((previousState) => ({
      saveZone: {
        ...previousState.saveZone,
        selectedObjects: newSelectedObjects,
      },
    }));
  }

  // Draw Tool methods
  buildErrorModal = () => {
    const { t, modalId } = this.props;

    const modalHeader = (
      <div>
        {t('Map.drawZone.errorModal.header')}
      </div>
    );
    const modalBody = (
      <div className="mb-4">
        {t('Map.drawZone.errorModal.body')}
      </div>
    );
    const modalFooter = (
      <div>
        <button
          type="button"
          className="btn btn-sm btn-primary"
          data-target={`#${modalId}`}
          data-toggle="modal"
          data-dismiss="modal"
        >
          <span>{t('Map.drawZone.errorModal.footer')}</span>
        </button>
      </div>
    );

    this.setState({
      modalHeader,
      modalBody,
      modalFooter,
    });
  }

  clearSelectedZone = (selectedIndex) => {
    const { drawActions } = this.props;
    this.editorRef.current.deleteFeatures(selectedIndex);
    drawActions.updateSelectedFeatureIndex(undefined);
    drawActions.updateMode(new BaseMode());
  }

  saveZone = async () => {
    const {
      zonesActions, map, user, drawActions, draw, t,
    } = this.props;
    const { saveZone } = this.state;
    const { drawZoneName, selectedGroup } = saveZone;

    console.log(this.editorRef.current.getFeatures()[draw.selectedFeatureIndex].geometry);
    const zoneWKT = stringify(this.editorRef.current.getFeatures()[0].geometry);
    let res;

    if (draw.feature && draw.zone) {
      const props = {
        group_id: selectedGroup.id,
        name: drawZoneName,
        zone: zoneWKT,
        center_x: map.viewport.latitude,
        center_y: map.viewport.longitude,
        center_z: map.viewport.zoom,
        types: [],
      };

      res = await zonesActions.updateProperties(draw.zone.id, props);
    } else {
      const newZone = {
        status: 0,
        group_id: selectedGroup.id,
        name: drawZoneName,
        zone: zoneWKT,
        center_x: map.viewport.latitude,
        center_y: map.viewport.longitude,
        center_z: map.viewport.zoom,
        owner_id: jwtDecode(user.accessToken).sub,
        types: [],
      };
      res = await zonesActions.createZone(newZone);
    }

    if (res === undefined) {
      const modalHeader = (
        <strong>{t('Map.drawZone.saveZoneNotice')}</strong>
      );
      const modalBody = (<></>);
      const modalFooter = (
        <div className="d-flex align-items-end justify-content-start" style={{ width: '420px' }}>
          <div className="btn-group dropdown">
            <button
              type="button"
              className="btn btn-sm btn-primary"
              data-dismiss="modal"
            >
              <span>Ok</span>
            </button>
          </div>
        </div>
      );

      this.setState({
        modalHeader,
        modalBody,
        modalFooter,
      });
    } else {
      this.setState({ modalBody: '' });
    }

    drawActions.updateMode(new BaseMode());
  }

  onDrawEnterMode = () => {
    const { mapActions, t, drawActions } = this.props;
    const enterMode = () => {
      mapActions.updateSelectedZone();
      drawActions.updateMode(new DrawPolygonMode());
    };

    // Build modal
    const modalHeader = (
      <div>
        {t('Map.drawZone.enterModal.header')}
      </div>
    );
    const modalBody = (<></>);
    const modalFooter = (
      <div>
        <button
          type="button"
          className="btn btn-sm btn-secondary mr-2"
          data-dismiss="modal"
        >
          <span>{t('Map.drawZone.cancel')}</span>
        </button>
        <button
          type="button"
          className="btn btn-sm btn-primary"
          data-dismiss="modal"
          onClick={enterMode}
        >
          <span>{t('Map.drawZone.enterModal.enterButton')}</span>
        </button>
      </div>
    );

    this.setState({
      modalHeader,
      modalBody,
      modalFooter,
    });
  }

  onDrawSelect = (options) => {
    const { drawActions } = this.props;
    drawActions.updateSelectedFeatureIndex(options && options.selectedFeatureIndex);
  };

  onDrawDelete = () => {
    const { draw, t, drawActions } = this.props;
    const selectedIndex = draw.selectedFeatureIndex;

    if (selectedIndex !== null && selectedIndex >= 0) {
      // Build modal
      const modalHeader = (
        <div>
          {t('Map.drawZone.deleteModal.header')}
        </div>
      );
      const modalBody = (<></>);
      const modalFooter = (
        <div>
          <button
            type="button"
            className="btn btn-sm btn-secondary mr-2"
            data-dismiss="modal"
          >
            <span>{t('Map.drawZone.cancel')}</span>
          </button>
          <button
            type="button"
            className="btn btn-sm btn-danger"
            data-dismiss="modal"
            onClick={() => this.clearSelectedZone(selectedIndex)}
          >
            <span>{t('Map.drawZone.delete')}</span>
          </button>
        </div>
      );

      this.setState({
        modalHeader,
        modalBody,
        modalFooter,
      });
    } else if (draw.feature) {
      drawActions.updateFeature();
    } else {
      // Display an error modal if no area is selected
      this.buildErrorModal();
    }
  };

  onDrawUpdate = ({ editType }) => {
    const { drawActions } = this.props;
    if (editType === 'addFeature') {
      drawActions.updateMode(new EditingMode());
    }
  };

  onDrawSave = () => {
    const { draw, t } = this.props;

    let modalHeader;
    let modalBody;
    let modalFooter;

    // Check if an area is selected
    const selectedIndex = draw.selectedFeatureIndex;
    if (selectedIndex !== null && selectedIndex >= 0) {
      // Build modal
      modalHeader = (
        <div>
          {t('Map.drawZone.modalHeader')}
        </div>
      );
      modalBody = this.renderSaveBody();
      modalFooter = (
        <ErrorBoundary>
          <div>
            <button
              type="button"
              className="btn btn-sm btn-primary"
              onClick={() => { this.saveZone(); }}
            >
              <span>{t('Map.drawZone.saveButton')}</span>
            </button>
          </div>
        </ErrorBoundary>
      );
    } else {
      // Display an error modal if no area is selected
      modalHeader = (
        <div>
          {t('Map.drawZone.errorModal.header')}
        </div>
      );
      modalBody = (
        <div className="mb-4">
          {t('Map.drawZone.errorModal.body')}
        </div>
      );
      modalFooter = (
        <div>
          <button
            type="button"
            className="btn btn-sm btn-primary"
            data-dismiss="modal"
          >
            <span>{t('Map.drawZone.errorModal.footer')}</span>
          </button>
        </div>
      );
    }

    this.setState({
      modalHeader,
      modalBody,
      modalFooter,
    });
  }

  renderSaveBody = () => {
    const { t } = this.props;
    const { saveZone } = this.state;
    const { selectedObjects, selectedGroup } = saveZone;

    return (
      <>
        <InputSNCF
          type="text"
          id="zone-name"
          value={saveZone.drawZoneName}
          onChange={(e) => {
            e.persist();
            this.setState((previousState) => ({
              saveZone: {
                ...previousState.saveZone,
                drawZoneName: e.target.value,
              },
            }));
          }}
          placeholder={t('Map.drawZone.namePlaceholder')}
          label={t('Map.drawZone.name')}
        />
        <div style={{ minWidth: 200 }}>
          <select
            id={`type-select${nextId()}`}
            value={JSON.stringify(selectedGroup)}
            onChange={(e) => this.setState((previousState) => ({
              saveZone: {
                ...previousState.saveZone,
                selectedGroup: JSON.parse(e.target.value),
              },
            }))}
          >
            {
              groups.map((group) => (
                <option
                  key={group.id}
                  data-id="1"
                  value={JSON.stringify(group)}
                >
                  { group.name }
                </option>
              ))
            }
          </select>
        </div>
      </>
    );
  }

  render() {
    const { modalId, draw: { mode, feature } } = this.props;

    const diplayZoneEditorLayer = mode instanceof DrawPolygonMode
                               || mode instanceof EditingMode
                               || feature;

    return (
      <>
        {diplayZoneEditorLayer && (
          <Editor
            ref={this.editorRef}
            style={{ width: '100%', height: '100%' }}
            clickRadius={12}
            mode={mode}
            onSelect={this.onDrawSelect}
            onUpdate={this.onDrawUpdate}
            editHandleShape="circle"
          />
        )}
        <div className="p-2 tools-container">
          <DrawTool
            onEnterMode={this.onDrawEnterMode}
            onSave={this.onDrawSave}
            onDelete={this.onDrawDelete}
            modalId={modalId}
          />
        </div>
      </>
    );
  }
}

const DrawToolLayer = withTranslation()(RawDrawToolLayer);

const mapStateToProps = (state) => ({
  map: state.map,
  user: state.user,
  draw: state.draw,
});

const mapDispatchToProps = (dispatch) => ({
  mapActions: bindActionCreators(allMapActions, dispatch),
  zonesActions: bindActionCreators(allZonesActions, dispatch),
  drawActions: bindActionCreators(allDrawActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DrawToolLayer);
