import React from 'react';
import { deleteSelectedPoint, toggleExtendLineMode } from 'components/GeoEditor/helpers/core';
import { useSelector } from 'react-redux';
import { MIDI_OBJECTS_KEYS } from 'common/Map/Consts/MidiObjects';

export default function GeoEditorSideBarActions() {
  const map = useSelector((state) => state.map);

  const displayExtendLine = map.selectedObjectLayer.key !== MIDI_OBJECTS_KEYS.tivSch;

  return (
    <>
      <div className="row m-2 my-4">
        <div className="col-9">
          <h2><strong>Supprimer un point</strong></h2>
        </div>
        <div className="col-3 d-flex justify-content-center align-items-center">
          <button className="btn btn-md w-100" type="button" onClick={deleteSelectedPoint}>
            <i className="icons-close icons-size-x5" aria-hidden="true" />
          </button>
        </div>
        <div className="col-12 justify-items-center d-flex flex-column">
          <p>
            - Cliquer sur le point à supprimer
            <br />
            - Taper sur la touche
            {' '}
            <strong>RETOUR EN ARRIÈRE</strong>
            {' '}
            ou cliquer sur le bouton ci-contre pour supprimer le point
          </p>
        </div>
      </div>
      {displayExtendLine && (
        <div className="row m-2 my-4">
          <div className="col-9">
            <h2><strong>Étendre une ligne</strong></h2>
          </div>
          <div className="col-3 d-flex justify-content-center align-items-center">
            <button className="btn btn-md w-100" type="button" onClick={toggleExtendLineMode}>
              CTRL
            </button>
          </div>
          <div className="col-12 justify-items-center d-flex flex-column">
            <p>
              - Cliquer sur le point de départ de l'extension
              <br />
              - Taper sur la touche
              {' '}
              <strong>CTRL</strong>
              {' '}
              ou cliquer sur le bouton ci-contre pour basculer en mode extention de ligne
              <br />
              - Cliquer sur la carte pour définir les points de l'extension
              <br />
              - Retaper sur la touche
              {' '}
              <strong>CTRL</strong>
              {' '}
              pour valider l'extension et revenir en mode modification de ligne
            </p>
          </div>
        </div>
      )}
    </>
  );
}
