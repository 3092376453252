import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as allProfileActions from 'reducers/profile';

import InputSNCF from 'common/BootstrapSNCF/InputSNCF';
import TableSNCF from 'common/BootstrapSNCF/TableSNCF';
import ModalSNCF from 'common/BootstrapSNCF/ModalSNCF/ModalSNCF';
import ModalHeaderSNCF from 'common/BootstrapSNCF/ModalSNCF/ModalHeaderSNCF';
import ModalBodySNCF from 'common/BootstrapSNCF/ModalSNCF/ModalBodySNCF';
import ModalFooterSNCF from 'common/BootstrapSNCF/ModalSNCF/ModalFooterSNCF';
import SelectSNCF from 'common/BootstrapSNCF/SelectSNCF';
import ErrorBoundary from 'common/ErrorBoundaries/ErrorBoundary';

class RawProfileManagement extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    profile: PropTypes.object.isRequired,
    profileActions: PropTypes.object.isRequired,
    tableHeaders: PropTypes.array.isRequired,
    htmlId: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      modalHeader: '',
      modalBody: '',
      modalFooter: '',
      selectedGroup: {},
    };
    this.modalId = 'add-user-modal';
  }

  componentDidMount = async () => {
    const { profileActions } = this.props;
    try {
      await profileActions.getUsers();
    } catch (e) {
      console.log(e);
    }
  }

  search = (value) => {
    const { profileActions } = this.props;
    profileActions.updateSearch(value);
  }

  getActions = () => {
    // const actions = (
    //   <div className="cell-inner">
    //     <button
    //       className="btn btn-only-icon btn-transparent btn-color-gray"
    //       type="button"
    //       title="Restaurer"
    //       onClick={() => {}}
    //     >
    //       <i className="icons-pencil" />
    //     </button>
    //   </div>
    // );
    // return actions;
  };

  addUser = (e) => {
    const { profile, profileActions } = this.props;
    let newUser = { ...profile.newUser };
    newUser = { ...newUser, [`${e.currentTarget.getAttribute('id')}`]: e.currentTarget.value };
    profileActions.updateNewUser(newUser);
  }

  createUser = async () => {
    const { t, profile, profileActions } = this.props;
    const { selectedGroup } = this.state;

    const newUser = await profileActions.createUser(profile.newUser, { group_id: selectedGroup.id });

    if (Object.keys(newUser).length !== 0) {
      const modalHeader = (
        <strong>{t('Logs.profile.creationNotice')}</strong>
      );
      const modalBody = (
        <div className="my-4">
          <div className="mb-3">
            <strong>
              {t('Logs.profile.userName')}
              :
              {' '}
            </strong>
            {newUser.username}
          </div>
          <div>
            <strong>
              {t('Logs.profile.password')}
              :
              {' '}
            </strong>
            {newUser.credentials[0].value}
          </div>
        </div>
      );
      const modalFooter = (
        <div className="d-flex align-items-end justify-content-start" style={{ width: '420px' }}>
          <div className="btn-group dropdown">
            <button
              type="button"
              className="btn btn-sm btn-primary"
              data-dismiss="modal"
              onClick={() => {}}
            >
              <span>Ok</span>
            </button>
          </div>
        </div>
      );

      this.setState({
        modalHeader,
        modalBody,
        modalFooter,
      });
    } else {
      this.setState({ modalBody: '' });
    }
  }

  onUserAdd = () => {
    const { t, profile } = this.props;
    const { selectedGroup } = this.state;
    const defaultSelectedGroup = profile.groups.filter((group) => group.name === 'Midi - Utilisateur')[0];
    this.setState({ selectedGroup: defaultSelectedGroup });

    const modalHeader = (
      <div>
        <strong>{t('Logs.profile.addUser')}</strong>
      </div>
    );
    const modalBody = (
      <div className="mb-4">
        <InputSNCF
          type="text"
          label={t('Logs.profile.firstName')}
          id="firstName"
          onChange={this.addUser}
        />
        <InputSNCF
          type="text"
          id="lastName"
          label={t('Logs.profile.lastName')}
          onChange={this.addUser}
        />
        <InputSNCF
          type="email"
          id="email"
          label={t('Logs.profile.email')}
          onChange={this.addUser}
        />
        <SelectSNCF
          title={t('Logs.profile.profile')}
          options={profile.groups}
          selectedValue={JSON.stringify(selectedGroup) === '{}' ? defaultSelectedGroup : selectedGroup}
          onChange={(e) => this.setState({ selectedGroup: JSON.parse(e.target.value) })}
        />
      </div>
    );
    const modalFooter = (
      <ErrorBoundary>
        <div className="d-flex align-items-end justify-content-end" style={{ width: '420px' }}>
          <div className="btn-group dropdown">
            <button type="button" className="btn btn-sm btn-secondary mr-2" data-dismiss="modal">
              <span>{t('Logs.profile.cancel')}</span>
            </button>
          </div>
          <div className="btn-group dropdown">
            <button
              type="button"
              className="btn btn-sm btn-primary"
              onClick={this.createUser}
            >
              <span>{t('Logs.profile.addUserButton')}</span>
            </button>
          </div>
        </div>
      </ErrorBoundary>
    );

    this.setState({
      modalHeader,
      modalBody,
      modalFooter,
    });
  }

  render() {
    const {
      t, profile, tableHeaders, htmlId,
    } = this.props;

    const { search } = profile;

    const {
      modalHeader, modalBody, modalFooter,
    } = this.state;

    const searchAppendOptions = {
      iconName: 'icons-search',
      onClick: () => {},
      name: 'profile-search',
    };

    return (
      <>
        <div className="row my-3 d-flex justify-content-between">
          <div className="col-4 mr-5">
            <InputSNCF
              label={t('Logs.filters.search')}
              htmlId={`${htmlId}-search`}
              value={search}
              appendOptions={searchAppendOptions}
              onChange={(e) => this.search(e.target.value)}
              onClear={() => this.search('')}
              placeholder={t('Logs.filters.searchPlaceholder')}
              noMargin
            />
          </div>
          <div className="mr-4 d-flex align-items-end">
            <button
              type="button"
              className="btn btn-primary"
              data-target={`#${this.modalId}`}
              data-toggle="modal"
              onClick={this.onUserAdd}
            >
              <span>{t('Logs.profile.addUser')}</span>
            </button>
          </div>
        </div>
        <div className="row" style={{ overflow: 'auto', maxHeight: '59vh' }}>
          <TableSNCF
            heads={tableHeaders}
            actions={this.getActions}
            content={profile.filteredUsers}
            modalId="affectation-modal"
          />
        </div>
        <ModalSNCF htmlID={this.modalId}>
          <ModalHeaderSNCF>
            {modalHeader}
          </ModalHeaderSNCF>
          <ModalBodySNCF>
            {modalBody}
          </ModalBodySNCF>
          <ModalFooterSNCF>
            {modalFooter}
          </ModalFooterSNCF>
        </ModalSNCF>
      </>
    );
  }
}

const ProfileManagement = withTranslation()(RawProfileManagement);

const mapStateToProps = (state) => ({
  profile: state.profile,
});

const mapDispatchToProps = (dispatch) => ({
  profileActions: bindActionCreators(allProfileActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileManagement);
