import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { debounce } from 'debounce';

import Loader from 'common/Loader';
import InputSNCF from 'common/BootstrapSNCF/InputSNCF';
import SearchResults from 'components/Map/Search/SearchResults';

import { updateSearch, updateSearchResults as updateSearchResultsReducer } from 'reducers/map';
import { useTranslation } from 'react-i18next';
import { get } from 'common/requests';

const searchAppendOptions = {
  iconName: 'icons-search',
  onClick: () => { },
  name: 'map-search',
};

function Search({ updateSelectedFeature }) {
  const dispatch = useDispatch();
  const searchContainerRef = useRef();
  const { search, isSearchLoading, ref } = useSelector((state) => state.map);
  const { t } = useTranslation();

  const getScreenBbox = () => {
    const { _ne: ne, _sw: sw } = ref.current.getMap().getBounds();
    return {
      type: 'Polygon',
      coordinates: [[
        [sw.lng, sw.lat],
        [ne.lng, sw.lat],
        [ne.lng, ne.lat],
        [sw.lng, ne.lat],
        [sw.lng, sw.lat],
      ]],
    };
  };

  const updateSearchResults = debounce(() => {
    const bbox = getScreenBbox();
    dispatch(updateSearchResultsReducer(bbox));
  }, 800);

  const localUpdateSearch = async (newSearch) => {
    dispatch(updateSearch(newSearch));
    if (newSearch.length >= 3) {
      updateSearchResults();
    }
  };

  const onResultClick = async (result) => {
    console.log(result);

    const info = await get(`/chartisv1/layer/${result.properties.layer_slug}/geojson/sch/`, { OP_id: result.properties.OP_id, srid: 4326 });

    localUpdateSearch('');
    updateSelectedFeature({
      ...info.features[0],
      layer: {
        'source-layer': result.properties.layer_slug,
      },
    });
  };

  const inputActive = search ? 'active' : '';

  return (
    <div
      className={`advanced-search w-100 ${inputActive}`}
      ref={searchContainerRef}
    >
      <div
        className="advanced-search-control"
      >
        <InputSNCF
          id="map-search"
          onChange={(e) => localUpdateSearch(e.target.value)}
          onClear={() => localUpdateSearch('')}
          noMargin
          type="text"
          clearButton
          value={search}
          appendOptions={searchAppendOptions}
          placeholder={t('Map.search.placeholder')}
        />
        {search && (
          <div role="list" className="advanced-search-menu" data-role="menu">
            {isSearchLoading && <Loader center />}
            {!isSearchLoading && <SearchResults onResultClick={onResultClick} />}
          </div>
        )}
      </div>
    </div>
  );
}

Search.propTypes = {
  updateSelectedFeature: PropTypes.func.isRequired,
};

export default Search;
