/* eslint-disable default-case */
import produce from 'immer';
import { MODES, mockFeature, INITIAL_SNAPPING_LAYERS } from 'components/GeoEditor/const';

// Action Types
export const UPDATE_MODE = 'geoEditor/UPDATE_MODE';
export const UPDATE_DATA = 'geoEditor/UPDATE_DATA';
export const UPDATE_DATA_POINTS = 'geoEditor/UPDATE_DATA_POINTS';
export const UPDATE_SELECTED_DATA = 'geoEditor/UPDATE_SELECTED_DATA';
export const UPDATE_JDZ = 'geoEditor/UPDATE_JDZ';
export const UPDATE_SIGNALS = 'geoEditor/UPDATE_SIGNALS';
export const UPDATE_TIV = 'geoEditor/UPDATE_TIV';
export const UPDATE_TIV_POINTS = 'geoEditor/UPDATE_TIV_POINTS';
export const UPDATE_HANDLE_INDEX = 'geoEditor/UPDATE_HANDLE_INDEX';
export const UPDATE_SELECTED_FEATURE_INDEX = 'geoEditor/UPDATE_SELECTED_FEATURE_INDEX';
export const UPDATE_SNAPPING_LAYERS = 'geoEditor/UPDATE_SNAPPING_LAYERS';
export const UPDATE_SNAPPED_GEOMETRY = 'geoEditor/UPDATE_SNAPPED_GEOMETRY';
export const UPDATE_MOUSE_COORDS = 'geoEditor/UPDATE_MOUSE_COORDS';
export const TOGGLE_INFRA_EDITOR = 'geoEditor/TOGGLE_INFRA_EDITOR';
export const TOGGLE_DRAW_AT_FRONT = 'geoEditor/TOGGLE_DRAW_AT_FRONT';
export const TOGGLE_SHOULD_DISPLAY = 'geoEditor/TOGGLE_SHOULD_DISPLAY';

// Reducer
const initialState = {
  shouldDisplay: false,
  mode: MODES.edit,
  data: [mockFeature('LineString')],
  dataPoints: [mockFeature('Point')],
  jdz: undefined,
  signals: undefined,
  tiv: [mockFeature('LineString')],
  tivPoints: [mockFeature('Point')],
  selectedData: undefined,
  selectedEditHandleIndex: null,
  selectedFeatureIndex: 0,
  infraEditor: false, // if modifying TIVs
  drawAtFront: true, // Continue LineString at the beginning of the line
  snappingLayers: INITIAL_SNAPPING_LAYERS,
  snappedGeometry: undefined,
  mouseCoords: null,
};

export default function reducer(state = initialState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case UPDATE_MODE:
        draft.mode = action.mode;
        break;
      case UPDATE_DATA:
        draft.data = action.data;
        break;
      case UPDATE_DATA_POINTS:
        draft.dataPoints = action.dataPoints;
        break;
      case UPDATE_JDZ:
        draft.jdz = action.jdz;
        break;
      case UPDATE_SIGNALS:
        draft.signals = action.signals;
        break;
      case UPDATE_SELECTED_DATA:
        draft.selectedData = action.selectedData;
        break;
      case UPDATE_TIV:
        draft.tiv = action.tiv;
        break;
      case UPDATE_TIV_POINTS:
        draft.tivPoints = action.tivPoints;
        break;
      case UPDATE_HANDLE_INDEX:
        draft.selectedEditHandleIndex = action.selectedEditHandleIndex;
        break;
      case UPDATE_SELECTED_FEATURE_INDEX:
        draft.selectedFeatureIndex = action.selectedFeatureIndex;
        break;
      case UPDATE_SNAPPING_LAYERS:
        draft.snappingLayers = action.snappingLayers;
        break;
      case UPDATE_SNAPPED_GEOMETRY:
        draft.snappedGeometry = action.snappedGeometry;
        break;
      case UPDATE_MOUSE_COORDS:
        draft.mouseCoords = action.mouseCoords;
        break;
      case TOGGLE_INFRA_EDITOR:
        draft.infraEditor = action.infraEditor;
        break;
      case TOGGLE_DRAW_AT_FRONT:
        draft.drawAtFront = action.drawAtFront;
        break;
      case TOGGLE_SHOULD_DISPLAY:
        draft.shouldDisplay = action.shouldDisplay;
        break;
    }
  });
}

// Action Creators
export const updateMode = (mode) => ({
  type: UPDATE_MODE,
  mode,
});

export const updateData = (data) => ({
  type: UPDATE_DATA,
  data,
});

export const updateDataPoints = (dataPoints) => ({
  type: UPDATE_DATA_POINTS,
  dataPoints,
});

export const updateJdz = (jdz) => ({
  type: UPDATE_JDZ,
  jdz,
});

export const updateSignals = (signals) => ({
  type: UPDATE_SIGNALS,
  signals,
});

export const updateTiv = (tiv) => ({
  type: UPDATE_TIV,
  tiv,
});

export const updateTivPoints = (tivPoints) => ({
  type: UPDATE_TIV_POINTS,
  tivPoints,
});

export const updateSelectedData = (selectedData) => ({
  type: UPDATE_SELECTED_DATA,
  selectedData,
});

export const updateSelectedEditHandleIndex = (selectedEditHandleIndex) => ({
  type: UPDATE_HANDLE_INDEX,
  selectedEditHandleIndex,
});

export const updateSelectedFeatureIndex = (selectedFeatureIndex) => ({
  type: UPDATE_SELECTED_FEATURE_INDEX,
  selectedFeatureIndex,
});

export const updateSnappingLayers = (snappingLayers) => ({
  type: UPDATE_SNAPPING_LAYERS,
  snappingLayers,
});

export const updateSnappedGeometry = (snappedGeometry) => ({
  type: UPDATE_SNAPPED_GEOMETRY,
  snappedGeometry,
});

export const updateMouseCoords = (mouseCoords) => ({
  type: UPDATE_MOUSE_COORDS,
  mouseCoords,
});

export const toggleInfraEditor = (infraEditor) => ({
  type: TOGGLE_INFRA_EDITOR,
  infraEditor,
});

export const toggleDrawAtFront = (drawAtFront) => ({
  type: TOGGLE_DRAW_AT_FRONT,
  drawAtFront,
});

export const toggleShouldDisplay = (shouldDisplay) => ({
  type: TOGGLE_SHOULD_DISPLAY,
  shouldDisplay,
});
