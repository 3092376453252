/* eslint-disable default-case */
import produce from 'immer';
import { ViewMode } from '@nebula.gl/edit-modes';

// Action Types
export const UPDATE_FEATURE = 'draw/UPDATE_FEATURE';
export const UPDATE_MODE = 'draw/UPDATE_MODE';
export const UPDATE_SELECTED_FEATURE_INDEX = 'draw/UPDATE_SELECTED_FEATURE_INDEX';
export const UPDATE_ZONE = 'draw/UPDATE_ZONE';

// Reducer
const initialState = {
  mode: new ViewMode(),
  selectedFeatureIndex: undefined,
  feature: undefined,
  zone: undefined,
};

export default function reducer(state = initialState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case UPDATE_FEATURE:
        draft.feature = action.feature;
        break;
      case UPDATE_SELECTED_FEATURE_INDEX:
        draft.selectedFeatureIndex = action.selectedFeatureIndex;
        break;
      case UPDATE_MODE:
        draft.mode = action.mode;
        break;
      case UPDATE_ZONE:
        draft.zone = action.zone;
        break;
    }
  });
}

// Action Creators
export const updateFeature = (feature) => ({
  type: UPDATE_FEATURE,
  feature,
});

export const updateMode = (mode) => ({
  type: UPDATE_MODE,
  mode,
});

export const updateSelectedFeatureIndex = (selectedFeatureIndex) => ({
  type: UPDATE_SELECTED_FEATURE_INDEX,
  selectedFeatureIndex,
});

export const updateZone = (zone) => ({
  type: UPDATE_ZONE,
  zone,
});
