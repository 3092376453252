import React from 'react';
import PropTypes from 'prop-types';
import { Popup } from 'react-map-gl';
import { useTranslation } from 'react-i18next';

import PopupInfos from 'common/Map/Popup/PopupInfos';
import MultipleObjectsPopupContent from 'components/MultipleObjectsPopupContent';

function MultipleObjectsPopup({
  displayMultiplePopup, featureInfoClick, scrollZoom, toggleScrollZoom,
  updateSelectedFeature, setDisplayMultiplePopup,
}) {
  const { t } = useTranslation();

  if (displayMultiplePopup && featureInfoClick && featureInfoClick.features.length > 2) {
    if (scrollZoom) toggleScrollZoom();
    return (
      <Popup
        longitude={featureInfoClick.lngLat[0]}
        latitude={featureInfoClick.lngLat[1]}
        closeButton={false}
        className="mapboxgl-hover-custom-popup"
      >
        <PopupInfos
          title={<strong>{`${featureInfoClick.features.length} ${t('Map.multipopupitems.itemsfounds')}`}</strong>}
          backgroundColor="bg-purple"
          content={(
            <MultipleObjectsPopupContent
              features={featureInfoClick.features}
              onObjectClick={(obj) => {
                updateSelectedFeature(obj);
                setDisplayMultiplePopup(false);
              }}
            />
          )}
        />
      </Popup>
    );
  }
  if (!scrollZoom) toggleScrollZoom();
  return null;
}

MultipleObjectsPopup.propTypes = {
  displayMultiplePopup: PropTypes.bool.isRequired,
  scrollZoom: PropTypes.bool.isRequired,
  toggleScrollZoom: PropTypes.func.isRequired,
  updateSelectedFeature: PropTypes.func.isRequired,
  setDisplayMultiplePopup: PropTypes.func.isRequired,
  featureInfoClick: PropTypes.object,
};

MultipleObjectsPopup.defaultProps = {
  featureInfoClick: undefined,
};

export default MultipleObjectsPopup;
