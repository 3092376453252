import React from 'react';
import { Source, Layer } from 'react-map-gl';
import { useSelector } from 'react-redux';
import mask from '@turf/mask';

function ZoneLayer() {
  const { selectedZone } = useSelector((state) => state.map);

  const maskedZone = mask(selectedZone.geometry);

  return (
    <Source id="selectedZone" type="geojson" data={maskedZone}>
      <Layer
        id="selectedZoneBackground"
        type="background"
        source="maskedZone"
        paint={{
          'background-color': '#888',
          'background-opacity': 0,
        }}
      />
      <Layer
        id="selectedZone"
        type="fill"
        source="maskedZone"
        paint={{
          'fill-color': '#888',
          'fill-opacity': 0.8,
        }}
      />
    </Source>
  );
}

export default ZoneLayer;
