import React from 'react';
import { Layer, Source } from 'react-map-gl';
import PropTypes from 'prop-types';

function TIV({ data }) {
  return (
    <Source
      id="tiv-source"
      type="geojson"
      data={{
        type: 'FeatureCollection',
        features: data,
      }}
    >
      <Layer
        id="tiv-lines"
        type="line"
        paint={{
          'line-color': ['case',
            ['==', ['get', 'type_voie'], 'VP'], '#000',
            ['==', ['get', 'type_voie'], 'VS'], '#888',
            '#666',
          ],
          'line-width': ['case',
            ['==', ['get', 'type_voie'], 'VP'], 3,
            ['==', ['get', 'type_voie'], 'VS'], 1,
            3,
          ],
        }}
      />
      <Layer
        id="tiv-points"
        type="circle"
        paint={{
          'circle-color': '#888',
          'circle-radius': 3,
        }}
      />
    </Source>
  );
}

TIV.propTypes = {
  data: PropTypes.array.isRequired,
};

export default TIV;
