import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import ActionBarItem, { BAR_ITEM_TYPES } from 'common/BootstrapSNCF/ActionBarSNCF/ActionBarItem';
import SwitchSNCF, { SWITCH_TYPES } from 'common/BootstrapSNCF/SwitchSNCF/SwitchSNCF';
import FloatingBar from 'common/FloatingBar/FloatingBar';

import Search from 'components/Map/Search/Search';
import { MAP_MODES, MAP_TRACK_SOURCES } from 'common/Map/const';
import * as mapActions from 'reducers/map';

function MenuBar({ updateSelectedFeature }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { mode } = useSelector((state) => state.map);

  const getMapModes = () => {
    const switchOptions = [];
    const mapModes = MAP_MODES;
    delete mapModes.creation;
    Object.entries(mapModes).forEach(([key, label]) => {
      switchOptions.push({
        value: key,
        label: t(`Map.modes.${label}`),
      });
    });

    return switchOptions;
  };

  const switchMode = (e) => {
    if (e.target.value === MAP_MODES.modification) {
      dispatch(mapActions.toggleTrackSource(MAP_TRACK_SOURCES.schematic));
    }
    dispatch(mapActions.updateMode(e.target.value));
  };

  return (
    <FloatingBar>
      <ActionBarItem
        type={BAR_ITEM_TYPES.custom}
        content={(
          <SwitchSNCF
            id="mapmodeswitch"
            type={SWITCH_TYPES.options}
            name="mapmodeswitch"
            options={getMapModes()}
            onChange={switchMode}
            checkedName={mode}
          />
          )}
        spacing
        left
      />
      <ActionBarItem
        type={BAR_ITEM_TYPES.custom}
        content={(<Search updateSelectedFeature={updateSelectedFeature} />)}
        spacing
      />
      <ActionBarItem
        type={BAR_ITEM_TYPES.iconButton}
        content="Filters"
        onClick={() => dispatch(mapActions.toggleFilters())}
        iconName="icons-filters"
      />
      <ActionBarItem
        type={BAR_ITEM_TYPES.iconButton}
        content="Objects"
        onClick={() => dispatch(mapActions.toggleObjects())}
        iconName="icons-list"
      />
    </FloatingBar>
  );
}

MenuBar.propTypes = {
  updateSelectedFeature: PropTypes.func.isRequired,
};

export default MenuBar;
