import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import SideBar from 'common/BootstrapSNCF/SideBarSNCF';
import ToolsBar, { JSON_KEYS } from 'components/Map/SideBar/SideBarTools';
import Table from 'components/DetailsTable';
import GeoEditorSideBarActions from 'components/GeoEditorSideBarActions';

import ModalSNCF from 'common/BootstrapSNCF/ModalSNCF/ModalSNCF';
import ModalHeaderSNCF from 'common/BootstrapSNCF/ModalSNCF/ModalHeaderSNCF';
import ModalBodySNCF from 'common/BootstrapSNCF/ModalSNCF/ModalBodySNCF';
import ModalFooterSNCF from 'common/BootstrapSNCF/ModalSNCF/ModalFooterSNCF';
import {
  DETAILS_TABLE_FIELDS, ELEMENT_TYPES, MAP_MODES, NEW_OBJECT_REF, NEW_PERIMETRE, NEW_POSTE,
} from 'common/Map/const';
import { validatePk, findPathsToKey } from 'utils/helpers';
import { get, isEqual } from 'lodash';
import ErrorBoundary from 'common/ErrorBoundaries/ErrorBoundary';
import {
  addSelectedElement,
  createObject, updateMode, updatePopupContent, updateSelectedObjectToCreate,
  updateSelectedSourceObject, updateSideBarTab, updateValidateSignal,
} from 'reducers/map';
import Loader from 'common/Loader';
import { MIDI_OBJECTS_KEYS } from 'common/Map/Consts/MidiObjects';
import { buildCustomGeom } from 'components/GeoEditor/helpers';

const CreationContent = ({ updateTiles }) => {
  const modalId = 'creation-modal';
  const dispatch = useDispatch();
  const map = useSelector((state) => state.map);
  const { t } = useTranslation();
  const [modalHeader, setModalHeader] = useState(null);
  const [modalBody, setModalBody] = useState(null);
  const [modalFooter, setModalFooter] = useState(null);

  const replaceEmpty = (object, fieldName) => {
    if (map?.popupContent[fieldName] && map.popupContent[fieldName].length === 1
      && isEqual(map.popupContent[fieldName][0], NEW_OBJECT_REF)) {
      return { ...object, [fieldName]: [] };
    }

    return object;
  };

  const getTitle = () => (
    <div>
      <strong>{map.selectedObjectToCreate.name.split(':')[0]}</strong>
    </div>
  );

  const onSideBarClose = () => {
    dispatch(updateSelectedObjectToCreate(undefined));
    dispatch(updateMode(MAP_MODES.display));
    dispatch(updateSelectedSourceObject({}));
    dispatch(updateValidateSignal(false));
    dispatch(updateSideBarTab('#geom'));
  };

  const validateNotice = () => {
    onSideBarClose();
    dispatch(updateMode(MAP_MODES.modification));
    dispatch(addSelectedElement(map.selectedObjectLayer, ELEMENT_TYPES.objects));
    dispatch(updateSelectedSourceObject({}));
  };

  const onValidate = async () => {
    const geomSch = buildCustomGeom();

    let newGaiaObject = {};
    // Check for associated tables
    if (Object.keys(map.popupContent).includes(DETAILS_TABLE_FIELDS.tables_associees)) {
      Object.keys(map.popupContent).forEach((key) => {
        if (key !== DETAILS_TABLE_FIELDS.tables_associees && key !== DETAILS_TABLE_FIELDS.itineraires_impactes) {
          newGaiaObject = { ...newGaiaObject, [`${key}`]: map.popupContent[key] };
        }
      });
    }
    // Check for responsibles tables
    if (Object.keys(map.popupContent).includes(DETAILS_TABLE_FIELDS.tables_responsables)) {
      Object.keys(map.popupContent).forEach((key) => {
        if (key !== DETAILS_TABLE_FIELDS.tables_responsables && key !== DETAILS_TABLE_FIELDS.itineraires_impactes) {
          newGaiaObject = { ...newGaiaObject, [`${key}`]: map.popupContent[key] };
        }
      });
    }
    // update impacted itineraries
    if (Object.keys(map.popupContent).includes(DETAILS_TABLE_FIELDS.itineraires_impactes) && map.itinerairesImpactes.length !== 0) {
      if (!Object.keys(map.popupContent).includes(DETAILS_TABLE_FIELDS.tables_associees)
      && !Object.keys(map.popupContent).includes(DETAILS_TABLE_FIELDS.tables_responsables)) {
        newGaiaObject = { ...map.popupContent, itineraires_impactes: map.itinerairesImpactes };
      } else {
        newGaiaObject = { ...newGaiaObject, itineraires_impactes: map.itinerairesImpactes };
      }
    }
    // update associated perimetres
    if (Object.keys(map.popupContent).includes(DETAILS_TABLE_FIELDS.perimetres_associes) && map.perimetresAssocies.length !== 0) {
      if (!Object.keys(map.popupContent).includes(DETAILS_TABLE_FIELDS.tables_associees)) {
        newGaiaObject = { ...map.popupContent, perimetres_associes: map.perimetresAssocies };
      } else {
        newGaiaObject = { ...newGaiaObject, perimetres_associes: map.perimetresAssocies };
      }
    }
    // update responsibles perimetres
    if (Object.keys(map.popupContent).includes(DETAILS_TABLE_FIELDS.perimetres_responsables) && map.perimetresResponsables.length !== 0) {
      if (!Object.keys(map.popupContent).includes(DETAILS_TABLE_FIELDS.tables_responsables)) {
        newGaiaObject = { ...map.popupContent, perimetres_responsables: map.perimetresResponsables };
      } else {
        newGaiaObject = { ...newGaiaObject, perimetres_responsables: map.perimetresResponsables };
      }
    }

    // remove empty object refs
    newGaiaObject = replaceEmpty(newGaiaObject, DETAILS_TABLE_FIELDS.groupements_catenaires);

    newGaiaObject = JSON.stringify(newGaiaObject) === '{}' ? map.popupContent : newGaiaObject;
    newGaiaObject = map.selectedObjectLayer.key !== MIDI_OBJECTS_KEYS.poste
      ? newGaiaObject
      : { RA_libelle: map.popupContent.RA_libelle, OP_id: map.popupContent.OP_id };

    newGaiaObject = geomSch === undefined
      ? newGaiaObject
      : {
        ...newGaiaObject,
        geomSch,
        isGeomSchFromLRS: false,
      };

    const params = {
      action_type: 'I',
      new_gaia_object: newGaiaObject,
      layer_name: map.selectedObjectLayer.sourceTable,
    };

    setModalBody(<></>);
    setModalFooter(<div className="mx-auto"><Loader center /></div>);

    const res = await dispatch(createObject(params));

    if (res !== undefined) {
      const addModalHeader = (
        <strong>{t('Map.popupDetails.createSuccess')}</strong>
      );
      const addModalBody = (<></>);
      const addModalFooter = (
        <div className="d-flex align-items-end justify-content-start" style={{ width: '420px' }}>
          <div className="btn-group dropdown">
            <button
              type="button"
              className="btn btn-sm btn-primary"
              data-dismiss="modal"
              onClick={validateNotice}
            >
              <span>{t('common.ok')}</span>
            </button>
          </div>
        </div>
      );

      setModalHeader(addModalHeader);
      setModalBody(addModalBody);
      setModalFooter(addModalFooter);

      updateTiles(res.tiles);
    } else {
      setModalFooter(<ErrorBoundary />);
    }
  };

  const validateObjects = () => {
    let isPkValid = true;
    const pathsToPk = findPathsToKey(map.popupContent, 'pk_sncf');
    if (pathsToPk && pathsToPk.length !== 0) {
      pathsToPk.forEach((path) => {
        isPkValid = isPkValid && validatePk(get(map.popupContent, path));
      });
    }
    let validateModalHeader;
    let validateModalBody;
    let validateModalFooter;

    if (!isPkValid) {
      validateModalHeader = (
        <div>
          <strong>{t('Map.popupDetails.errorModal.header')}</strong>
        </div>
      );
      validateModalBody = (
        <div>
          {t('Map.popupDetails.errorModal.body')}
        </div>
      );
      validateModalFooter = (
        <div className="d-flex align-items-end justify-content-end" style={{ width: '420px' }}>
          <div className="btn-group dropdown">
            <button type="button" className="btn btn-sm btn-secondary mr-2" data-dismiss="modal">
              <span>{t('Map.popupDetails.discartButton')}</span>
            </button>
          </div>
        </div>
      );
    } else {
      validateModalHeader = (
        <div>
          <strong>{t('Map.popupDetails.creationNotice')}</strong>
        </div>
      );
      validateModalBody = (<></>);
      validateModalFooter = (
        <div className="d-flex align-items-end justify-content-end" style={{ width: '420px' }}>
          <div className="btn-group dropdown">
            <button type="button" className="btn btn-sm btn-secondary mr-2" data-dismiss="modal">
              <span>{t('Map.popupDetails.discartButton')}</span>
            </button>
          </div>
          <div className="btn-group dropdown">
            <button type="button" className="btn btn-sm btn-primary" onClick={onValidate}>
              <span>{t('Map.popupDetails.createButton')}</span>
            </button>
          </div>
        </div>
      );
    }

    setModalHeader(validateModalHeader);
    setModalBody(validateModalBody);
    setModalFooter(validateModalFooter);
  };

  const addNewFields = (fieldKey) => {
    let fieldToAdd = {};
    const jsonField = [];
    let newPopupContent = { ...map.popupContent };
    if ([
      JSON_KEYS.extremites, JSON_KEYS.branches, JSON_KEYS.itineraires_impactes,
      JSON_KEYS.sels, JSON_KEYS.groupements_catenaires, JSON_KEYS.zeps,
    ].includes(fieldKey)) {
      map.popupContent[fieldKey].forEach((field) => jsonField.push(field));

      Object.keys(map.popupContent[fieldKey][0]).forEach((key) => {
        fieldToAdd = { ...fieldToAdd, [`${key}`]: '' };
      });

      jsonField.push(fieldToAdd);

      newPopupContent = { ...newPopupContent, [fieldKey]: jsonField };
    } else if (fieldKey === JSON_KEYS.adv_positionnes) {
      if (map.popupContent.adv_positionnes.length === 0) {
        const newAdv = {
          RA_libelle: '',
          OP_id: '',
          CAV_rang: '',
          branches: [
            {
              OP_id: '',
              BAPV_codeBranche: '',
            },
            {
              OP_id: '',
              BAPV_codeBranche: '',
            },
          ],
        };
        jsonField.push(newAdv);
      } else {
        map.popupContent.adv_positionnes.forEach((adv) => jsonField.push(adv));

        Object.entries(map.popupContent.adv_positionnes[0]).forEach(([key, value]) => {
          if (key !== 'branches') {
            fieldToAdd = { ...fieldToAdd, [`${key}`]: '' };
          } else {
            const newArr = [];
            value.forEach((v) => {
              let subField = {};
              Object.keys(v).forEach((k) => {
                subField = { ...subField, [k]: '' };
              });
              newArr.push(subField);
            });
            fieldToAdd = { ...fieldToAdd, [key]: newArr };
          }
        });

        jsonField.push(fieldToAdd);
      }

      newPopupContent = { ...newPopupContent, adv_positionnes: jsonField };
    } else if (fieldKey === JSON_KEYS.cles_protections) {
      map.popupContent.cles_protections.forEach((cle) => jsonField.push(cle));
      jsonField.push('');
      newPopupContent = { ...newPopupContent, cles_protections: jsonField };
    } else if (fieldKey === JSON_KEYS.poste) {
      newPopupContent = { ...newPopupContent, ...NEW_POSTE };
    } else if (fieldKey === JSON_KEYS.tables_responsables) {
      newPopupContent = { ...newPopupContent, tables_responsables: '' };
    } else if (fieldKey === JSON_KEYS.tables_associees) {
      newPopupContent = { ...newPopupContent, tables_associees: '' };
    } else if (fieldKey === JSON_KEYS.is_mgpt) {
      newPopupContent = { ...newPopupContent, is_mgpt: false };
    } else if (fieldKey === JSON_KEYS.perimetres_associes) {
      newPopupContent = { ...newPopupContent, perimetres_associes: [NEW_PERIMETRE] };
    } else if (fieldKey === JSON_KEYS.perimetres_responsables) {
      newPopupContent = { ...newPopupContent, perimetres_responsables: [NEW_PERIMETRE] };
    }
    dispatch(updatePopupContent(newPopupContent));
  };

  const sideBarContent = map.sidebarTab === '#geoEditor'
    ? <GeoEditorSideBarActions />
    : <Table />;

  return (
    <>
      <div>
        <SideBar
          title={getTitle()}
          toolBar={(
            <ToolsBar
              validateObjects={validateObjects}
              deleteObjects={() => {}}
              modalId={modalId}
            />
            )}
          content={sideBarContent}
          addTools={<ToolsBar addRows={addNewFields} />}
          onClose={onSideBarClose}
        />
      </div>
      <ModalSNCF htmlID={modalId}>
        <ModalHeaderSNCF>
          {modalHeader}
        </ModalHeaderSNCF>
        <ModalBodySNCF>
          {modalBody}
        </ModalBodySNCF>
        <ModalFooterSNCF>
          {modalFooter}
        </ModalFooterSNCF>
      </ModalSNCF>
    </>
  );
};

CreationContent.propTypes = {
  updateTiles: PropTypes.func.isRequired,
};

export default CreationContent;
