import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { MdRestore } from 'react-icons/md';
import { IoIosCopy } from 'react-icons/io';
import TableSNCF from 'common/BootstrapSNCF/TableSNCF';
import ModalSNCF from 'common/BootstrapSNCF/ModalSNCF/ModalSNCF';
import ModalHeaderSNCF from 'common/BootstrapSNCF/ModalSNCF/ModalHeaderSNCF';
import ModalBodySNCF from 'common/BootstrapSNCF/ModalSNCF/ModalBodySNCF';
import ModalFooterSNCF from 'common/BootstrapSNCF/ModalSNCF/ModalFooterSNCF';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as allLogsActions from 'reducers/logs';
import DatePickerSNCF, { PICKER_MODES } from 'common/BootstrapSNCF/DatePickerSNCF/DatePickerSNCF';
import InputSNCF from 'common/BootstrapSNCF/InputSNCF';
import copy from 'copy-to-clipboard';
import { ADMIN_PATH, MAP_PATH } from 'utils/router';
import { getFirstCoordinates } from 'utils/helpers';
import config from 'config/config';
import Pagination from 'common/BootstrapSNCF/PaginationSNCF';
import ErrorBoundary from 'common/ErrorBoundaries/ErrorBoundary';

export const REQUEST_LIMIT = 100;

class RawHistory extends Component {
  static propTypes = {
    logs: PropTypes.object.isRequired,
    logsActions: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    tableHeaders: PropTypes.array.isRequired,
    filters: PropTypes.object,
    modalId: PropTypes.string.isRequired,
    htmlId: PropTypes.string.isRequired,
    pathName: PropTypes.string,
  }

  static defaultProps = {
    pathName: '',
    filters: <></>,

  }

  constructor(props) {
    super(props);
    this.state = {
      modalHeader: '',
      modalBody: '',
      modalFooter: '',
      activePage: 1,
      firstPage: 0,
    };
  }

  componentDidMount = async () => {
    const { logsActions, pathName } = this.props;
    try {
      await logsActions.getHistory(pathName !== ADMIN_PATH);
      await logsActions.updateSearch(pathName !== ADMIN_PATH, 1, '');
    } catch (e) {
      console.log(e);
    }
  }

  onRestore = async (layerName, id) => {
    const {
      t, logsActions, pathName, logs,
    } = this.props;
    const { activePage } = this.state;
    const params = {
      action_type: 'R',
      layer_name: layerName,
      modification_id: id,
    };
    console.log(params);

    const res = await logsActions.restoreObject(pathName !== ADMIN_PATH, params);

    if (res === undefined) {
      const modalHeader = (
        <strong>{t('Logs.popupDetails.restoreNotice')}</strong>
      );
      const modalBody = (<></>);
      const modalFooter = (
        <div className="d-flex align-items-end justify-content-start" style={{ width: '420px' }}>
          <div className="btn-group dropdown">
            <button
              type="button"
              className="btn btn-sm btn-primary"
              data-dismiss="modal"
              onClick={() => logsActions.getHistory(pathName !== ADMIN_PATH, { page: activePage, search: logs.search })}
            >
              <span>Ok</span>
            </button>
          </div>
        </div>
      );

      this.setState({
        modalHeader,
        modalBody,
        modalFooter,
      });
    }
  }

  onRollBack = (id, layerName) => {
    const { t } = this.props;
    const modalHeader = (
      <div>
        <strong>{t('Logs.popupDetails.confirmationNotice')}</strong>
      </div>
    );
    const modalBody = (<></>);
    const modalFooter = (
      <ErrorBoundary>
        <div className="d-flex align-items-end justify-content-end" style={{ width: '420px' }}>
          <div className="btn-group dropdown">
            <button type="button" className="btn btn-sm btn-secondary mr-2" data-dismiss="modal">
              <span>{t('Logs.popupDetails.cancel')}</span>
            </button>
          </div>
          <div className="btn-group dropdown">
            <button
              type="button"
              className="btn btn-sm btn-primary"
              onClick={() => this.onRestore(layerName, id)}
            >
              <span>{t('Logs.popupDetails.confirmButton')}</span>
            </button>
          </div>
        </div>
      </ErrorBoundary>
    );

    this.setState({ modalHeader, modalBody, modalFooter });
  }

  getItemLink = (id) => {
    const { logs: { items } } = this.props;
    const item = items.find((i) => i.id === id);
    const { new_gaia_object: object } = item;
    if (object && object.geomSch) {
      const geom = object.geomSch;
      const [longitude, latitude, zoom] = getFirstCoordinates(geom);

      // Building URL using substrings, TODO: update env variables with the right url
      const baseUrl = config.proxy.replace('gateway', 'midi');

      const url = `${baseUrl}${MAP_PATH}/${latitude}/${longitude}/${zoom}`;
      copy(url);
    }
  }

  getActions = (id, status, layerName, actionType, hasBeenRestored) => {
    const { t, modalId } = this.props;

    return (
      <div className="cell-inner">
        <button
          className="btn btn-only-icon btn-transparent btn-color-gray btn-sm mr-2"
          type="button"
          title={t('Logs.popupDetails.confirmButton')}
          data-target={`#${modalId}`}
          data-toggle="modal"
          disabled={hasBeenRestored}
          onClick={() => this.onRollBack(id, layerName)}
        >
          <MdRestore size="25" />
        </button>
        <button
          className="btn btn-only-icon btn-transparent btn-color-gray btn-sm"
          type="button"
          title="Copier le lien vers l'objet"
          onClick={() => this.getItemLink(id)}
          disabled={actionType !== 'M'}
        >
          <IoIosCopy size="20" />
        </button>
      </div>
    );
  }

  search = (value) => {
    const { logsActions, pathName } = this.props;
    logsActions.updateSearch(pathName !== ADMIN_PATH, 1, value);
    this.setState({ activePage: 1, firstPage: 0 });
  }

  getPageContent = (e, pages, paginationRange) => {
    const { logsActions, pathName, logs } = this.props;
    const { activePage, firstPage } = this.state;
    const index = e.currentTarget.getAttribute('href').split('#')[1];
    const limitPages = paginationRange < pages ? paginationRange : pages;

    switch (index) {
      case 'first':
        logsActions.getHistory(pathName !== ADMIN_PATH, { page: 1, search: logs.search });
        this.setState({ activePage: 1, firstPage: 0 });
        break;
      case 'last':
        logsActions.getHistory(pathName !== ADMIN_PATH, { page: pages, search: logs.search });
        this.setState({ activePage: pages, firstPage: pages - limitPages });
        break;
      case 'previous':
        logsActions.getHistory(pathName !== ADMIN_PATH, { page: activePage - 1, search: logs.search });
        this.setState({ activePage: activePage - 1 });
        if (firstPage > 0) {
          this.setState({ firstPage: firstPage - 1 });
        }
        break;
      case 'next':
        logsActions.getHistory(pathName !== ADMIN_PATH, { page: activePage + 1, search: logs.search });
        if (activePage < pages) {
          this.setState({ activePage: activePage + 1 });
        }
        /* In order to set the new value of the first page to displayon the navigation menu,
         we need tocheck wether:
            -the next page to display reaches the displayed page limit
            -the next page to dispaly is less than the total pages number
            -the pages limit is different that the total pages number */
        if (activePage + 1 === limitPages + firstPage && activePage + 1 < pages && limitPages !== pages) {
          this.setState({ firstPage: firstPage + 1 });
        }
        break;
      default:
        logsActions.getHistory(pathName !== ADMIN_PATH, { page: index, search: logs.search });
        this.setState({ activePage: Number(index) });
        /* In order to set the new value of the first page to displayon the navigation menu,
         we need tocheck wether the selected page number to display:
            -reaches the displayed page limit
            -is greater that the actual displayed page
            -is less that the total pages number */
        if (Number(index) === limitPages + firstPage && Number(index) > activePage && Number(index) < pages) {
          this.setState({ firstPage: firstPage + 1 });
        }
        if (firstPage > 0 && Number(index) < activePage) {
          this.setState({ firstPage: firstPage - 1 });
        }
        if (Number(index) > 3 && Number(index) > activePage) {
          this.setState({ firstPage: firstPage + 1 });
        }
    }
  }

  render() {
    const {
      t, logs, tableHeaders, filters, modalId, htmlId, logsActions, profile,
    } = this.props;
    const {
      modalHeader, modalBody, modalFooter, activePage, firstPage,
    } = this.state;
    const {
      dateRange, search, filteredItems, pages,
    } = logs;

    const searchAppendOptions = {
      iconName: 'icons-search',
      onClick: () => {},
      name: 'log-search',
    };

    return (
      <>
        <div className="row my-3">
          <div className="col-4 mr-5">
            <InputSNCF
              label={t('Logs.filters.search')}
              htmlId={`${htmlId}-search`}
              value={search}
              appendOptions={searchAppendOptions}
              onChange={(e) => this.search(e.target.value)}
              onClear={() => this.search('')}
              placeholder={t('Logs.filters.searchPlaceholder')}
              noMargin
            />
          </div>
          {filters}

          <div className="col-4 mr-3">
            <DatePickerSNCF
              htmlId={`${htmlId}-range`}
              mode={PICKER_MODES.range}
              date={dateRange}
              onChange={(date) => logsActions.updateDateRange(date)}
              onClear={logsActions.updateDateRange}
              placeholder={t('Logs.filters.datePickerPlaceholder')}
            />
          </div>
        </div>
        <div className="row" style={{ overflow: 'auto', maxHeight: '59vh' }}>
          <TableSNCF
            heads={tableHeaders}
            actions={this.getActions}
            content={filteredItems}
            itemsList={profile.users}
          />
        </div>
        <Pagination pages={pages} activePage={activePage} firstPage={firstPage} getPageContent={this.getPageContent} />
        <ModalSNCF htmlID={modalId}>
          <ModalHeaderSNCF>
            {modalHeader}
          </ModalHeaderSNCF>
          <ModalBodySNCF>
            {modalBody}
          </ModalBodySNCF>
          <ModalFooterSNCF>
            {modalFooter}
          </ModalFooterSNCF>
        </ModalSNCF>
      </>
    );
  }
}

const History = withTranslation()(RawHistory);

const mapStateToProps = (state) => ({
  logs: state.logs,
  profile: state.profile,
});

const mapDispatchToProps = (dispatch) => ({
  logsActions: bindActionCreators(allLogsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(History);
