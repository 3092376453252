import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import nextId from 'react-id-generator';
import { updateSplitPk } from 'reducers/map';
import { FIELDS_LABEL } from 'common/Map/const';

export default function SplitSideBar() {
  const dispatch = useDispatch();
  const map = useSelector((state) => state.map);

  return (
    <table className="table table-hover mt-2 mb-0 w-100 detailed-table" style={{ fontSize: '.9rem' }}>
      <tbody>
        <tr>
          <td>
            <div
              className="cell-inner small-cell-sidebar text-monospace font-weight-bold"
            >
              {FIELDS_LABEL.pk_sncf}
            </div>
          </td>
          <td style={{ width: '10rem' }}>
            <div>
              <div className="cell-inner small-cell-sidebar">
                <input
                  type="text"
                  onChange={(e) => dispatch(updateSplitPk(e.target.value))}
                  className="text-monospace form-control form-control-sm"
                  id={nextId()}
                  value={map.splitPk}
                />
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
}
