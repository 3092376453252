import React from 'react';
import PropTypes from 'prop-types';
import kmORm from 'common/distances';

export function PopupInfosCustomTitle(props) {
  const {
    nomVoie, codeLigne, longueur, pkSncfDe, pkSncfFi, libelle, pk,
  } = props;
  const distance = kmORm(longueur);
  let title = '';
  if (libelle.length !== 0) {
    title = libelle;
  } else if (codeLigne.length !== 0) {
    title = `${codeLigne} ${nomVoie}`;
  }
  return (
    <>
      <strong className="mr-2">{title}</strong>
      {distance && (
        <small className="mr-2">
          {distance.value}
          <small className="text-uppercase">{distance.unit}</small>
        </small>
      )}
      <small>
        {pkSncfDe !== ''
        && (
          <>
            {pkSncfDe}
            <strong> / </strong>
            {pkSncfFi}
          </>
        )}
        {pk !== '' && (pk)}
      </small>
    </>
  );
}
PopupInfosCustomTitle.propTypes = {
  nomVoie: PropTypes.string,
  codeLigne: PropTypes.string,
  longueur: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  pkSncfDe: PropTypes.string,
  pkSncfFi: PropTypes.string,
  libelle: PropTypes.string,
  pk: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};
PopupInfosCustomTitle.defaultProps = {
  nomVoie: '',
  codeLigne: '',
  longueur: '',
  pkSncfDe: '',
  pkSncfFi: '',
  libelle: '',
  pk: '',
};

export function PopupInfosCustomContent(props) {
  const {
    codeTiv, idGaia, id,
  } = props;
  return (
    <>
      {codeTiv && (
        <div className="labelvalue">
          <span className="labelvalue-label">CODE TIV</span>
          {codeTiv}
        </div>
      )}
      {idGaia && (
        <div className="labelvalue">
          <span className="labelvalue-label">ID GAIA</span>
          {idGaia}
        </div>
      )}
      {(idGaia === undefined && id) && (
        <div className="labelvalue">
          <span className="labelvalue-label">ID</span>
          {id}
        </div>
      )}
    </>
  );
}
PopupInfosCustomContent.propTypes = {
  codeTiv: PropTypes.string,
  idGaia: PropTypes.string,
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};
PopupInfosCustomContent.defaultProps = {
  codeTiv: undefined,
  idGaia: undefined,
  id: undefined,
};
