import { store } from 'Store';
import nearestPointOnLine from '@turf/nearest-point-on-line';
import transformRotate from '@turf/transform-rotate';
import bearing from '@turf/bearing';
import { point } from '@turf/helpers';
import { ExtendLineStringMode } from '@nebula.gl/edit-modes';

import { MIDI_OBJECTS_KEYS } from 'common/Map/Consts/MidiObjects';
import { getRotateAngle } from 'components/GeoEditor/helpers';
import {
  updateSelectedData, updateMode, toggleDrawAtFront,
  updateSelectedFeatureIndex, updateSelectedEditHandleIndex,
} from 'reducers/geoEditor';
import { MODES } from 'components/GeoEditor/const';

const angleControlOnTiv = false;
const baseAngle = 15;

const findModifiedIndex = (newGeometry, nbCoords) => {
  const { geoEditor } = store.getState();
  const { selectedData, selectedFeatureIndex } = geoEditor;

  let i = 0;
  let modifiedPointIndex;
  while (i < nbCoords && modifiedPointIndex === undefined) {
    const newStrPoint = JSON.stringify(newGeometry.coordinates[i]);
    const oldStrPoint = JSON.stringify(selectedData[selectedFeatureIndex].geometry.coordinates[i]);
    if (newStrPoint !== oldStrPoint) {
      modifiedPointIndex = i;
      break;
    }
    i += 1;
  }
  return modifiedPointIndex;
};

const findSnappedPoint = (mouseCoords) => {
  const mousePoint = point(mouseCoords);
  const { geoEditor } = store.getState();
  const {
    infraEditor, tivPoints, dataPoints, snappedGeometry,
  } = geoEditor;

  if (snappedGeometry.geometry.type === 'Point') {
    const pointsToSnap = infraEditor ? tivPoints : dataPoints;
    return pointsToSnap.find((p) => p.properties.OP_id === snappedGeometry.properties.OP_id
      && p.properties.pointIndex === snappedGeometry.properties.pointIndex
      && p.properties?.multiLineStringIndex === snappedGeometry.properties?.multiLineStringIndex);
  }
  return nearestPointOnLine(
    snappedGeometry,
    mousePoint,
  );
};

const getRotatedPoint = (newGeometry) => {
  const { geoEditor } = store.getState();
  const { selectedData, selectedFeatureIndex, data } = geoEditor;

  const firstLineBearing = bearing(newGeometry.coordinates[0], newGeometry.coordinates[1]);
  let refCoords;
  if (selectedData[selectedFeatureIndex].geometry.coordinates[1] !== undefined
      && selectedData[selectedFeatureIndex].geometry.coordinates[2] !== undefined) {
    const [, point1, point2] = selectedData[selectedFeatureIndex].geometry.coordinates;
    refCoords = [point1, point2];
  } else if (selectedData[selectedFeatureIndex].geometry.coordinates[1] !== undefined) {
    const formerData = data.find((p) => (
      p.properties.OP_id === selectedData[selectedFeatureIndex].properties.OP_id
      && p.properties?.multiLineStringIndex === selectedData[selectedFeatureIndex]
        .properties?.multiLineStringIndex
    ));
    if (formerData !== undefined) {
      const [point1, point2] = formerData.geometry.coordinates;
      refCoords = [point1, point2];
    }
  }

  const rotateAngle = getRotateAngle(firstLineBearing, baseAngle, refCoords);
  return transformRotate(point(newGeometry.coordinates[0]), rotateAngle, {
    pivot: newGeometry.coordinates[1],
  });
};

export const createNewLineString = (params) => {
  const { data, editContext } = params;

  const featureIndex = editContext?.featureIndexes[0] || 0;
  const newLineBasicProps = data[featureIndex];
  const newElement = {
    geometry: newLineBasicProps.geometry,
    ...newLineBasicProps.properties,
  };
  return {
    ...newElement,
    id: featureIndex,
    properties: {
      renderType: 'LineString',
    },
  };
};

export const modifyLine = (params, mouseCoords) => {
  const { geoEditor, map } = store.getState();
  const { selectedData, selectedFeatureIndex, snappedGeometry } = geoEditor;
  const { data, editType } = params;

  const modifiedData = data[selectedFeatureIndex];
  const newGeometry = modifiedData.geometry;
  const shouldSnap = mouseCoords && editType !== 'addPosition' && snappedGeometry;
  const nbCoords = selectedData[selectedFeatureIndex].geometry.coordinates.length;

  const modifiedPointIndex = findModifiedIndex(newGeometry, nbCoords);

  // snapping function
  if (shouldSnap) {
    const snappedPoint = findSnappedPoint(mouseCoords);
    if (modifiedPointIndex !== undefined && snappedPoint !== undefined) {
      newGeometry.coordinates[modifiedPointIndex] = snappedPoint.geometry.coordinates;
    }
  } else if (
    map.selectedObjectLayer.key === MIDI_OBJECTS_KEYS.tivSch
    && modifiedPointIndex !== undefined
    // default to False for the moment
    && angleControlOnTiv
  ) {
    if (modifiedPointIndex === 0) {
      const rotatedPoint = getRotatedPoint(newGeometry);

      if (rotatedPoint !== undefined) {
        newGeometry.coordinates[0] = rotatedPoint.geometry.coordinates;
      }
    } else if (modifiedPointIndex === nbCoords - 1) {
      // TODO: Finish dealing with angle constraint
      // const lastLineBearing = bearing(newGeometry.coordinates[nbCoords - 1],
      // newGeometry.coordinates[nbCoords - 2]);
    }
  }
  const newEl = {
    ...modifiedData,
    geometry: newGeometry,
    properties: {
      renderType: newGeometry.type,
      ...modifiedData.properties,
    },
  };
  return selectedData.map(
    (el, index) => (index === modifiedData.id ? newEl : el),
  );
};

export const extendLine = (params) => {
  const { geoEditor } = store.getState();
  const { editContext } = params;
  const {
    selectedData, selectedFeatureIndex, selectedEditHandleIndex, drawAtFront,
  } = geoEditor;

  const newCoordinates = [...selectedData[selectedFeatureIndex].geometry.coordinates];
  if (drawAtFront) {
    newCoordinates.splice(selectedEditHandleIndex, 0, editContext.position);
  } else {
    newCoordinates.push(editContext.position);
  }
  const elIndex = editContext.featureIndexes[0];
  const updatedFeature = {
    ...selectedData[editContext.featureIndexes[0]],
    id: elIndex,
    geometry: {
      ...selectedData[editContext.featureIndexes[0]].geometry,
      coordinates: newCoordinates,
    },
  };
  return selectedData.map(
    (el, index) => (index === elIndex ? updatedFeature : el),
  );
};

export const deleteSelectedPoint = () => {
  const { geoEditor } = store.getState();
  const { selectedData, selectedFeatureIndex, selectedEditHandleIndex } = geoEditor;

  const selectedDataEl = selectedData !== undefined ? selectedData[selectedFeatureIndex] : null;
  if (selectedEditHandleIndex !== null) {
    const modifiedSelectedData = {
      ...selectedDataEl,
      geometry: {
        ...selectedDataEl.geometry,
        coordinates: selectedDataEl.geometry.coordinates
          .filter((coord, index) => index !== selectedEditHandleIndex),
      },
      id: selectedFeatureIndex,
    };
    const newSelectedData = [...selectedData];
    newSelectedData.splice(selectedFeatureIndex, 1, modifiedSelectedData);
    store.dispatch(updateSelectedData(newSelectedData));
  }
  return null;
};

export const toggleExtendLineMode = () => {
  const {
    geoEditor: {
      mode, shouldDisplay, selectedData, selectedEditHandleIndex, selectedFeatureIndex,
    },
  } = store.getState();

  if (mode instanceof ExtendLineStringMode) {
    if (selectedData && selectedData[selectedData.length - 1]?.geometry?.coordinates.length < 2) {
      const newSelectedData = [...selectedData].slice(0, -1);
      store.dispatch(updateSelectedData(newSelectedData));
      store.dispatch(updateSelectedFeatureIndex(newSelectedData.length - 1));
    }
    store.dispatch(updateMode(MODES.edit));
    return;
  }
  const enableExtendLineMode = shouldDisplay
    && selectedData !== undefined
    && selectedEditHandleIndex !== null

  if (enableExtendLineMode) {
    store.dispatch(toggleDrawAtFront(selectedEditHandleIndex === 0));
    store.dispatch(updateMode(MODES.extendLine));
    if (shouldDisplay && selectedData !== undefined && selectedEditHandleIndex !== null) {
      const firstPointCoordinates = selectedData[selectedFeatureIndex]
        .geometry.coordinates[selectedEditHandleIndex];
      const newLineString = {
        id: selectedData.length,
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [firstPointCoordinates],
        },
        properties: {
          multiLineStringIndex: selectedData.length,
          renderType: 'LineString',
          ...selectedData[0].properties,
        },
      };
      let selectedDataBase = [...selectedData];
      if (selectedData?.length === 1) {
        selectedDataBase = [{
          ...selectedData[0],
          properties: {
            multiLineStringIndex: 0,
            ...selectedData[0].properties,
          },
          geometry: selectedData[0].geometry,
        }];
      }
      const newSelectedData = [...selectedDataBase, newLineString];

      store.dispatch(updateSelectedData(newSelectedData));
      store.dispatch(updateSelectedFeatureIndex(newSelectedData.length - 1));
      store.dispatch(updateSelectedEditHandleIndex(0));
      store.dispatch(toggleDrawAtFront(false));
      store.dispatch(updateMode(MODES.extendLine));
    }
  }
};
