import Admin from 'views/Admin';
import Logs from 'views/Logs';
import Map from 'views/Map/Map';

export const MAP_PATH = '/map';
export const LOGS_PATH = '/logs';
export const LIBRARY_PATH = '/library';
export const ADMIN_PATH = '/administration';
export const STRETCHING_PATH = '/stretching';

export const MIDI_ACCESS = 'midi::ACCESS';
export const MIDI_ADMIN = 'midi::ADMIN';

export const ROUTES = [
  {
    component: Map,
    path: MAP_PATH,
    exact: true,
    access: MIDI_ACCESS,
  },
  {
    component: Map,
    path: `${MAP_PATH}/:lat/:lon/:zoom/:bearing/:pitch`,
    access: MIDI_ACCESS,
  },
  {
    component: Logs,
    path: LOGS_PATH,
    access: MIDI_ACCESS,
  },
  {
    component: Admin,
    path: ADMIN_PATH,
    access: MIDI_ADMIN,
  },
];
