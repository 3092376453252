import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Assets and Styles
import { GoPlus } from 'react-icons/go';
import { MAP_MODES, midiObjects, NEW_OBJECT_REF } from 'common/Map/const';
import SelectSNCF from 'common/BootstrapSNCF/SelectSNCF';
import { MIDI_OBJECTS_KEYS } from 'common/Map/Consts/MidiObjects';
import {
  updateMode, updateObjectToCreate, updatePopupContent, updateSelectedObjectToCreate,
} from 'reducers/map';
import CreationContent from './CreationContent';

const midiObjectsList = midiObjects.filter((obj) => !obj.hide);
const selectOptions = [
  {
    id: 'default',
    name: 'Choisir un objet',
  },
  ...midiObjectsList.map((obj) => ({
    id: obj.key,
    name: obj.singular,
  })),
];

const CreationTools = ({ updateTiles }) => {
  const dispatch = useDispatch();
  const map = useSelector((state) => state.map);
  const main = useSelector((state) => state.main);
  const { t } = useTranslation();
  const [creationSideBar, setCreationSideBar] = useState(false);

  const onSideBarClose = () => {
    setCreationSideBar(!creationSideBar);
  };

  const getSideBarContent = (selectedValue) => {
    let contentFields = {};
    let selectedLayer = {};
    // eslint-disable-next-line default-case
    switch (selectedValue.id) {
      case MIDI_OBJECTS_KEYS.signal:
        contentFields = {
          pk_sncf: '',
          L_code: '',
          V_nom: '',
          LP_sensLecture: '',
          RA_libelle: '',
          TIF_mnemo: '',
          OP_id_tronconditinerairevoie: '',
          consigne: '',
        };
        selectedLayer = midiObjects.find((obj) => obj.key === MIDI_OBJECTS_KEYS.signal);
        break;
      case MIDI_OBJECTS_KEYS.jdz:
        contentFields = {
          pk_sncf: '',
          L_code: '',
          V_nom: '',
          OP_id_tronconditinerairevoie: '',
          consigne: '',
        };
        selectedLayer = midiObjects.find((obj) => obj.key === MIDI_OBJECTS_KEYS.jdz);
        break;
      case MIDI_OBJECTS_KEYS.cdv:
        contentFields = {
          RA_libelle: '',
          consigne: '',
          extremites: [
            {
              L_code: '',
              V_nom: '',
              pk_sncf: '',
              OP_id_tronconditinerairevoie: '',
              OP_id: '',
              RLJDZ_sens: '',
            },
          ],
        };
        selectedLayer = midiObjects.find((obj) => obj.key === MIDI_OBJECTS_KEYS.cdv);
        break;
      case MIDI_OBJECTS_KEYS.adv:
        contentFields = {
          RA_libelle: '',
          TADV_mnemonique: '',
          consigne: '',
          is_neutralise: '',
          branches: [
            {
              L_code: '',
              V_nom: '',
              pk_sncf: '',
              OP_id_tronconditinerairevoie: '',
              BAPV_direction: '',
              BAPV_codeBranche: '',
              BAPV_pointObservation: '',
            },
          ],
        };
        selectedLayer = midiObjects.find((obj) => obj.key === MIDI_OBJECTS_KEYS.adv);
        break;
      case MIDI_OBJECTS_KEYS.itineraire:
        contentFields = {
          RA_libelle: '',
          RA_libelle_signal_origine: '',
          OP_id_canton_origine: '',
          RA_libelle_signal_destination: '',
          OP_id_canton_destination: '',
          pk_sncf_signalOrigine: '',
          pk_sncf_signalDestination: '',
          L_code_signalOrigine: '',
          L_code_signalDestination: '',
          V_nom_signalOrigine: '',
          V_nom_signalDestination: '',
          OP_id_localisationpk_signalOrigine: '',
          OP_id_localisationpk_signalDestination: '',
          LP_sensLecture_signalOrigine: '',
          LP_sensLecture_signalDestination: '',
          OP_id_tronconditinerairevoie_signalOrigine: '',
          OP_id_tronconditinerairevoie_signalDestination: '',
          RA_libelle_macaron_origine: '',
          RA_libelle_macaron_destination: '',
          jalon: '',
          consigne: '',
          adv_positionnes: [],
          itineraires_impactes: [NEW_OBJECT_REF],
        };
        selectedLayer = midiObjects.find((obj) => obj.key === MIDI_OBJECTS_KEYS.itineraire);
        break;
      case MIDI_OBJECTS_KEYS.zep:
        contentFields = {
          RA_libelle: '',
          TIF_mnemo: '',
          is_utilisable_isolement: '',
          Z_trainTravauxInterdits: '',
          perimetres_responsables: [],
          perimetres_associes: [],
          consigne: '',
          extremites: [
            {
              L_code: '',
              V_nom: '',
              pk_sncf: '',
              OP_id_tronconditinerairevoie: '',
              OP_id: '',
              RLE_sens: '',
            },
          ],
          cles_protections: [],
          itineraires_impactes: [NEW_OBJECT_REF],
        };
        selectedLayer = midiObjects.find((obj) => obj.key === MIDI_OBJECTS_KEYS.zep);
        break;
      case MIDI_OBJECTS_KEYS.groupementZep:
        contentFields = {
          RA_libelle: '',
          perimetres_responsables: [],
          perimetres_associes: [],
          consigne: '',
          zeps: [
            {
              RA_libelle: '',
              OP_id: '',
            },
          ],
          GDZEP_trainTravauxInterdits: '',
          cles_protections: [],
          itineraires_impactes: [NEW_OBJECT_REF],
        };
        selectedLayer = midiObjects.find((obj) => obj.key === MIDI_OBJECTS_KEYS.groupementZep);
        break;
      case MIDI_OBJECTS_KEYS.sel:
        contentFields = {
          RA_libelle: '',
          RA_libelle_centralsoustation: '',
          perimetres_responsables: [],
          perimetres_associes: [],
          consigne: '',
          is_utilisable_isolement: '',
          extremites: [
            {
              L_code: '',
              V_nom: '',
              pk_sncf: '',
              L_ordre: '',
              OP_id_tronconditinerairevoie: '',
              OP_id: '',
            },
          ],
          cles_protections: [],
          itineraires_impactes: [NEW_OBJECT_REF],
        };
        selectedLayer = midiObjects.find((obj) => obj.key === MIDI_OBJECTS_KEYS.sel);
        break;
      case MIDI_OBJECTS_KEYS.groupementSel:
        contentFields = {
          RA_libelle: '',
          RA_libelle_centralsoustation: '',
          perimetres_responsables: [],
          perimetres_associes: [],
          consigne: '',
          sels: [NEW_OBJECT_REF],
          groupements_catenaires: [NEW_OBJECT_REF],
          cles_protections: [],
          itineraires_impactes: [NEW_OBJECT_REF],
        };
        selectedLayer = midiObjects.find((obj) => obj.key === MIDI_OBJECTS_KEYS.groupementSel);
        break;
      case MIDI_OBJECTS_KEYS.poste:
        contentFields = {
          RA_libelle: '',
          OP_id: '',
          consigne: '',
          object: '',
        };
        selectedLayer = midiObjects.find((obj) => obj.key === MIDI_OBJECTS_KEYS.poste);
        break;
    }
    dispatch(updateObjectToCreate(contentFields));
    dispatch(updatePopupContent(contentFields, selectedLayer));
  };

  const onSelect = (e) => {
    dispatch(updateSelectedObjectToCreate(JSON.parse(e.currentTarget.value)));
    dispatch(updateMode(MAP_MODES.creation));
    getSideBarContent(JSON.parse(e.currentTarget.value));
    setCreationSideBar(false);
  };

  const selectObjectToCreate = () => {
    if (creationSideBar) {
      return (
        <nav className={`sideBar-container${main.fullscreen ? ' fullscreen' : ''}`}>
          <div className="d-flex flex-column">
            <div className="d-flex justify-content-end">
              <button className="btn btn-sm btn-transparent" type="button" onClick={onSideBarClose} style={{ color: 'black' }}>
                <i className="icons-close icons-size-x75" aria-hidden="true" />
              </button>
            </div>
            <div className=" mx-4 mt-2">
              <SelectSNCF
                title={t('Map.popupDetails.createModal.selectValue')}
                options={selectOptions}
                selectedValue={selectOptions[0]}
                onChange={onSelect}
                selectStyle="cell-inner"
              />
            </div>
          </div>
        </nav>
      );
    }
    return null;
  };

  return (
    <>
      <div className={`creation-sidebar${main.fullscreen ? ' fullscreen' : ''}`}>
        <button
          type="button"
          className="btn btn-primary mt-2"
          title={t('Map.addObjectButton')}
          onClick={() => setCreationSideBar(!creationSideBar)}
        >
          <GoPlus
            size="1.5em"
          />
        </button>
      </div>
      {selectObjectToCreate()}
      {map.selectedObjectToCreate !== undefined
        && (
          <CreationContent
            updateTiles={updateTiles}
          />
        )}
    </>
  );
};

CreationTools.propTypes = {
  updateTiles: PropTypes.func,
};

CreationTools.defaultProps = {
  updateTiles: () => {},
};

export default CreationTools;
