import React, { Suspense, useEffect } from 'react';
import { Router } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Loader from 'common/Loader';
import Home from 'views/Home';

import { attemptLoginOnLaunch } from 'reducers/user';
import history from 'appHistory';

function App() {
  const dispatch = useDispatch();
  const { isLogged } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(attemptLoginOnLaunch());
  }, []);

  return (
    <Suspense fallback={<Loader />}>
      {isLogged && (
        <Router history={history}>
          <Home />
        </Router>
      )}
      {!isLogged && (
        <Loader />
      )}
    </Suspense>
  );
}

export default App;
