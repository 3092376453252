export const QUALITY_STATUS = {
  0: {
    name: 'VALIDATING',
    label: 'Logs.zones.validating',
  },
  1: {
    name: 'VALIDATED',
    label: 'Logs.zones.validated',
  },
  2: {
    name: 'VERIFYING',
    label: 'Logs.zones.verifying',
  },
  3: {
    name: 'VERIFIED',
    label: 'Logs.zones.verified',
  },
  4: {
    name: 'UNASSIGNED',
    label: 'Logs.zones.unassigned',
  },
};
