import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Editor, EditingMode, DrawLineStringMode } from 'react-map-gl-draw';
import { ExtendLineStringMode } from '@nebula.gl/edit-modes';

import SnappingLinesLayer from 'components/GeoEditor/SnappingLinesLayer';
import SnappingPointsLayer from 'components/GeoEditor/SnappingPointsLayer';
import JointsDeZones from 'common/Map/Layers/JointsDeZones';
import SignalsSCH from 'common/Map/Layers/SignalsSCH';
import { MAP_MODES, MAP_URL, QUALITY_SIGNALS } from 'common/Map/const';
import { MIDI_OBJECTS_KEYS, MIDI_OBJECTS_LAYERS } from 'common/Map/Consts/MidiObjects';
import config from 'components/GeoEditor/config';
import TIV from 'components/GeoEditor/TIV';

import {
  updateSelectedData, updateSelectedEditHandleIndex, updateSelectedFeatureIndex,
  updateMode as updateEditorMode,
} from 'reducers/geoEditor';
import {
  createNewLineString, deleteSelectedPoint, extendLine, modifyLine, toggleExtendLineMode,
} from 'components/GeoEditor/helpers/core';
import {
  DELETE_KEYS, EXTEND_LINE_KEYS, linearObjectPaint, MODES, tivPaint,
} from 'components/GeoEditor/const';
import ModificationLayer from 'components/GeoEditor/ModificationLayer';

const { width, height } = config;

function GeoEditor({
  editorRef, featureInfoClick, onSideBarClose,
}) {
  const dispatch = useDispatch();
  const { mode, sidebarTab, selectedObjectLayer } = useSelector((state) => state.map);
  const {
    jdz, signals, mode: editorMode, infraEditor, tiv, shouldDisplay, drawAtFront,
    selectedData, data, dataPoints, selectedFeatureIndex, selectedEditHandleIndex,
    mouseCoords,
  } = useSelector((state) => state.geoEditor);

  const onSelect = (e) => {
    if (editorMode instanceof EditingMode) {
      console.log('e', e.selectedEditHandleIndex, e.selectedFeatureIndex);
      dispatch(updateSelectedEditHandleIndex(e.selectedEditHandleIndex));
      dispatch(updateSelectedFeatureIndex(e.selectedFeatureIndex));
      if (e.selectedFeature === null && mode !== MAP_MODES.creation) {
        dispatch(updateSelectedData());
        dispatch(updateSelectedFeatureIndex(0));
        onSideBarClose();
      }
    }
  };

  const onUpdate = async (update) => {
    const { editType, editContext } = update;
    if (editorMode instanceof DrawLineStringMode && editType === 'addFeature') {
      const newLine = createNewLineString(update);

      dispatch(updateSelectedData([newLine]));
      dispatch(updateSelectedFeatureIndex(0));
      dispatch(updateEditorMode(MODES.edit));
    } else if (editorMode instanceof EditingMode) {
      const newSelectedData = modifyLine(update, mouseCoords);
      dispatch(updateSelectedData(newSelectedData));
    } else if (editorMode instanceof ExtendLineStringMode
               && editContext
               && selectedEditHandleIndex !== null) {
      const newSelectedData = extendLine(update);
      dispatch(updateSelectedData(newSelectedData));
    }
  };

  const handleUserKeyDownPress = useCallback((event) => {
    const { keyCode } = event;

    // Disable extend line for TIVs
    const enableExtendLine = selectedObjectLayer
                          && (selectedObjectLayer.key !== MIDI_OBJECTS_KEYS.tivSch);

    if (enableExtendLine && EXTEND_LINE_KEYS.includes(keyCode)) {
      toggleExtendLineMode();
    } else if (DELETE_KEYS.includes(keyCode)) {
      deleteSelectedPoint();
    }
  }, [editorMode, selectedEditHandleIndex, selectedData]);

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyDownPress);

    return () => {
      window.removeEventListener('keydown', handleUserKeyDownPress);
    };
  }, [handleUserKeyDownPress]);

  return (
    <>
      {shouldDisplay && (featureInfoClick !== undefined || mode === MAP_MODES.creation) && (
        <Editor
          ref={editorRef}
          style={{ width, height }}
          clickRadius={12}
          mode={editorMode}
          features={selectedData}
          onSelect={onSelect}
          modeConfig={{ drawAtFront }}
          onUpdate={onUpdate}
          editHandleShape="rect"
          selectable
          selectedFeatureIndex={selectedFeatureIndex}
        />
      )}

      {sidebarTab === '#geoEditor' && !infraEditor && (
        <>
          <SnappingLinesLayer data={tiv} />
          <SnappingPointsLayer data={dataPoints} />
          <TIV data={tiv} />
          {jdz !== undefined && (
            <JointsDeZones
              mapURL={MAP_URL}
              sourceLayer="sch"
              sourceTable={MIDI_OBJECTS_LAYERS.jdz}
              mapMode={mode}
              data={jdz}
            />
          )}
          {signals !== undefined && (
            <SignalsSCH
              mapURL={MAP_URL}
              sourceLayer="sch"
              sourceTable={MIDI_OBJECTS_LAYERS.signal}
              mapMode={mode}
              signalList={QUALITY_SIGNALS}
              data={signals}
            />
          )}
          <ModificationLayer data={data} paint={linearObjectPaint} />
        </>
      )}
      {sidebarTab === '#geoEditor' && infraEditor && (
        <>
          <SnappingLinesLayer data={data} />
          <SnappingPointsLayer data={dataPoints} />
          <ModificationLayer data={data} paint={tivPaint} />
        </>
      )}
    </>
  );
}

GeoEditor.propTypes = {
  editorRef: PropTypes.object.isRequired,
  onSideBarClose: PropTypes.func.isRequired,
  featureInfoClick: PropTypes.object,
};

GeoEditor.defaultProps = {
  featureInfoClick: undefined,
};

export default GeoEditor;
