import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

const hasRoles = (obj, access) => access.includes(obj);

function RouteProtected({
  component: Component, path, access, internal, extUrl, exact,
}) {
  const user = useSelector((state) => state.user);

  const hasAccess = hasRoles(access, user.appPermissions);

  if (hasAccess && internal) {
    return (
      <Route exact={exact} path={path}>
        <Component />
      </Route>
    );
  }
  if (!hasAccess) {
    return <Redirect to={{ pathname: '/forbidden' }} />;
  }
  if (hasAccess && !internal) {
    return <Route component={() => { window.location = extUrl; return null; }} />;
  }
}

RouteProtected.propTypes = {
  access: PropTypes.string.isRequired,
  component: PropTypes.any,
  path: PropTypes.any.isRequired,
  internal: PropTypes.bool,
  extUrl: PropTypes.string,
  exact: PropTypes.bool,
};

export default RouteProtected;
