import React from 'react';
import { useSelector } from 'react-redux';
import { Layer, Source } from 'react-map-gl';
import PropTypes from 'prop-types';
import geoEditorOptions from 'components/GeoEditor/config';

function SnappingLinesLayer({ data }) {
  return (
    <Source
      id="invisible-tiv-source"
      type="geojson"
      data={{
        type: 'FeatureCollection',
        features: data,
      }}
    >
      <Layer
        id="invisible-tiv"
        type="line"
        paint={{
          'line-color': 'hsla(0, 100%, 50%, 0.001)',
          'line-width': geoEditorOptions.tivSnappingDistance * 2,
        }}
      />
    </Source>
  );
}

SnappingLinesLayer.propTypes = {
  data: PropTypes.array.isRequired,
};

export default SnappingLinesLayer;
