import React from 'react';
import { Source, Layer } from 'react-map-gl';
import { useSelector } from 'react-redux';

import { MAP_URL } from 'common/Map/const';
import { trackNameLayer, lineNumberLayer } from 'common/Map/Layers/commonlayers.json';
import {
  schematicMainLayer, schematicServiceLayer, schematicTunnelLayer,
  arrowServiceLayer, arrowMainLayer,
} from 'common/Map/Layers/schematiclayers.json';
import { MIDI_OBJECTS_LAYERS } from 'common/Map/Consts/MidiObjects';
import { booleanToStringLayerVisibility } from 'utils/helpers';

import Selected from 'common/Map/Selected';
import Hover from 'common/Map/Hover';

function SchematicView() {
  const { layers, viewport: { zoom } } = useSelector((state) => state.map);

  const isVSCalibratedExpression = ['all',
    ['to-boolean', ['get', 'pk_sncf_debut']],
    ['to-boolean', ['get', 'pk_sncf_fin']],
    ['to-boolean', ['get', 'L_code']],
    ['to-boolean', ['get', 'V_nom']],
  ];

  return (
    <Source type="vector" url={`${MAP_URL}/chartisv1/layer/map_midi_tronconditinerairevoie/mvt/sch/`} source-layer={MIDI_OBJECTS_LAYERS.tivSch} id="tiv-tiles">
      <Layer
        {...schematicServiceLayer}
        paint={{
          'line-width': 1,
          'line-color': ['case',
            isVSCalibratedExpression, '#999',
            '#111',
          ],
        }}
        layout={{ visibility: booleanToStringLayerVisibility(layers.vs.visible) }}
        source-layer={MIDI_OBJECTS_LAYERS.tivSch}
      />
      <Layer
        {...schematicMainLayer}
        layout={{ visibility: booleanToStringLayerVisibility(layers.vp.visible) }}
        source-layer={MIDI_OBJECTS_LAYERS.tivSch}
      />
      <Layer
        {...schematicTunnelLayer}
        source-layer={MIDI_OBJECTS_LAYERS.tivSch}
      />
      <Layer
        {...{
          ...trackNameLayer,
          layout: {
            ...trackNameLayer.layout,
            'text-field': '{V_nom}',
            'text-size': 16,
            visibility: booleanToStringLayerVisibility(layers.vp.visible),
          },
        }}
        source-layer={MIDI_OBJECTS_LAYERS.tivSch}
        filter={['==', 'type_voie', 'VP']}
      />
      <Layer
        {...{
          ...trackNameLayer,
          layout: {
            ...trackNameLayer.layout,
            'text-field': '{V_nom}',
            'text-size': 10,
            visibility: booleanToStringLayerVisibility(layers.vs.visible),
          },
        }}
        source-layer={MIDI_OBJECTS_LAYERS.tivSch}
        filter={['!=', 'type_voie', 'VP']}
      />
      <Layer
        {...{
          ...arrowMainLayer,
          source: 'mapDataSourceId',
          layout: {
            ...arrowMainLayer.layout,
            visibility: zoom > 15 ? 'visible' : 'none',
          },
        }}
        source-layer={MIDI_OBJECTS_LAYERS.tivSch}
      />
      <Layer
        {...{
          ...arrowServiceLayer,
          source: 'mapDataSourceId',
          layout: {
            ...arrowServiceLayer.layout,
            visibility: zoom > 15 ? 'visible' : 'none',
          },
        }}
        source-layer={MIDI_OBJECTS_LAYERS.tivSch}
      />
      <Layer
        {...{
          ...lineNumberLayer,
          layout: {
            ...lineNumberLayer.layout,
            'text-field': '{L_code}',
            visibility: booleanToStringLayerVisibility(layers.vp.visible),
          },
        }}
        source-layer={MIDI_OBJECTS_LAYERS.tivSch}
      />
      <Selected filterField="OP_id" sourceLayer={MIDI_OBJECTS_LAYERS.tivSch} />
      <Hover filterField="OP_id" sourceLayer={MIDI_OBJECTS_LAYERS.tivSch} />
    </Source>
  );
}

export default SchematicView;
