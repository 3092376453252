import React, { useEffect } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import MastNavSNCF from 'common/BootstrapSNCF/MastNavSNCF';
import MastNavItemSNCF from 'common/BootstrapSNCF/MastNavItemSNCF';
import NavBarSNCF from 'common/BootstrapSNCF/NavBarSNCF';
import logo from 'assets/logo_osrd_seul_blanc.svg';
import RouteProtected from 'components/RouteProtected';
import {
  ADMIN_PATH, LOGS_PATH, MAP_PATH, LIBRARY_PATH, ROUTES, MIDI_ADMIN, MIDI_ACCESS,
} from 'utils/router';
import { getUserAppPermissions } from 'reducers/user';
import { updateSelectedZone } from 'reducers/map';

function Home() {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);

  const isAdmin = user.appPermissions.includes(MIDI_ADMIN);
  const hasAccess = user.appPermissions.includes(MIDI_ACCESS);

  useEffect(() => {
    document.title = 'DGEX Solutions - MIDI';
    getUserAppPermissions();
  }, []);

  return (
    <>
      {hasAccess && (
        <>
          <MastNavSNCF
            items={(
              <>
                <MastNavItemSNCF link={MAP_PATH} linkname={t('nav.map')} icon="icons-localisation-pin" customAction={updateSelectedZone} />
                <MastNavItemSNCF link={LOGS_PATH} linkname={t('nav.logs')} icon="icons-quiet-area" />
                {isAdmin && <MastNavItemSNCF link={ADMIN_PATH} linkname={t('nav.administration')} icon="icons-admin" />}
              </>
              )}
          />
          <NavBarSNCF appName="MIDI" logo={logo} />
        </>
      )}
      <Switch>
        {/* Temporary solution for a 403 error */}
        <Route path="/forbidden">
          <pre>
            Accès refusé. Contactez votre administrateur.
          </pre>
        </Route>
        {ROUTES.map((route) => (
          <RouteProtected
            access={route.access}
            key={route.path}
            component={route.component}
            exact={route.exact}
            path={route.path}
            internal
          />
        ))}
        <Redirect to={MAP_PATH} />
      </Switch>
    </>
  );
}

export default Home;
