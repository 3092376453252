import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import { GoTrashcan } from 'react-icons/go';
import { FaDrawPolygon } from 'react-icons/fa';
import { IoIosSave } from 'react-icons/io';
import './DrawTool.css';

class RawDrawTool extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onEnterMode: PropTypes.func.isRequired,
    modalId: PropTypes.string,
  }

  static defaultProps = {
    modalId: undefined,
  }

  render() {
    const {
      onDelete, onSave, modalId, t, onEnterMode,
    } = this.props;

    return (
      <div className="draw-tool">
        <div className="btn-group-vertical">
          <button
            type="button"
            className="btn btn-secondary pr-2 pl-2"
            title={t('Map.drawZone.drawButton')}
            data-target={`#${modalId}`}
            data-toggle="modal"
            onClick={onEnterMode}
          >
            <FaDrawPolygon
              size="1.5em"
            />
          </button>
          <button
            type="button"
            className="btn btn-secondary pr-2 pl-2"
            title={t('Map.drawZone.deleteButton')}
            data-target={`#${modalId}`}
            data-toggle="modal"
            onClick={onDelete}
          >
            <GoTrashcan
              size="1.5em"
            />
          </button>
          <button
            type="button"
            className="btn btn-secondary pr-2 pl-2"
            title={t('Map.drawZone.saveButton')}
            data-target={`#${modalId}`}
            data-toggle="modal"
            onClick={onSave}
          >
            <IoIosSave
              size="1.5em"
            />
          </button>
        </div>
      </div>
    );
  }
}

const DrawTool = withTranslation()(RawDrawTool);

export default DrawTool;
