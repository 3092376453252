import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import nextId from 'react-id-generator';
import { AiFillCheckCircle } from 'react-icons/ai';
import iconAdv from 'assets/pictures/layersicons/layer_adv.svg';
import iconItineraire from 'assets/pictures/layersicons/layer_itineraire.svg';
import iconJdz from 'assets/pictures/layersicons/layer_jdz.svg';
import iconPn from 'assets/pictures/layersicons/layer_pn.svg';
import iconSel from 'assets/pictures/layersicons/layer_sel.svg';
import iconSignal from 'assets/pictures/layersicons/layer_signal.svg';
import iconTiv from 'assets/pictures/layersicons/layer_tiv.svg';
import iconZep from 'assets/pictures/layersicons/layer_zep.svg';

import {
  getMidiObjectBySource, MIDI_OBJECTS_LAYERS,
} from 'common/Map/Consts/MidiObjects';

const MultipleObjectsPopupContent = ({ features, onObjectClick }) => {
  const { t } = useTranslation();

  const formatProperties = (feature) => {
    const formattedProperties = {};

    formattedProperties.title = t('Map.multipopupitems.itemundefined');
    formattedProperties.lineName = feature.properties.L_code;
    formattedProperties.trackName = feature.properties.V_nom;
    formattedProperties.pk = feature.properties.pk_sncf;
    formattedProperties.libelle = (feature.properties.RA_libelle !== undefined) ? feature.properties.RA_libelle : '';

    switch (feature.sourceLayer) {
      case MIDI_OBJECTS_LAYERS.adv:
        formattedProperties.title = <span className="font-weight-bolder text-uppercase">{t('Map.multipopupitems.adv')}</span>;
        formattedProperties.icon = iconAdv;
        break;
      case MIDI_OBJECTS_LAYERS.itineraire:
        formattedProperties.title = <span className="font-weight-bolder text-uppercase">{t('Map.multipopupitems.itineraire')}</span>;
        formattedProperties.icon = iconItineraire;
        break;
      case MIDI_OBJECTS_LAYERS.jdz:
        formattedProperties.title = <span className="font-weight-bolder text-uppercase">{t('Map.multipopupitems.jdz')}</span>;
        formattedProperties.icon = iconJdz;
        break;
      case MIDI_OBJECTS_LAYERS.pn:
        formattedProperties.title = <span className="font-weight-bolder text-uppercase">{t('Map.multipopupitems.pn')}</span>;
        formattedProperties.icon = iconPn;
        break;
      case MIDI_OBJECTS_LAYERS.sel:
        formattedProperties.title = <span className="font-weight-bolder text-uppercase">{t('Map.multipopupitems.sel')}</span>;
        formattedProperties.icon = iconSel;
        break;
      case MIDI_OBJECTS_LAYERS.signal:
        formattedProperties.icon = iconSignal;
        formattedProperties.title = (
          <div className="d-flex align-items-center">
            <span className="font-weight-bolder text-uppercase mr-1">
              {t('Map.multipopupitems.signal')}
            </span>
            <span className="mr-2">{feature.properties.TIF_mnemo}</span>
            <span className="ml-auto">{formattedProperties.libelle}</span>
          </div>
        );
        formattedProperties.libelle = ''; // to avoid twice libelle
        break;
      case MIDI_OBJECTS_LAYERS.tivSch:
      case MIDI_OBJECTS_LAYERS.tivGeo:
        formattedProperties.icon = iconTiv;
        formattedProperties.title = <span className="font-weight-bolder text-uppercase">{t('Map.multipopupitems.line')}</span>;
        formattedProperties.lineName = feature.properties.L_code;
        formattedProperties.trackName = feature.properties.V_nom;
        formattedProperties.pk = `${feature.properties.pk_sncf_debut} — ${feature.properties.pk_sncf_fin}`;
        formattedProperties.libelle = (feature.properties.V_nom !== undefined) ? feature.properties.V_nom : '';
        break;
      case MIDI_OBJECTS_LAYERS.zep:
        formattedProperties.title = <span className="font-weight-bolder text-uppercase">{t('Map.multipopupitems.zep')}</span>;
        formattedProperties.icon = iconZep;
        break;
      default:
        formattedProperties.title = (
          <>
            <span className="font-weight-bolder mr-1">{getMidiObjectBySource(feature.sourceLayer).singular}</span>
            <span>{formattedProperties.RA_libelle}</span>
          </>
        );
        break;
    }

    return formattedProperties;
  };

  return (
    <div className="multipleobjectspopupcontent w-100 mr-2">
      {features.map((feature, index) => {
        const formattedProperties = formatProperties(feature);
        return (
          <div key={nextId()} className="multipleobjectspopupcontent-item w-100 my-2 p-1">
            <div role="button" className="multipleobjectspopupcontent-item-content d-flex align-items-center" onClick={() => onObjectClick(feature)} tabIndex={index}>
              {formattedProperties.icon !== '' ? (
                <div className="multipleobjectspopupcontent-item-icon mr-2">
                  <img src={formattedProperties.icon} alt="" />
                </div>
              ) : '' }
              <div className="flex-grow-1 w-100">
                <div className="multipleobjectspopupcontent-item-title d-flex align-items-center">
                  {feature.properties.isVerifie ? <span className="text-green text-center mr-2"><AiFillCheckCircle /></span> : ''}
                  <span className="flex-grow-1 w-100">{formattedProperties.title}</span>
                </div>
                {formattedProperties.pk === undefined
                    && formattedProperties.trackName === undefined
                    && formattedProperties.lineName === undefined ? (
                    ''
                  ) : (
                    <div className="multipleobjectspopupcontent-item-body d-flex align-items-center d-flex justify-content-between text-monospace">
                      <span>
                        <small className="mr-1 text-muted">{t('Map.multipopupitems.pk')}</small>
                        {formattedProperties.pk}
                      </span>
                      <span>
                        <small className="mr-1 text-muted text-uppercase">{t('Map.multipopupitems.track')}</small>
                        {formattedProperties.trackName}
                      </span>
                      <span>
                        <small className="mr-1 text-muted text-uppercase">{t('Map.multipopupitems.line')}</small>
                        {formattedProperties.lineName}
                      </span>
                    </div>
                  )}
                {formattedProperties.libelle !== '' ? (
                  <div>
                    <small className="mr-1 text-muted text-uppercase">{t('Map.multipopupitems.label')}</small>
                    <span className="text-monospace">
                      {formattedProperties.libelle}
                    </span>
                  </div>
                ) : ''}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

MultipleObjectsPopupContent.propTypes = {
  features: PropTypes.array.isRequired,
  onObjectClick: PropTypes.func.isRequired,
};

export default MultipleObjectsPopupContent;
