import { EditingMode, DrawLineStringMode } from 'react-map-gl-draw';
import { ExtendLineStringMode } from '@nebula.gl/edit-modes';

export const MODES = {
  edit: new EditingMode(),
  drawLine: new DrawLineStringMode(),
  extendLine: new ExtendLineStringMode(),
};

export const mockFeature = (type) => ({
  type: 'Feature',
  geometry: {
    type,
    coordinates: [],
  },
  properties: {
    renderType: type,
  },
});

export const geoEditorLayers = [
  'modified-lines',
  'modified-points',
  'invisible-points',
  'invisible-tiv',
];

export const INITIAL_SNAPPING_LAYERS = [
  'invisible-tiv',
  'invisible-points',
];

export const linearObjectPaint = {
  'line-color': ['case',
    ['==', ['get', 'isGeomSchFromLRS'], ['boolean', true]], '#FF0000',
    '#a1006b',
  ],
  'line-width': 3,
};

export const tivPaint = {
  'line-color': ['case',
    ['==', ['get', 'type_voie'], 'VP'], '#000',
    ['==', ['get', 'type_voie'], 'VS'], '#888',
    '#666',
  ],
  'line-width': ['case',
    ['==', ['get', 'type_voie'], 'VP'], 3,
    ['==', ['get', 'type_voie'], 'VS'], 1,
    3,
  ],
};

export const EXTEND_LINE_KEYS = [17, 91]; // CTRL, CMD
export const DELETE_KEYS = [8]; // Backspace
