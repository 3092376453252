const geoEditorOptions = {
  minZoom: 9,
  snappingDistance: 5,
  tivSnappingDistance: 10,
  pointSnappingDistance: 5,
  width: '100%',
  height: '100%',
};

export default geoEditorOptions;
