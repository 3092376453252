import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MIDI_OBJECTS_KEYS } from 'common/Map/Consts/MidiObjects';

class SideBarInfosCustomTitle extends React.Component {
  getTitle = () => {
    const {
      map,
      SF_nomVoie: SFNomVoie,
      SF_codeLigne: SFCodeLigne,
      RA_libelle: RALibelle,
      L_code: LCode,
      V_nom: VNom,
      TIF_mnemo: TIFMnemo,
    } = this.props;

    let title = '';
    if (RALibelle.length !== 0) {
      title = RALibelle;
    } else if (LCode.length !== 0) {
      title = `${LCode} ${VNom}`;
    } else {
      title = `${SFCodeLigne} ${SFNomVoie}`;
    }

    if (map.selectedObjectLayer) {
      if (map.selectedObjectLayer.key === MIDI_OBJECTS_KEYS.signal) {
        title = `${TIFMnemo} ${title}`;
      } else if (map.selectedObjectLayer.key === MIDI_OBJECTS_KEYS.sel) {
        title = `${title}`;
      } else {
        title = `${map.selectedObjectLayer.singular} ${title}`;
      }
    }

    return title;
  }

  render() {
    return (
      <>
        <strong className="mr-2">{this.getTitle()}</strong>
      </>
    );
  }
}

SideBarInfosCustomTitle.propTypes = {
  SF_nomVoie: PropTypes.string,
  SF_codeLigne: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  RA_libelle: PropTypes.string,
  L_code: PropTypes.string,
  V_nom: PropTypes.string,
  TIF_mnemo: PropTypes.string,
  map: PropTypes.object.isRequired,
};
SideBarInfosCustomTitle.defaultProps = {
  SF_nomVoie: '',
  SF_codeLigne: '',
  RA_libelle: '',
  L_code: '',
  V_nom: '',
  TIF_mnemo: '',
};

const mapStateToProps = (state) => ({
  map: state.map,
});

export default connect(mapStateToProps)(SideBarInfosCustomTitle);
