import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import JointsDeZones from 'common/Map/Layers/JointsDeZones';
import LinearObject from 'common/Map/Layers/LinearObject';
import SignalsSCH from 'common/Map/Layers/SignalsSCH';
import SignalsGEO from 'common/Map/Layers/Signals';

import {
  ALL_SIGNAL_LAYERS, MAP_MODES, MAP_TRACK_SOURCES, MAP_URL, QUALITY_SIGNALS,
} from 'common/Map/const';
import { MIDI_OBJECTS_KEYS } from 'common/Map/Consts/MidiObjects';

function ObjectLayer({ sourceLayer }) {
  const { elements, trackSource, mode } = useSelector((state) => state.map);

  return elements.selected.objects.map((el) => {
    if (el.isLinear) {
      return (
        <LinearObject
          key={el.sourceTable}
          mapURL={MAP_URL}
          sourceLayer={sourceLayer}
          sourceTable={el.sourceTable}
          mapMode={mode}
        />
      );
    }
    if (el.key === MIDI_OBJECTS_KEYS.jdz) {
      return (
        <JointsDeZones
          source="midi"
          mapURL={MAP_URL}
          sourceLayer={sourceLayer}
          sourceTable={el.sourceTable}
          mapMode={mode}
        />
      );
    }
    if (el.key === MIDI_OBJECTS_KEYS.signal) {
      // find which child is selected among the signal's children
      const selectedChild = elements.selected.objects
        .find((obj) => obj.key === MIDI_OBJECTS_KEYS.signal)
        .children
        .find((child) => child.selected);
      let signalList = ALL_SIGNAL_LAYERS;
      if ((selectedChild?.key === 'qualitySignals') || mode === MAP_MODES.modification) {
        signalList = QUALITY_SIGNALS;
      }

      if (trackSource === MAP_TRACK_SOURCES.schematic) {
        return (
          <SignalsSCH
            source="midi"
            mapURL={MAP_URL}
            sourceLayer={sourceLayer}
            sourceTable={el.sourceTable}
            signalList={signalList}
            mapMode={mode}
          />
        );
      }
      return (
        <SignalsGEO
          source="midi"
          mapURL={MAP_URL}
          sourceLayer={sourceLayer}
          sourceTable={el.sourceTable}
          mapMode={mode}
        />
      );
    }
    return null;
  });
}

ObjectLayer.propTypes = {
  sourceLayer: PropTypes.string.isRequired,
};

export default ObjectLayer;
