import React from 'react';
import { Source, Layer } from 'react-map-gl';
import { useSelector } from 'react-redux';

import { MAP_URL } from 'common/Map/const';
import { trackNameLayer, lineNumberLayer } from 'common/Map/Layers/commonlayers.json';
import { geoMainLayer, geoServiceLayer } from 'common/Map/Layers/geographiclayers.json';
import { MIDI_OBJECTS_LAYERS } from 'common/Map/Consts/MidiObjects';
import { booleanToStringLayerVisibility } from 'utils/helpers';

import Selected from 'common/Map/Selected';
import Hover from 'common/Map/Hover';

function GeographicView() {
  const { layers } = useSelector((state) => state.map);

  return (
    <Source type="vector" url={`${MAP_URL}/chartisv1/layer/map_midi_tronconditinerairevoie/mvt/geo/`} source-layer={MIDI_OBJECTS_LAYERS.tivGeo}>
      <Layer
        {...geoMainLayer}
        layout={{ visibility: booleanToStringLayerVisibility(layers.vp.visible) }}
        source-layer={MIDI_OBJECTS_LAYERS.tivGeo}
      />
      <Layer
        {...geoServiceLayer}
        layout={{ visibility: booleanToStringLayerVisibility(layers.vs.visible) }}
        source-layer={MIDI_OBJECTS_LAYERS.tivGeo}
      />
      <Layer
        {...{
          ...trackNameLayer,
          layout: {
            ...trackNameLayer.layout,
            'text-field': '{V_nom}',
            visibility: booleanToStringLayerVisibility(layers.vp.visible),
          },
        }}
        source-layer={MIDI_OBJECTS_LAYERS.tivGeo}
        filter={['==', 'TDV_mnemonique', 'VPL']}
      />
      <Layer
        {...{
          ...trackNameLayer,
          layout: {
            ...trackNameLayer.layout,
            'text-field': '{V_nom}',
            'text-size': 10,
            visibility: booleanToStringLayerVisibility(layers.vs.visible),
          },
        }}
        source-layer={MIDI_OBJECTS_LAYERS.tivGeo}
        filter={['!=', 'TDV_mnemonique', 'VPL']}
      />
      <Layer
        {...{
          ...lineNumberLayer,
          layout: {
            ...lineNumberLayer.layout,
            'text-field': '{L_code}',
          },
        }}
        source-layer={MIDI_OBJECTS_LAYERS.tivGeo}
      />
      <Selected filterField="OP_id" sourceLayer={MIDI_OBJECTS_LAYERS.tivGeo} />
      <Hover filterField="OP_id" sourceLayer={MIDI_OBJECTS_LAYERS.tivGeo} />
    </Source>
  );
}

export default GeographicView;
