export const DATA_TYPES = {
  string: 'STRING',
  date: 'DATE',
  array: 'ARRAY',
  status: 'STATUS',
  action: 'ACTION',
  object: 'OBJECT',
  number: 'NUMBER',
  percent: 'PERCENT',
};
