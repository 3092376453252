export const MIDI_OBJECTS_KEYS = {
  zep: 'zep',
  groupementZep: 'groupement_zep',
  signal: 'signal',
  jdz: 'joint_de_zone',
  cdv: 'circuit_de_voie',
  adv: 'appareil_de_voie',
  itineraire: 'itineraire',
  sel: 'catenaire',
  groupementSel: 'groupement_catenaire',
  tivSch: 'tivSch',
  tivGeo: 'tivGeo',
  poste: 'poste',
};

export const MIDI_OBJECTS_LAYERS = {
  zep: 'map_midi_zep',
  groupementZep: 'map_midi_groupementdezep',
  signal: 'map_midi_signal',
  jdz: 'map_midi_jointdezone',
  cdv: 'map_midi_circuitdevoie',
  adv: 'map_midi_brancheapv',
  itineraire: 'map_midi_itineraire',
  sel: 'map_midi_elementcatenaire',
  groupementSel: 'map_midi_groupementcatenaire',
  poste: 'map_midi_poste',
  tivSch: 'map_midi_tronconditinerairevoie',
  tivGeo: 'map_midi_tronconditinerairevoie',
};

export const midiObjects = [
  {
    key: MIDI_OBJECTS_KEYS.zep,
    sourceTable: MIDI_OBJECTS_LAYERS.zep,
    label: 'Zep - Zones élémentaires de protection',
    singular: 'Zep',
    pcid: 249,
    isEditable: true,
    isLinear: true,
  },
  {
    key: MIDI_OBJECTS_KEYS.groupementZep,
    sourceTable: MIDI_OBJECTS_LAYERS.groupementZep,
    label: 'Groupements de zep',
    singular: 'Groupement de zep',
    pcid: 185,
    isEditable: true,
    isLinear: true,
  },
  {
    key: MIDI_OBJECTS_KEYS.signal,
    sourceTable: MIDI_OBJECTS_LAYERS.signal,
    label: 'Signaux',
    singular: 'Signal',
    pcid: 278,
    isEditable: true,
    children: [
      {
        key: 'allSignals',
        label: 'Tous les signaux',
        default: true,
      },
      {
        key: 'qualitySignals',
        label: 'Carrés, carrés violet, heurtoirs, ATC, disques, guidons d\'arrêt, SG TGV, repères TGV et signaux fictifs',
      },
    ],
  },
  {
    key: MIDI_OBJECTS_KEYS.jdz,
    sourceTable: MIDI_OBJECTS_LAYERS.jdz,
    label: 'Joints de zone',
    singular: 'Joint de zone',
    pcid: 274,
    isEditable: true,
  },
  {
    key: MIDI_OBJECTS_KEYS.cdv,
    sourceTable: MIDI_OBJECTS_LAYERS.cdv,
    label: 'Circuits de voie',
    singular: 'Circuit de voie',
    pcid: 293,
    isEditable: true,
    isLinear: true,
  },
  {
    key: MIDI_OBJECTS_KEYS.adv,
    sourceTable: MIDI_OBJECTS_LAYERS.adv,
    label: 'Appareils de voie',
    singular: 'Appareil de voie',
    pcid: 62,
    isEditable: true,
    isLinear: true,
  },
  {
    key: MIDI_OBJECTS_KEYS.itineraire,
    sourceTable: MIDI_OBJECTS_LAYERS.itineraire,
    label: 'Itinéraires',
    singular: 'Itinéraire',
    pcid: 191,
    isEditable: true,
    isLinear: true,
  },
  {
    key: MIDI_OBJECTS_KEYS.sel,
    sourceTable: MIDI_OBJECTS_LAYERS.sel,
    label: 'Éléments caténaire (Sel, Feeder)',
    singular: 'Élément caténaire',
    pcid: 236,
    isEditable: true,
    isLinear: true,
  },
  {
    key: MIDI_OBJECTS_KEYS.groupementSel,
    sourceTable: MIDI_OBJECTS_LAYERS.groupementSel,
    label: 'Groupements caténaire, Secteurs, Sous-secteurs',
    singular: 'Groupement caténaire',
    pcid: 291,
    isEditable: true,
    isLinear: true,
  },
  {
    key: MIDI_OBJECTS_KEYS.tivSch,
    sourceTable: MIDI_OBJECTS_LAYERS.tivSch,
    singular: 'TIV',
    hide: true,
  },
  {
    key: MIDI_OBJECTS_KEYS.tivGeo,
    sourceTable: MIDI_OBJECTS_LAYERS.tivGeo,
    singular: 'TIV',
    hide: true,
  },
  {
    key: MIDI_OBJECTS_KEYS.poste,
    sourceTable: MIDI_OBJECTS_LAYERS.poste,
    singular: 'Poste',
  },
];

export const midiObjectsGroups = [
  {
    label: 'S9C',
    content: [midiObjects[0], midiObjects[1], midiObjects[2]],
  },
];

export const getMidiObjectByKey = (key) => midiObjects.find((o) => o.key === key);

export const getSourceObjectByKey = (key) => {
  const obj = midiObjects.find((o) => o.key === key);
  if (obj) {
    return obj.sourceTable;
  }
  return undefined;
};

export const getMidiObjectBySource = (source) => midiObjects.find((o) => o.sourceTable === source);
