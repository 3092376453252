import React from 'react';
import { useSelector } from 'react-redux';
import { Layer, Source } from 'react-map-gl';
import PropTypes from 'prop-types';

function SnappingPointsLayer({ data }) {
  const { viewport } = useSelector((state) => state.map);
  const snappingRadius = 0.15 + 0.00337 * Math.exp(0.5517125 * viewport.zoom);

  return (
    <Source
      id="invisible-points-source"
      type="geojson"
      data={{
        type: 'FeatureCollection',
        features: data,
      }}
    >
      <Layer
        id="invisible-points"
        type="circle"
        paint={{
          'circle-color': 'hsla(0, 100%, 50%, 0.001)',
          'circle-radius': snappingRadius,
        }}
      />
    </Source>
  );
}

SnappingPointsLayer.propTypes = {
  data: PropTypes.array.isRequired,
};

export default SnappingPointsLayer;
