export const ACTION_TYPES = {
  M: {
    name: 'MODIFICATION',
    label: 'Logs.actionTypes.modification',
  },
  D: {
    name: 'DELETION',
    label: 'Logs.actionTypes.deletion',
  },
  I: {
    name: 'INSERTION',
    label: 'Logs.actionTypes.insertion',
  },
  S: {
    name: 'SPLIT',
    label: 'Logs.actionTypes.split',
  },
};
