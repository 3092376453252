import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DETAILS_TABLE_FIELDS, MAP_MODES } from 'common/Map/const';
import { MIDI_OBJECTS_KEYS } from 'common/Map/Consts/MidiObjects';
import { GoPlus } from 'react-icons/go';

export const JSON_KEYS = {
  extremites: 'extremites',
  branches: 'branches',
  adv_positionnes: 'adv_positionnes',
  itineraires_impactes: 'itineraires_impactes',
  cles_protections: 'cles_protections',
  signal_origin: 'RA_libelle_signal_origine',
  signal_destination: 'RA_libelle_signal_destination',
  sels: 'sels',
  groupements_catenaires: 'groupements_catenaires',
  zeps: 'zeps',
  perimetres_associes: 'perimetres_associes',
  perimetres_responsables: 'perimetres_responsables',
  poste: 'RA_libelle_poste',
  tables_associees: 'tables_associees',
  tables_responsables: 'tables_responsables',
  is_mgpt: 'is_mgpt',
  isGeomSchFromLRS: 'isGeomSchFromLRS',
};

export const JSON_FIELDS = [
  {
    key: JSON_KEYS.extremites,
    label: 'Map.popupDetails.addExtremite',
    type: '#geom',
  },
  {
    key: JSON_KEYS.branches,
    label: 'Map.popupDetails.addBranch',
    type: '#geom',
  },
  {
    key: JSON_KEYS.adv_positionnes,
    label: 'Map.popupDetails.addAdv',
    type: '#geom',
  },
  {
    key: JSON_KEYS.itineraires_impactes,
    label: 'Map.popupDetails.addIti',
    type: '#attributs',
  },
  {
    key: JSON_KEYS.cles_protections,
    label: 'Map.popupDetails.addKey',
    type: '#attributs',
  },
  {
    key: JSON_KEYS.signal_origin,
    label: 'Map.popupDetails.addSignalOrigin',
    type: '#geom',
  },
  {
    key: JSON_KEYS.signal_destination,
    label: 'Map.popupDetails.addSignalDestination',
    type: '#geom',
  },
  {
    key: JSON_KEYS.sels,
    label: 'Map.popupDetails.addSel',
    type: '#geom',
  },
  {
    key: JSON_KEYS.groupements_catenaires,
    label: 'Map.popupDetails.addGroupementCatenaire',
    type: '#geom',
  },
  {
    key: JSON_KEYS.zeps,
    label: 'Map.popupDetails.addZep',
    type: '#geom',
  },
  {
    key: JSON_KEYS.perimetres_associes,
    label: 'Map.popupDetails.addPerimetreAssocie',
    type: '#attributs',
  },
  {
    key: JSON_KEYS.tables_associees,
    label: 'Map.popupDetails.addTableAssociee',
    type: '#attributs',
  },
  {
    key: JSON_KEYS.perimetres_responsables,
    label: 'Map.popupDetails.addPerimetreResponsable',
    type: '#attributs',
  },
  {
    key: JSON_KEYS.tables_responsables,
    label: 'Map.popupDetails.addTableResp',
    type: '#attributs',
  },
  {
    key: JSON_KEYS.poste,
    label: 'Map.popupDetails.addPoste',
    type: '#attributs',
  },
  {
    key: JSON_KEYS.is_mgpt,
    label: 'Map.popupDetails.addMGPT',
    type: '#attributs',
  },
];

const ToolsBar = ({
  modalId, validateObjects, deleteObjects, addRows, switchToLRS,
}) => {
  const map = useSelector((state) => state.map);
  const { t } = useTranslation();

  const getActions = (mode) => {
    const addButtons = JSON_FIELDS.filter(
      (field) => Object.keys(map.popupContent).includes(field.key)
       && field.key !== JSON_KEYS.signal_origin && field.key !== JSON_KEYS.signal_destination
       && field.key !== JSON_KEYS.poste && field.key !== JSON_KEYS.is_mgpt
       && field.key !== JSON_KEYS.perimetres_responsables && field.key !== JSON_KEYS.perimetres_associes,
    );
    if (map.selectedObjectLayer?.key === MIDI_OBJECTS_KEYS.itineraire) {
      if (!Object.keys(map.popupContent).includes(JSON_KEYS.signal_origin)) {
        addButtons.push(JSON_FIELDS.find((field) => field.key === JSON_KEYS.signal_origin));
      } else if (!Object.keys(map.popupContent).includes(JSON_KEYS.signal_destination)) {
        addButtons.push(JSON_FIELDS.find((field) => field.key === JSON_KEYS.signal_destination));
      }
    }
    if (map.selectedObjectLayer.key !== MIDI_OBJECTS_KEYS.jdz
      && map.selectedObjectLayer.key !== MIDI_OBJECTS_KEYS.tivSch
      && map.selectedObjectLayer.key !== MIDI_OBJECTS_KEYS.tivSch
      && !Object.keys(map.popupContent).includes(DETAILS_TABLE_FIELDS.OP_id_poste)) {
      addButtons.push(JSON_FIELDS.find((field) => field.key === JSON_KEYS.poste));
    }
    if (Object.keys(map.popupContent).includes(JSON_KEYS.perimetres_associes)
      && map.popupContent.perimetres_associes.length === 0) {
      addButtons.push(JSON_FIELDS.find((field) => field.key === JSON_KEYS.perimetres_associes));
    }
    if (Object.keys(map.popupContent).includes(JSON_KEYS.perimetres_associes)
      && map.popupContent.perimetres_associes.length !== 0
      && !Object.keys(map.popupContent).includes(JSON_KEYS.tables_associees)) {
      if (map.perimetresAssocies.length !== 0 ? map.perimetresAssocies[0].OP_id_poste_metier !== '' : map.popupContent.perimetres_associes[0].OP_id_poste_metier !== '') {
        addButtons.push(JSON_FIELDS.find((field) => field.key === JSON_KEYS.tables_associees));
      }
    }
    if (Object.keys(map.popupContent).includes(JSON_KEYS.perimetres_responsables)
      && map.popupContent.perimetres_responsables.length === 0) {
      addButtons.push(JSON_FIELDS.find((field) => field.key === JSON_KEYS.perimetres_responsables));
    }
    if (Object.keys(map.popupContent).includes(JSON_KEYS.perimetres_responsables)
      && map.popupContent.perimetres_responsables.length !== 0
      && !Object.keys(map.popupContent).includes(JSON_KEYS.tables_responsables)) {
      if (map.perimetresResponsables.length !== 0 ? map.perimetresResponsables[0].OP_id_poste_metier !== '' : map.popupContent.perimetres_responsables[0].OP_id_poste_metier !== '') {
        addButtons.push(JSON_FIELDS.find((field) => field.key === JSON_KEYS.tables_responsables));
      }
    }
    if ((map.selectedObjectLayer.key === MIDI_OBJECTS_KEYS.zep
      || map.selectedObjectLayer.key === MIDI_OBJECTS_KEYS.groupementZep)
      && !Object.keys(map.popupContent).includes(JSON_KEYS.is_mgpt)) {
      addButtons.push(JSON_FIELDS.find((field) => field.key === JSON_KEYS.is_mgpt));
    }
    if ((map.selectedObjectLayer.key === MIDI_OBJECTS_KEYS.zep
      || map.selectedObjectLayer.key === MIDI_OBJECTS_KEYS.groupementZep
      || map.selectedObjectLayer.key === MIDI_OBJECTS_KEYS.sel
      || map.selectedObjectLayer.key === MIDI_OBJECTS_KEYS.groupementSel)
      && !Object.keys(map.popupContent).includes(JSON_KEYS.itineraires_impactes)) {
      addButtons.push(JSON_FIELDS.find((field) => field.key === JSON_KEYS.itineraires_impactes));
    }
    if (map.selectedObjectLayer.key === MIDI_OBJECTS_KEYS.groupementSel
      && !Object.keys(map.popupContent).includes(JSON_KEYS.groupements_catenaires)) {
      addButtons.push(JSON_FIELDS.find((field) => field.key === JSON_KEYS.groupements_catenaires));
    }

    const buttonsType = [];
    Object.values(addButtons).forEach((field) => !buttonsType.includes(field.type) && buttonsType.push((field.type)));

    const getTools = buttonsType.includes(map.sidebarTab)
      ? (
        <div className="d-flex justify-content-center dropdown" style={{ paddingRight: '.5rem', paddingLeft: '.5rem' }}>
          <button
            type="button"
            id="dropdownMenuButton1"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            aria-controls="mycontrol1"
            className="btn btn-sm btn-primary mt-4 dropdown-toggle"
            title="button"
          >
            <GoPlus
              size="1.5em"
            />
          </button>
          <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton1" id="mycontrol1">
            <ul>
              {addButtons.map((button) => (
                <>
                  {
              map.sidebarTab.includes(button.type)
              && (
              <button
                key={button.key}
                className="dropdown-item"
                type="button"
                title={t(button.label)}
                onClick={() => addRows(button.key)}
              >
                <span>{t(button.label)}</span>
              </button>
              )
            }
                </>
              ))}
            </ul>
          </div>
        </div>
      ) : <></>;

    const displaySwitchToLRS = mode === MAP_MODES.modification
                            && map.selectedObjectLayer.key !== MIDI_OBJECTS_KEYS.tivSch
                            && !map.popupContent.isGeomSchFromLRS;

    const tools = mode === MAP_MODES.display
      ? <></>
      : (
        <>
          {addRows === undefined && (
          <div className="d-flex justify-content-end my-2">
            <div className="sideBarTools-container">
              {displaySwitchToLRS && (
                <button
                  className="btn btn-sm mr-2 btn-primary"
                  type="button"
                  title={t('Map.popupDetails.restoreLRS.button')}
                  data-target={`#${modalId}`}
                  data-toggle="modal"
                  onClick={switchToLRS}
                >
                  <span>{t('Map.popupDetails.restoreLRS.button')}</span>
                </button>
              )}
            </div>
            <div className="sideBarTools-container">
              {mode === MAP_MODES.modification && (
                <button
                  className="btn btn-sm mr-2 btn-danger"
                  type="button"
                  title={t('Map.popupDetails.deleteButton')}
                  data-target={`#${modalId}`}
                  data-toggle="modal"
                  onClick={deleteObjects}
                >
                  <span>{t('Map.popupDetails.deleteButton')}</span>
                </button>
              )}
            </div>
            <div className="sideBarTools-container">
              <button
                className="btn btn-sm mr-2 btn-success"
                type="button"
                title={t('Map.popupDetails.validateButton')}
                data-target={`#${modalId}`}
                data-toggle="modal"
                onClick={validateObjects}
              >
                <span>{t('Map.popupDetails.validateButton')}</span>
              </button>
            </div>
          </div>
          )}
          {addRows !== undefined && getTools}
        </>
      );

    return tools;
  };

  return (
    <>
      {getActions(map.mode)}
    </>
  );
};

ToolsBar.propTypes = {
  modalId: PropTypes.string,
  validateObjects: PropTypes.func,
  deleteObjects: PropTypes.func,
  addRows: PropTypes.func,
};

ToolsBar.defaultProps = {
  modalId: undefined,
  validateObjects: undefined,
  deleteObjects: undefined,
  addRows: undefined,
};

export default ToolsBar;
