import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function ModificationButtons({ buttonTitle, color, onClick }) {
  const { t } = useTranslation();

  return (
    <div className="d-flex align-items-end justify-content-end" style={{ width: '420px' }}>
      <div className="btn-group dropdown">
        <button type="button" className="btn btn-sm btn-secondary mr-2" data-dismiss="modal">
          <span>{t('Map.popupDetails.discartButton')}</span>
        </button>
      </div>
      <div className="btn-group dropdown">
        <button type="button" className={`btn btn-sm ${color}`} onClick={onClick}>
          <span>{buttonTitle}</span>
        </button>
      </div>
    </div>
  );
}

ModificationButtons.propTypes = {
  buttonTitle: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ModificationButtons;
