/* eslint-disable no-underscore-dangle */
import React, {
  useRef, useState, useEffect, useCallback,
} from 'react';
import ReactMapGL, { WebMercatorViewport } from 'react-map-gl';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { debounce } from 'debounce';
import { DrawPolygonMode, EditingMode, DrawLineStringMode } from 'react-map-gl-draw';
import { last } from 'lodash';
import bbox from '@turf/bbox';
import { ExtendLineStringMode } from '@nebula.gl/edit-modes';

// Common components
import ButtonFullscreen from 'common/ButtonFullscreen';
import ModalSNCF from 'common/BootstrapSNCF/ModalSNCF/ModalSNCF';
import ModalHeaderSNCF from 'common/BootstrapSNCF/ModalSNCF/ModalHeaderSNCF';
import ModalBodySNCF from 'common/BootstrapSNCF/ModalSNCF/ModalBodySNCF';
import ModalFooterSNCF from 'common/BootstrapSNCF/ModalSNCF/ModalFooterSNCF';
import Caption from 'common/Map/Caption/Caption';
import {
  MAP_MODES, MAP_TRACK_SOURCES, MAP_URL, QUALITY_SIGNALS, DETAILS_TABLE_FIELDS,
} from 'common/Map/const';
import ElementsMenu from 'common/Map/ElementsMenu';
import ErrorBoundary from 'common/ErrorBoundaries/ErrorBoundary';
import Loader from 'common/Loader';
import ButtonResetViewport from 'common/ButtonResetViewport';
import ButtonResetView from 'common/ButtonResetView';

// Specific components
import FiltersMenu from 'components/FiltersMenu';
import DrawToolLayer from 'components/DrawToolLayer';
import CreationTools from 'components/CreationTools';

// Assets and Styles
import * as mapActions from 'reducers/map';
import * as zonesActions from 'reducers/zones';
import * as logsActions from 'reducers/logs';
import * as profileActions from 'reducers/profile';
import './Map.css';

// Helpers and constants
import { getSignalLayerId, formatViewport } from 'utils/helpers';
import { flyTo } from 'utils/reducerHelpers';
import {
  MIDI_OBJECTS_KEYS, getSourceObjectByKey, getMidiObjectBySource,
  midiObjects, midiObjectsGroups, MIDI_OBJECTS_LAYERS,
} from 'common/Map/Consts/MidiObjects';
import { MAP_PATH } from 'utils/router';
import mapboxgl from 'mapbox-gl'; // This is a dependency of react-map-gl even if you didn't explicitly install it
import SchematicView from 'components/Map/SchematicView';
import GeographicView from 'components/Map/GeographicView';
import ObjectLayer from 'components/Map/ObjectLayer';
import ZoneLayer from 'components/Map/ZoneLayer';
import GeoEditor from 'components/GeoEditor';
import geoEditorOptions from 'components/GeoEditor/config';
import {
  toggleInfraEditor, toggleShouldDisplay, updateData,
  updateJdz, updateSelectedData, updateSignals, updateTiv,
  updateMode as updateEditorMode, updateSnappingLayers, updateSnappedGeometry,
} from 'reducers/geoEditor';
import { geoEditorLayers, INITIAL_SNAPPING_LAYERS, MODES } from 'components/GeoEditor/const';
import {
  getGeoJSONData, getChartisParams, refreshLinearData, refreshTivData,
  refreshCreationData, buildCustomGeom, snapOnHover,
} from 'components/GeoEditor/helpers';
import MultipleObjectsPopup from 'components/Map/MultipleObjectsPopup';
import CustomPopup from 'components/Map/CustomPopup';
import SideBarWrapper from 'components/Map/SideBar/SideBarWrapper';
import MenuBar from 'components/Map/MenuBar';
import { useParams } from 'react-router-dom';

import arrow from '../../assets/pictures/arrow.png';
import arrowVS from '../../assets/pictures/arrow_vs.png';
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const mvtLayers = [
  'schematicMainLayer',
  'schematicServiceLayer',
  'schematicTunnelLayer',
];

const Map = () => {
  const modalId = 'map-modal';
  const params = useParams();
  const mapRef = useRef();
  const sideBarRef = useRef();
  const map = useSelector((state) => state.map);
  const { viewport } = map;
  const draw = useSelector((state) => state.draw);
  const { t } = useTranslation();
  const main = useSelector((state) => state.main);
  const editorRef = useRef();
  const {
    selectedData, infraEditor, shouldDisplay: displayGeoEditor,
    mode: editorMode, snappingLayers, snappedGeometry,
  } = useSelector((state) => state.geoEditor);
  const dispatch = useDispatch();

  const [featureInfoClick, setFeatureInfoClick] = useState();
  const [featureInfoHover, setFeatureInfoHover] = useState();
  const [displaySideBar, setDisplaySideBar] = useState(false);
  const [displayMultiplePopup, setDisplayMultiplePopup] = useState(false);
  const [modalHeaderTitle, setModalHeaderTitle] = useState('');
  const [modalBody, setModalBody] = useState('');
  const [modalFooter, setModalFooter] = useState('');
  const [isFlying, setIsFlying] = useState(false);
  const [jsonFiledSize, setJsonFiledSize] = useState(0);
  const [scrollZoom, setScrollZoom] = useState(true);
  const [stateViewport, setStateViewport] = useState(viewport);

  const [schematicInteractiveLayers, setSchematicInteractiveLayers] = useState(mvtLayers);
  const [isDraggingFeature, setIsDraggingFeature] = useState(false);

  const useDebounce = (value, delay = 500) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      return () => {
        clearTimeout(handler);
      };
    }, [value]);

    return debouncedValue;
  };

  const onViewportChange = (newViewport) => {
    localStorage.setItem('newViewport', JSON.stringify(newViewport));
    if (isFlying) {
      setStateViewport(newViewport);
    } else {
      dispatch(mapActions.updateViewport(newViewport, MAP_PATH));
    }
  };

  useEffect(() => {
    let newViewport = map.viewport;
    const localViewport = localStorage.getItem('newViewport');
    if (params && params.lat && params.lon && params.zoom) {
      newViewport = formatViewport(
        map.viewport, params.lat, params.lon, params.zoom, params.bearing, params.pitch,
      );
    } else if (localViewport) {
      const applyViewport = JSON.parse(localViewport);
      const {
        latitude, longitude, zoom, bearing, pitch,
      } = applyViewport;
      newViewport = formatViewport(
        map.viewport, latitude, longitude, zoom, bearing, pitch,
      );
    }

    onViewportChange(newViewport);
    if (mapRef.current) dispatch(mapActions.updateRef(mapRef));
    dispatch(mapActions.toggleTrackSource(MAP_TRACK_SOURCES.schematic));
    dispatch(mapActions.updateElementsList(midiObjects, midiObjectsGroups));
    dispatch(zonesActions.updateError(null, null));
    dispatch(logsActions.updateError(null, null));
    dispatch(profileActions.updateError(null, null));

    return () => {
      dispatch(mapActions.updateRef());
    };
  }, []);

  // Add arrow image to map instance
  useEffect(() => {
    if (mapRef.current) {
      const currentMap = mapRef.current.getMap();
      currentMap.loadImage(arrow, (err, img) => {
        currentMap.addImage('arrow', img);
      });
      currentMap.loadImage(arrowVS, (err, img) => {
        currentMap.addImage('arrow-vs', img);
      });
    }
  }, []);

  const updateModal = useCallback((header, body, footer) => {
    if (header !== '' || body !== '' || footer !== '') {
      setModalHeaderTitle(header);
      setModalBody(body);
      setModalFooter(footer);
    }
  });

  const updateObject = (selectedObject, objectSourceLayer) => {
    let updatedObject = {};
    const jsonField = [];
    let fields = {};

    const LABEL_TO_KEY = {
      RA_libelle_signal_origine: 'RA_libelle',
      pk_sncf_signalOrigine: 'pk_sncf',
      L_code_signalOrigine: 'L_code',
      V_nom_signalOrigine: 'V_nom',
      OP_id_localisationpk_signalOrigine: 'OP_id_localisationpk',
      LP_sensLecture_signalOrigine: 'LP_sensLecture',
      OP_id_canton_origine: 'OP_id',
      OP_id_tronconditinerairevoie_signalOrigine: 'OP_id_tronconditinerairevoie',
      RA_libelle_signal_destination: 'RA_libelle',
      pk_sncf_signalDestination: 'pk_sncf',
      L_code_signalDestination: 'L_code',
      V_nom_signalDestination: 'V_nom',
      OP_id_localisationpk_signalDestination: 'OP_id_localisationpk',
      LP_sensLecture_signalDestination: 'LP_sensLecture',
      OP_id_canton_destination: 'OP_id',
      OP_id_tronconditinerairevoie_signalDestination: 'OP_id_tronconditinerairevoie',
    };

    Object.keys(map.popupContent).forEach((key) => {
      const shouldUpdateExtremitiesOrBranches = map.selectedProperty
        ? map.selectedProperty.key !== 'itineraires' && map.selectedProperty.key !== 'branches' && (key === 'extremites' || key === 'branches')
        : (key === 'extremites' || key === 'branches');
      if (Object.keys(selectedObject).includes('OP_id_appareildevoie') && map.selectedObjectLayer.id === 'adv') {
        updatedObject = {
          ...updatedObject,
          OP_id_appareildevoie: selectedObject.OP_id_appareildevoie,
        };
        if (key === 'RA_libelle') {
          updatedObject = { ...updatedObject, [`${key}`]: selectedObject[`${key}`] };
        } else if (map.popupContent.key !== '') {
          updatedObject = { ...updatedObject, [`${key}`]: map.popupContent[`${key}`] };
        } else {
          updatedObject = { ...updatedObject, [`${key}`]: '' };
        }
      } else if (key === 'V_nom') {
        updatedObject = { ...updatedObject, [`${key}`]: selectedObject[`${key}`] || selectedObject.SF_nomVoie };
      } else if (key === 'L_code') {
        updatedObject = { ...updatedObject, [`${key}`]: selectedObject[`${key}`] || selectedObject.SF_codeLigne };
      } else if (key === 'OP_id_tronconditinerairevoie') {
        updatedObject = { ...updatedObject, [`${key}`]: selectedObject[key] || selectedObject.OP_id };
      } else if (key === 'object') {
        updatedObject = { ...updatedObject, [`${key}`]: selectedObject.RA_libelle || selectedObject.RA_libelle_voie, OP_id: selectedObject[key] };
      } else if (map.selectedProperty && map.selectedProperty.keyList.includes(key) && Object.keys(selectedObject).includes('LP_sensLecture')) {
        updatedObject = { ...updatedObject, [`${key}`]: selectedObject[LABEL_TO_KEY[key]] };
      } else if (shouldUpdateExtremitiesOrBranches && map.selectedProperty === undefined) {
        // eslint-disable-next-line array-callback-return
        map.popupContent[`${key}`].map((extremite, index) => {
          if (extremite.V_nom !== '' && map.popupContent[`${key}`].length !== jsonFiledSize && index !== map.popupContent[`${key}`].length - 1) {
            jsonField.push(extremite);
          } else if (index === map.popupContent[key].length - 1) {
            Object.keys(extremite).forEach((field) => {
              if (field === 'V_nom') {
                fields = { ...fields, [`${field}`]: selectedObject[`${field}`] };
              } else if (field === 'L_code') {
                fields = { ...fields, [`${field}`]: selectedObject[`${field}`] };
              } else if (field === 'OP_id_tronconditinerairevoie') {
                fields = { ...fields, [`${field}`]: selectedObject[field] || selectedObject.OP_id };
              } else if (field === 'OP_id') {
                fields = { ...fields, [`${field}`]: selectedObject[`${field}`] };
              } else if (field === 'RA_libelle') {
                fields = { ...fields, [`${field}`]: selectedObject[`${field}`] };
              } else if (field === 'pk_sncf') {
                if (selectedObject.OP_id !== undefined) {
                  fields = { ...fields, [`${field}`]: selectedObject.pk_sncf };
                } else if (Object.keys(map.popupContent).includes('branches') && map.popupContent.branches[index].BAPV_direction === 'D') {
                  fields = { ...fields, [`${field}`]: selectedObject.SF_pkSncfDe };
                } else if (Object.keys(map.popupContent).includes('branches') && map.popupContent.branches[index].BAPV_direction === 'F') {
                  fields = { ...fields, [`${field}`]: selectedObject.SF_pkSncfFi };
                } else {
                  fields = { ...fields, [`${field}`]: '' };
                }
              } else if (Object.keys(map.popupContent).includes('branches') && map.popupContent.branches[index][field] !== '') {
                fields = { ...fields, [`${field}`]: map.popupContent.branches[index][field] };
              } else {
                fields = { ...fields, [`${field}`]: '' };
              }
            });
            jsonField.push(fields);
          } else {
            jsonField.push(extremite);
          }
        });
        updatedObject = { ...updatedObject, [`${key}`]: jsonField };
        setJsonFiledSize(map.popupContent[`${key}`].length);
      } else if (key === 'extremites' && map.selectedProperty && map.selectedProperty.key === 'extremites') {
        const { path } = map.selectedProperty;
        map.popupContent[path[0]].forEach((extremite, i) => {
          if (i !== path[1]) {
            jsonField.push(extremite);
          } else {
            const newExtremite = {
              ...extremite,
              OP_id: selectedObject.OP_id,
              OP_id_tronconditinerairevoie: selectedObject.OP_id_tronconditinerairevoie || selectedObject.OP_id,
              L_code: selectedObject.L_code,
              V_nom: selectedObject.V_nom,
              pk_sncf: selectedObject.pk_sncf,
              RA_libelle: selectedObject.RA_libelle,
            };
            jsonField.push(newExtremite);
          }
        });
        updatedObject = { ...updatedObject, [`${key}`]: jsonField };
        setJsonFiledSize(map.popupContent[`${key}`].length);
      } else if (key === 'adv_positionnes' && map.selectedProperty && map.selectedProperty.key === 'branches' && Object.keys(selectedObject).includes('BAPV_codeBranche')) {
        const { path } = map.selectedProperty;
        map.popupContent[path[0]].forEach((adv, i) => {
          if (i !== path[1]) {
            jsonField.push({
              ...adv,
              OP_id_appareildevoie: selectedObject.OP_id_appareildevoie,
              CAV_rang: i + 1,
            });
          } else {
            const newAdv = {
              ...adv,
              RA_libelle: selectedObject.RA_libelle,
              OP_id: selectedObject.OP_id,
              OP_id_appareildevoie: selectedObject.OP_id_appareildevoie,
              CAV_rang: i + 1,
              BAPV_codeBranche_pointobservation: 2,
              [path[2]]: [],
            };
            adv.branches.forEach((branche, j) => {
              if (j !== last(path)) {
                newAdv[path[2]].push(branche);
              } else {
                newAdv[path[2]].push(selectedObject);
              }
            });
            jsonField.push(newAdv);
          }
        });
        updatedObject = { ...updatedObject, [`${key}`]: jsonField };
        setJsonFiledSize(map.popupContent[`${key}`].length);
      } else if (key === 'branches' && map.selectedProperty && map.selectedProperty.key === 'branches') {
        const { path } = map.selectedProperty;
        map.popupContent[path[0]].forEach((adv, i) => {
          if (i !== path[1]) {
            jsonField.push({
              ...adv,
            });
          } else {
            const newAdv = {
              ...adv,
              OP_id: selectedObject.OP_id,
              OP_id_tronconditinerairevoie: selectedObject.OP_id_tronconditinerairevoie || selectedObject.OP_id,
              L_code: selectedObject.L_code,
              V_nom: selectedObject.V_nom,
            };
            jsonField.push(newAdv);
          }
        });
        updatedObject = { ...updatedObject, [`${key}`]: jsonField };
        setJsonFiledSize(map.popupContent[`${key}`].length);
      } else if (key === 'sels' && map.selectedProperty && map.selectedProperty.key === 'sels') {
        const { path } = map.selectedProperty;
        map.popupContent[path[0]].forEach((sel, i) => {
          if (i !== path[1]) {
            jsonField.push(sel);
          } else {
            const newSel = {
              ...sel,
              OP_id: selectedObject.OP_id,
              RA_libelle: selectedObject.RA_libelle,
            };
            jsonField.push(newSel);
          }
        });
        updatedObject = { ...updatedObject, [`${key}`]: jsonField };
        setJsonFiledSize(map.popupContent[`${key}`].length);
      } else if (key === 'groupements_catenaires'
                 && map.selectedProperty.key === 'groupements_catenaires'
                 && objectSourceLayer === MIDI_OBJECTS_LAYERS.groupementSel) {
        const { path } = map.selectedProperty;
        map.popupContent[path[0]].forEach((grpSels, i) => {
          if (i !== path[1]) {
            jsonField.push(grpSels);
          } else {
            const newGrpSel = {
              ...grpSels,
              OP_id: selectedObject.OP_id,
              RA_libelle: selectedObject.RA_libelle,
            };
            jsonField.push(newGrpSel);
          }
        });
        updatedObject = { ...updatedObject, [`${key}`]: jsonField };
        setJsonFiledSize(map.popupContent[`${key}`].length);
      } else if (key === 'zeps' && map.selectedProperty && map.selectedProperty.key === 'zeps') {
        const { path } = map.selectedProperty;
        map.popupContent[path[0]].forEach((zep, i) => {
          if (i !== path[1]) {
            jsonField.push(zep);
          } else {
            const newZep = {
              ...zep,
              OP_id: selectedObject.OP_id,
              RA_libelle: selectedObject.RA_libelle,
            };
            jsonField.push(newZep);
          }
        });
        updatedObject = { ...updatedObject, [`${key}`]: jsonField };
        setJsonFiledSize(map.popupContent[`${key}`].length);
      } else if (map.popupContent.key !== '') {
        updatedObject = { ...updatedObject, [`${key}`]: map.popupContent[`${key}`] };
      } else {
        updatedObject = { ...updatedObject, [`${key}`]: '' };
      }
    });
    dispatch(mapActions.updatePopupContent(updatedObject, map.selectedObjectLayer));
  };

  const updateLocalViewport = (newViewport, transitionDuration) => {
    setStateViewport(viewport);
    setIsFlying(true);
    setTimeout(() => {
      // Prevent redux viewport to transition twice
      delete newViewport.transitionDuration;
      delete newViewport.transitionInterpolator;

      dispatch(mapActions.updateViewport(newViewport, MAP_PATH));
      setIsFlying(false);
    }, transitionDuration);
  };

  const centerOnFeature = (feature) => {
    // let windowWidth = window.innerWidth;
    const windowWidth = Math.floor(window.innerWidth * 0.60);
    const windowHeight = window.innerHeight;

    const [minLng, minLat, maxLng, maxLat] = bbox(feature);
    const newViewport = new WebMercatorViewport({
      width: windowWidth,
      height: windowHeight,
    });

    // Test mobile or desktop view to place feature on top or right of the sidebar
    const paddingLeft = windowWidth < 1024 ? 100 : Math.floor(windowWidth * 0.60);
    const paddingBottom = windowWidth >= 1024 ? 200 : Math.floor(windowHeight * 0.60);
    const paddingRight = windowWidth < 1024 ? 100 : 200;
    const paddingTop = windowWidth < 1024 ? 150 : 200;

    const fitBoundsOptions = {
      padding: {
        top: paddingTop,
        bottom: paddingBottom,
        left: paddingLeft,
        right: paddingRight,
      },
      maxZoom: 16,
    };

    const { longitude, latitude, zoom } = newViewport.fitBounds([
      [minLng, minLat],
      [maxLng, maxLat],
    ], fitBoundsOptions);

    // To zoom in, use clamp(zoom, 16) — disabled waiting for correct boundaries from backend
    // flyTo(longitude, latitude, map.viewport.zoom, this.updateLocalViewport);
    if (zoom >= map.viewport.zoom) flyTo(longitude, latitude, zoom, updateLocalViewport);
  };

  const updateSelectedFeature = (feature) => {
    if (mapRef.current) {
      const { _ne: ne, _sw: sw } = mapRef.current.getMap().getBounds();
      const windowBbox = {
        type: 'Polygon',
        coordinates: [[
          [sw.lng, sw.lat],
          [ne.lng, sw.lat],
          [ne.lng, ne.lat],
          [sw.lng, ne.lat],
          [sw.lng, sw.lat],
        ]],
      };

      const bboxParams = {
        srid: 4326,
        bbox: windowBbox,
      };

      dispatch(mapActions.updateCoordinates(bboxParams));
    }

    if (feature.properties.id) {
      dispatch(mapActions.updateFeatureInfoClick(feature.properties.id, feature.source));
    } else if (feature.properties.OP_id) {
      console.log(feature);
      dispatch(mapActions.updateFeatureInfoClick(
        feature.properties.OP_id, feature.source, feature.sourceLayer,
      ));
    }
    const jdzSource = getSourceObjectByKey(MIDI_OBJECTS_KEYS.jdz);
    let selectedPropertySource;
    if (map.selectedProperty) {
      selectedPropertySource = getSourceObjectByKey(map.selectedProperty.requiredLayer);
    }

    // Object should update in creation and when user wants to update an extremity
    const shouldUpdateCdv = map.selectedObjectLayer
      && map.mode === MAP_MODES.modification
      && featureInfoClick
      && map.selectedObjectLayer.key === MIDI_OBJECTS_KEYS.cdv
      && (feature.source.includes(jdzSource) || map.popupContent.extremites[map.popupContent.extremites.length - 1].id_pk === '');
    const shouldUpdateSel = map.selectedObjectLayer
      && map.mode === MAP_MODES.modification
      && featureInfoClick
      && map.selectedObjectLayer.key === MIDI_OBJECTS_KEYS.sel
      && (map.popupContent.extremites[map.popupContent.extremites.length - 1].id_pk === ''
        || (map.selectedProperty && feature.source.includes(selectedPropertySource))
        || (map.selectedProperty && feature.sourceLayer.includes(selectedPropertySource)));
    const shouldUpdateGrpSel = map.selectedObjectLayer
      && map.mode === MAP_MODES.modification
      && featureInfoClick
      && map.selectedObjectLayer.key === MIDI_OBJECTS_KEYS.groupementSel
      && ((map.selectedProperty && feature.source.includes(selectedPropertySource))
        || (map.popupContent.groupements_catenaires
          && map.popupContent.groupements_catenaires[map.popupContent.groupements_catenaires.length - 1].OP_id === ''));
    const shouldUpdateZep = map.selectedObjectLayer
      && map.mode === MAP_MODES.modification
      && featureInfoClick
      && map.selectedObjectLayer.key === MIDI_OBJECTS_KEYS.zep
      && (map.popupContent.extremites[map.popupContent.extremites.length - 1].id_pk === ''
        || (map.selectedProperty && feature.source.includes(selectedPropertySource))
        || (map.selectedProperty && feature.sourceLayer.includes(selectedPropertySource)));
    const shouldUpdateGrpZep = map.selectedObjectLayer
      && map.mode === MAP_MODES.modification
      && featureInfoClick
      && map.selectedObjectLayer.key === MIDI_OBJECTS_KEYS.groupementZep
      && (map.selectedProperty && feature.source.includes(selectedPropertySource));
    const shouldUpdateItineraire = map.selectedObjectLayer
      && map.mode === MAP_MODES.modification
      && featureInfoClick
      && map.selectedObjectLayer.key === MIDI_OBJECTS_KEYS.itineraire
      && (map.selectedProperty && feature.source.includes(selectedPropertySource));
    const shouldUpdatePonctualObject = map.selectedObjectLayer
      && map.mode === MAP_MODES.modification
      && (map.selectedObjectLayer.key === MIDI_OBJECTS_KEYS.signal
        || map.selectedObjectLayer.key === MIDI_OBJECTS_KEYS.adv
        || map.selectedObjectLayer.key === MIDI_OBJECTS_KEYS.jdz)
      && (map.selectedProperty && feature.sourceLayer.includes(selectedPropertySource));
    const objectShouldUpdate = map.mode === MAP_MODES.creation
      || shouldUpdateCdv
      || shouldUpdateSel
      || shouldUpdateGrpSel
      || shouldUpdateZep
      || shouldUpdateGrpZep
      || shouldUpdateItineraire
      || shouldUpdatePonctualObject;

    if (objectShouldUpdate) {
      dispatch(mapActions.updateSelectedSourceObject(feature.properties));
      updateObject(feature.properties, feature.sourceLayer);
    } else {
      const selectedObjectLayer = getMidiObjectBySource(feature.layer['source-layer']);
      if (selectedObjectLayer.key === MIDI_OBJECTS_KEYS.itineraire && !Object.keys(feature.properties).includes(DETAILS_TABLE_FIELDS.jalon)) {
        dispatch(mapActions.updatePopupContent(
          { ...feature.properties, jalon: '' },
          selectedObjectLayer,
        ));
      } else if (selectedObjectLayer.key === MIDI_OBJECTS_KEYS.tivSch) {
        let newFields = {};
        if (!Object.keys(feature.properties).includes(DETAILS_TABLE_FIELDS.pk_sncf_debut)) {
          newFields = { ...newFields, pk_sncf_debut: '' };
        }
        if (!Object.keys(feature.properties).includes(DETAILS_TABLE_FIELDS.pk_sncf_fin)) {
          newFields = { ...newFields, pk_sncf_fin: '' };
        }
        if (!Object.keys(feature.properties).includes(DETAILS_TABLE_FIELDS.RA_libelle_ligne)) {
          newFields = { ...newFields, RA_libelle_ligne: '' };
        }
        if (!Object.keys(feature.properties).includes(DETAILS_TABLE_FIELDS.RA_libelle_voie)) {
          newFields = { ...newFields, RA_libelle_voie: '' };
        }
        if (!Object.keys(feature.properties).includes(DETAILS_TABLE_FIELDS.L_code)) {
          newFields = { ...newFields, L_code: '' };
        }
        if (!Object.keys(feature.properties).includes(DETAILS_TABLE_FIELDS.V_nom)) {
          newFields = { ...newFields, V_nom: '' };
        }

        dispatch(mapActions.updatePopupContent(
          { ...feature.properties, ...newFields },
          selectedObjectLayer,
        ));
      } else {
        dispatch(mapActions.updatePopupContent(
          feature.properties,
          selectedObjectLayer,
        ));
      }

      setDisplaySideBar(true);
    }
    centerOnFeature(feature);
  };

  const updateTiles = (tiles) => {
    const sourceLayer = map.trackSource === MAP_TRACK_SOURCES.schematic ? 'sch' : 'geo';
    // Create a Set so that all identical values are removed
    const set = new Set(tiles[sourceLayer].map((tile) => JSON.stringify(tile.layer)));
    // Build back the array
    const tilesSources = Array.from(set).map(JSON.parse);
    const mapInstance = mapRef.current.getMap();

    tilesSources.forEach((tileSource) => {
      const sourceId = tileSource.includes('tronconditinerairevoie')
        ? 'tiv-tiles'
        : `${tileSource}-${sourceLayer}-source`;
      const tempCacheUrl = `${MAP_URL}/chartisv1/tile/${tileSource}/${sourceLayer}/{z}/{x}/{y}/?dt=${Date.now()}`;
      // Set the tile url to a cache-busting url (to circumvent browser caching behaviour):
      try {
        mapInstance.getSource(sourceId).tiles = [tempCacheUrl];
      } catch (err) {
        return;
      }

      // Remove the tiles for a particular source
      mapInstance.style.sourceCaches[sourceId].clearTiles();

      // Load the new tiles for the current viewport (mapInstance.transform -> viewport)
      mapInstance.style.sourceCaches[sourceId].update(mapInstance.transform);

      // Force a repaint, so that the map will be repainted without you having to touch the map
      mapInstance.triggerRepaint();
    });
  };

  const onDeleteConfirm = () => {
    dispatch(mapActions.updateFeatureInfoClick(undefined, undefined));
    setFeatureInfoClick(undefined);
    setDisplaySideBar(false);
  };

  const onDeleteObject = async () => {
    const queryParams = {
      action_type: 'D',
      old_gaia_object: map.popupContent,
      layer_name: map.selectedObjectLayer.sourceTable,
    };

    setModalBody(<></>);
    setModalFooter(<div className="mx-auto"><Loader center /></div>);

    const res = await dispatch(mapActions.deleteObjects(queryParams));
    if (res) {
      const newModalHeaderTitle = t('Map.popupDetails.deleteSuccess');
      const newModalBody = (<></>);
      const newModalFooter = (
        <div className="d-flex align-items-end justify-content-start" style={{ width: '420px' }}>
          <div className="btn-group dropdown">
            <button
              type="button"
              className="btn btn-sm btn-primary"
              data-dismiss="modal"
              onClick={onDeleteConfirm}
            >
              <span>{t('common.ok')}</span>
            </button>
          </div>
        </div>
      );

      updateModal(
        newModalHeaderTitle,
        newModalBody,
        newModalFooter,
      );
      updateTiles(res.tiles);
    } else {
      const newModalHeaderTitle = t('Map.popupDetails.deleteNotice.object');
      const newModalBody = (<></>);
      const newModalFooter = <ErrorBoundary />;

      updateModal(
        newModalHeaderTitle,
        newModalBody,
        newModalFooter,
      );
    }
  };

  const getCursor = () => (featureInfoHover !== undefined ? 'pointer' : 'default');

  const toggleScrollZoom = () => {
    setScrollZoom(!scrollZoom);
  };

  const renderZoneLayer = () => {
    const { selectedZone, mode } = map;
    const isDrawModeEditing = draw.mode instanceof EditingMode;

    return selectedZone && mode === MAP_MODES.modification && !isDrawModeEditing
      ? <ZoneLayer />
      : null;
  };

  const usedViewport = isFlying ? stateViewport : viewport;

  const debouncedViewport = useDebounce(viewport);

  const sendUpdateObject = async (queryParams, geomSch) => {
    setModalBody(<></>);
    setModalFooter(<div className="mx-auto"><Loader center /></div>);

    const res = await dispatch(mapActions.updateObject(queryParams));
    if (res) {
      const newModalHeaderTitle = t('Map.popupDetails.modifSuccess');
      const newModalBody = (<></>);
      const newModalFooter = (
        <div className="d-flex align-items-end justify-content-start" style={{ width: '420px' }}>
          <div className="btn-group dropdown">
            <button
              type="button"
              className="btn btn-sm btn-primary"
              data-dismiss="modal"
            >
              <span>{t('common.ok')}</span>
            </button>
          </div>
        </div>
      );
      updateModal(
        newModalHeaderTitle,
        newModalBody,
        newModalFooter,
      );
      updateTiles(res.tiles);
      dispatch(mapActions.updateItinerairesImpactes([]));
      if (geomSch !== undefined) refreshData();
    } else {
      const newModalHeaderTitle = t('Map.popupDetails.validateNotice');
      const newModalBody = (<></>);
      const newModalFooter = <ErrorBoundary />;

      updateModal(
        newModalHeaderTitle,
        newModalBody,
        newModalFooter,
      );
    }
  };

  const onValidate = async () => {
    let geomSch;
    let queryParams;

    if (map.sidebarTab === '#split' && map.splitPk !== '') {
      queryParams = {
        action_type: 'S',
        old_gaia_object: {
          OP_id: map.popupContent.OP_id,
        },
        new_gaia_object: {
          pk_sncf: map.splitPk,
        },
        layer_name: map.selectedObjectLayer.sourceTable,
      };
    } else {
      if (displayGeoEditor && selectedData) {
        geomSch = buildCustomGeom();
      }
      let newObject = {};
      // Check for associated tables
      if (Object.keys(map.popupContent).includes(DETAILS_TABLE_FIELDS.tables_associees)) {
        Object.keys(map.popupContent).forEach((key) => {
          if (key !== DETAILS_TABLE_FIELDS.tables_associees && key !== DETAILS_TABLE_FIELDS.itineraires_impactes
            && key !== DETAILS_TABLE_FIELDS.tables_responsables) {
            newObject = { ...newObject, [`${key}`]: map.popupContent[key] };
          }
        });
      }
      // Check for responsibles tables
      if (Object.keys(map.popupContent).includes(DETAILS_TABLE_FIELDS.tables_responsables)) {
        Object.keys(map.popupContent).forEach((key) => {
          if (key !== DETAILS_TABLE_FIELDS.tables_responsables && key !== DETAILS_TABLE_FIELDS.itineraires_impactes
            && key !== DETAILS_TABLE_FIELDS.tables_associees) {
            newObject = { ...newObject, [`${key}`]: map.popupContent[key] };
          }
        });
      }
      // update impacted itineraries
      if (Object.keys(map.popupContent).includes(DETAILS_TABLE_FIELDS.itineraires_impactes) && map.itinerairesImpactes.length !== 0) {
        if (!Object.keys(map.popupContent).includes(DETAILS_TABLE_FIELDS.tables_associees)
        && !Object.keys(map.popupContent).includes(DETAILS_TABLE_FIELDS.tables_responsables)) {
          newObject = { ...map.popupContent, itineraires_impactes: map.itinerairesImpactes };
        } else {
          newObject = { ...newObject, itineraires_impactes: map.itinerairesImpactes };
        }
      }
      // update associated perimetres
      if (Object.keys(map.popupContent).includes(DETAILS_TABLE_FIELDS.perimetres_associes) && map.perimetresAssocies.length !== 0) {
        if (!Object.keys(map.popupContent).includes(DETAILS_TABLE_FIELDS.tables_associees)
        && !Object.keys(map.popupContent).includes(DETAILS_TABLE_FIELDS.tables_responsables)) {
          newObject = { ...map.popupContent, perimetres_associes: map.perimetresAssocies };
        } else {
          newObject = {
            ...newObject,
            perimetres_associes: map.perimetresAssocies.map((pr) => ({
              ...pr,
              table: (typeof pr?.table === 'string' ? pr?.table : pr?.table?.table) || '',
            })),
          };
        }
      }
      // update responsibles perimetres
      if (Object.keys(map.popupContent).includes(DETAILS_TABLE_FIELDS.perimetres_responsables) && map.perimetresResponsables.length !== 0) {
        if (!Object.keys(map.popupContent).includes(DETAILS_TABLE_FIELDS.tables_responsables)
        && !Object.keys(map.popupContent).includes(DETAILS_TABLE_FIELDS.tables_associees)) {
          newObject = { ...map.popupContent, perimetres_responsables: map.perimetresResponsables };
        } else {
          newObject = {
            ...newObject,
            perimetres_responsables: map.perimetresResponsables.map((pr) => ({
              ...pr,
              table: (typeof pr?.table === 'string' ? pr?.table : pr?.table?.table) || '',
            })),
          };
        }
      }

      newObject = JSON.stringify(newObject) === '{}' ? map.popupContent : newObject;
      newObject = geomSch === undefined
        ? newObject
        : {
          ...newObject,
          geomSch,
          isGeomSchFromLRS: false,
        };

      queryParams = {
        action_type: 'M',
        new_gaia_object: newObject,
        layer_name: map.selectedObjectLayer.sourceTable,
      };
    }

    await sendUpdateObject(queryParams, geomSch);
  };

  const onRestoreLRS = async () => {
    const queryParams = {
      action_type: 'M',
      new_gaia_object: {
        ...map.popupContent,
        isGeomSchFromLRS: true,
      },
      layer_name: map.selectedObjectLayer.sourceTable,
    };

    await sendUpdateObject(queryParams);
    await refreshData(true);
  };

  const onSideBarClose = () => {
    dispatch(mapActions.updateFeatureInfoClick(undefined, undefined));
    dispatch(mapActions.updateSideBarTab('#geom'));
    setFeatureInfoClick(undefined);
    setDisplaySideBar(false);
    dispatch(mapActions.updateItinerairesImpactes([]));
    dispatch(updateSelectedData());
  };

  useEffect(() => {
    if (map.sidebarTab === '#geoEditor') {
      setSchematicInteractiveLayers([...new Set([...geoEditorLayers, ...snappingLayers])]);
    } else {
      const newSchematicInteractiveLayers = [
        ...mvtLayers,
        ...map.elements.selected.objects.map((el) => `${el.sourceTable}Layer`),
      ];
      let signalList = QUALITY_SIGNALS;
      if (newSchematicInteractiveLayers.includes('map_midi_signalLayer')) {
        const selectedChild = map.elements.selected.objects
          .find((obj) => obj.key === MIDI_OBJECTS_KEYS.signal)
          .children
          .find((child) => child.selected);
        if ((selectedChild && selectedChild.key === 'qualitySignals') || map.mode === MAP_MODES.modification) {
          signalList = QUALITY_SIGNALS;
        }
        newSchematicInteractiveLayers.push(...signalList.map((type) => getSignalLayerId(type)));
      }
      setSchematicInteractiveLayers(newSchematicInteractiveLayers);
    }
  }, [map.sidebarTab, map.elements.selected.objects, infraEditor, snappingLayers]);

  const refreshData = async (forceRefresh = false) => {
    if (mapRef && (displaySideBar || map.mode === MAP_MODES.creation)) {
      const chartisParams = getChartisParams(mapRef);
      const tivResults = await getGeoJSONData(MIDI_OBJECTS_LAYERS.tivSch, chartisParams);
      dispatch(updateTiv(tivResults));

      if (map?.selectedObjectLayer.isLinear
          && map.elements.selected.objects.length > 0
          && map.mode !== MAP_MODES.creation) {
        await refreshLinearData(tivResults, chartisParams, featureInfoClick, forceRefresh);
      } else if (map?.selectedObjectLayer?.sourceTable === MIDI_OBJECTS_LAYERS.tivSch) {
        await refreshTivData(tivResults, featureInfoClick);
      } else if (map.mode === MAP_MODES.creation && map.selectedObjectToCreate !== undefined) {
        await refreshCreationData(tivResults, chartisParams);
      } else {
        dispatch(updateData());
        dispatch(updateSnappingLayers(INITIAL_SNAPPING_LAYERS));
        dispatch(updateJdz());
        dispatch(updateSignals());
      }
    }
  };

  useEffect(() => {
    const displayInfraEditor = displayGeoEditor && map.selectedObjectLayer?.sourceTable === MIDI_OBJECTS_LAYERS.tivSch;
    dispatch(toggleInfraEditor(displayInfraEditor));
    if (displayInfraEditor) {
      refreshData();
    }
  }, [map.selectedObjectLayer, displayGeoEditor]);

  // To enable GeoEditor on creation
  useEffect(() => {
    if (map.mode === MAP_MODES.creation && map.sidebarTab === '#geoEditor' && selectedData === undefined) {
      dispatch(updateEditorMode(MODES.drawLine));
    }
  }, [map.sidebarTab, map.mode, selectedData]);

  useEffect(() => {
    const shouldDisplayGeoEditor = ((editorMode instanceof EditingMode)
      || (editorMode instanceof ExtendLineStringMode)
      || (editorMode instanceof DrawLineStringMode))
      && map.sidebarTab === '#geoEditor'
      && map.mode !== MAP_MODES.display
      && debouncedViewport.zoom >= geoEditorOptions.minZoom;

    console.log('update', shouldDisplayGeoEditor);
    console.log('sidebartab', map.sidebarTab);

    if (!shouldDisplayGeoEditor && map.sidebarTab === '#geoEditor') {
      dispatch(mapActions.updateSideBarTab('#geom'));
    }
    dispatch(toggleShouldDisplay(shouldDisplayGeoEditor));
    refreshData();
  }, [
    map.sidebarTab, debouncedViewport.zoom, editorMode, map.elements.selected.objects,
    selectedData, map.mode,
  ]);

  const handleMouseMove = useCallback(debounce(() => {
    if (editorRef.current && editorRef.current !== null) {
      setIsDraggingFeature(editorRef.current.state.isDragging);
    }
  }, 100), [editorRef.current]);

  const onFeatureClick = (e) => {
    // Disable click when drawing and on GeoEditor mode
    if (draw.mode instanceof EditingMode || draw.mode instanceof DrawPolygonMode) return;
    if (displayGeoEditor) return;

    if (e.features?.length > 0) {
      setFeatureInfoClick(e);
      setFeatureInfoHover(undefined);
      if (e.features.length <= 2) {
        updateSelectedFeature(e.features[0]);
        if (map.mode !== MAP_MODES.creation) setDisplaySideBar(true);
      } else {
        setDisplayMultiplePopup(true);
      }
    } else {
      setFeatureInfoClick(null);
      dispatch(mapActions.updateFeatureInfoClick());
      dispatch(mapActions.updateSelectedProperty());
    }
  };

  const onFeatureHover = (e) => {
    if (draw.mode !== undefined
      && (draw.mode instanceof DrawPolygonMode || draw.mode instanceof EditingMode)) {
      return;
    }
    if (displayGeoEditor && editorMode instanceof ExtendLineStringMode) return;

    if (e.features?.length > 0) {
      if (e.features[0].properties.id) {
        dispatch(mapActions.updateFeatureInfoHover(
          e.features[0].properties.id, e.features[0].source,
        ));
      } else if (e.features[0].properties.OP_id) {
        dispatch(mapActions.updateFeatureInfoHover(
          e.features[0].properties.OP_id, e.features[0].source,
        ));
      }
      setFeatureInfoHover(e);
      snapOnHover(e, displaySideBar, editorRef);
    } else if (displaySideBar && snappedGeometry !== undefined) {
      dispatch(updateSnappedGeometry());
    } else if (map.featureInfoHoverID !== null) {
      dispatch(mapActions.updateFeatureInfoHover(null, null));
      setFeatureInfoHover(undefined);
    }
  };

  const renderModalHeader = () => (
    <div className="d-flex justify-content-between w-100">
      <div>
        <strong>{modalHeaderTitle}</strong>
      </div>
      <button type="button" className="close" aria-label="Close" data-dismiss="modal">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  );

  return (
    <>
      <MenuBar updateSelectedFeature={updateSelectedFeature} />
      <main className={`mastcontainer mastcontainer-map${main.fullscreen ? ' fullscreen' : ''}`}>

        <FiltersMenu />
        <ElementsMenu withHeader />
        {map.elements.selected.objects.length !== 0 && (
          <Caption
            items={map.elements.selected.objects}
          />
        )}
        <ReactMapGL
          {...usedViewport}
          style={{ cursor: 'pointer' }}
          width="100%"
          height="100%"
          mapStyle={map.mapStyle}
          onViewportChange={onViewportChange}
          ref={mapRef} // Needed to access the mapbox instance
          onHover={onFeatureHover}
          onClick={onFeatureClick}
          clickRadius={2} // Click made easier !
          getCursor={getCursor} // Change cursor following hoveringable (!) layer
          interactiveLayerIds={map.trackSource === MAP_TRACK_SOURCES.schematic ? (schematicInteractiveLayers) : (['geoMainLayer', 'geoServiceLayer'])} // Needed to allow getCursor & interactivity ,
          preventStyleDiffing
          scrollZoom={scrollZoom}
          onMouseMove={handleMouseMove}
          id="midi-map"
        >
          {map.trackSource === MAP_TRACK_SOURCES.schematic && !displayGeoEditor ? (
            <>
              <SchematicView />
              <ObjectLayer sourceLayer="sch" />
              {renderZoneLayer()}
            </>
          ) : null}
          {map.trackSource === MAP_TRACK_SOURCES.geographic ? (
            <>
              <GeographicView />
              <ObjectLayer sourceLayer="geo" />
              {renderZoneLayer()}
            </>
          ) : null}

          <CustomPopup isDraggingFeature={isDraggingFeature} featureInfoHover={featureInfoHover} />
          <MultipleObjectsPopup
            displayMultiplePopup={displayMultiplePopup}
            scrollZoom={scrollZoom}
            toggleScrollZoom={toggleScrollZoom}
            updateSelectedFeature={updateSelectedFeature}
            setDisplayMultiplePopup={setDisplayMultiplePopup}
            featureInfoClick={featureInfoClick}
          />

          <DrawToolLayer
            modalId={modalId}
            updateModal={updateModal}
          />

          <GeoEditor
            editorRef={editorRef}
            onSideBarClose={onSideBarClose}
            featureInfoClick={featureInfoClick}
          />
        </ReactMapGL>
        <CreationTools updateTiles={updateTiles} />
        <SideBarWrapper
          displaySideBar={displaySideBar}
          onDeleteObject={onDeleteObject}
          onValidate={onValidate}
          onRestoreLRS={onRestoreLRS}
          modalId={modalId}
          onSideBarClose={onSideBarClose}
          sideBarRef={sideBarRef}
          updateModal={updateModal}
        />
        <ModalSNCF htmlID={modalId}>
          <ModalHeaderSNCF>
            {renderModalHeader()}
          </ModalHeaderSNCF>
          <ModalBodySNCF>
            {modalBody}
          </ModalBodySNCF>
          <ModalFooterSNCF>
            {modalFooter}
          </ModalFooterSNCF>
        </ModalSNCF>
        <ButtonResetView updateLocalViewport={updateLocalViewport} />
        <ButtonResetViewport updateLocalViewport={updateLocalViewport} />
        <ButtonFullscreen />
      </main>
    </>
  );
};

export default Map;
