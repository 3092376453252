import axios from 'axios';
import qs from 'qs';
import { MAIN_API } from 'config/config';

const formatPath = (path) => `${MAIN_API.api}${path}${path.slice(-1) !== '/' ? '/' : ''}`;

const getAuthConfig = () => ({
  headers: {
    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
  },
});

export const get = async (path, params = undefined, repeatArray = false) => {
  const config = getAuthConfig();
  if (params) {
    config.params = params;
    if (repeatArray) config.paramsSerializer = (p) => qs.stringify(p, { arrayFormat: 'repeat' });
  }
  const res = await axios.get(formatPath(path), config);
  return res.data;
};

export const post = async (path, payload, config = {}) => {
  config = { ...getAuthConfig(), ...config };
  const res = await axios.post(formatPath(path), payload, config);
  return res.data;
};

export const patch = async (path, payload) => {
  const config = getAuthConfig();
  const { data } = await axios.patch(formatPath(path), payload, config);
  return data;
};

export const put = async (path, payload, config = {}) => {
  config = { ...getAuthConfig(), ...config };
  const { data } = await axios.put(formatPath(path), payload, config);
  return data;
};

export const deleteRequest = async (path) => {
  const config = getAuthConfig();
  const { data } = await axios.delete(formatPath(path), config);
  return data;
};
