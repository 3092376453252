import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Popup } from 'react-map-gl';
import { ExtendLineStringMode } from '@nebula.gl/edit-modes';

import { convertLayerVariables } from 'utils/helpers';
import PopupInfos from 'common/Map/Popup/PopupInfos';
import { PopupInfosCustomContent, PopupInfosCustomTitle } from 'components/PopupInfosCustom';

function CustomPopup({ featureInfoHover, isDraggingFeature }) {
  const {
    shouldDisplay: displayGeoEditor, mode: editorMode,
  } = useSelector((state) => state.geoEditor);
  const hidePopup = !featureInfoHover
      || (displayGeoEditor && editorMode instanceof ExtendLineStringMode)
      || (displayGeoEditor && isDraggingFeature)
      || (displayGeoEditor && (!featureInfoHover.features[0].source.includes('jointdezone') && !featureInfoHover.features[0].source.includes('signal')));
  if (!hidePopup) {
    const properties = convertLayerVariables(featureInfoHover.features[0].properties);
    if (!properties.longueur) {
      properties.longueur = properties.pkLigneF - properties.pkLigneD;
    }
    let backgroundColor;
    switch (properties.typeVoie) {
      case 'VP':
        backgroundColor = (properties.categVoie === 'VS') ? 'bg-danger' : 'bg-primary';
        break;
      default:
        backgroundColor = 'bg-secondary';
        break;
    }

    return (
      <Popup
        longitude={featureInfoHover.lngLat[0]}
        latitude={featureInfoHover.lngLat[1]}
        closeButton={false}
        className="mapboxgl-hover-custom-popup"
      >
        <PopupInfos
          title={<PopupInfosCustomTitle {...properties} />}
          content={<PopupInfosCustomContent {...properties} />}
          backgroundColor={backgroundColor}
        />
      </Popup>
    );
  }
  return null;
}

CustomPopup.propTypes = {
  isDraggingFeature: PropTypes.bool.isRequired,
  featureInfoHover: PropTypes.object,
};

CustomPopup.defaultProps = {
  featureInfoHover: undefined,
};

export default CustomPopup;
