import React from 'react';
import { Layer, Source } from 'react-map-gl';

function ModificationLayer({ data, paint }) {
  return (
    <Source
      id="modified-source"
      type="geojson"
      data={{
        type: 'FeatureCollection',
        features: data,
      }}
    >
      <Layer
        id="modified-lines"
        type="line"
        paint={{
          'line-color': ['case',
            ['==', ['get', 'isGeomSchFromLRS'], ['boolean', true]], '#FF0000',
            '#a1006b',
          ],
          'line-width': 3,
        }}
      />
      <Layer
        id="modified-points"
        type="circle"
        paint={{
          'circle-color': '#000',
          'circle-radius': 3,
        }}
      />
    </Source>
  );
}

export default ModificationLayer;
