import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FlyToInterpolator } from 'react-map-gl';
import { FaCompass } from 'react-icons/fa';
import * as mapActions from 'reducers/map';
import { MAP_PATH } from 'utils/router';

export default function ButtonResetViewport() {
  const dispatch = useDispatch();
  const map = useSelector((state) => state.map);

  const resetOrientation = () => {
    const newViewport = {
      ...map.viewport,
      bearing: 0,
      pitch: 0,
      transitionDuration: 1000,
      transitionInterpolator: new FlyToInterpolator(),
    };
    dispatch(mapActions.updateViewport(newViewport, MAP_PATH));
  };

  return (
    <button type="button" className="btn-rounded btn-rounded-white btn-resetviewport" onClick={resetOrientation}>
      <span className="sr-only">Reset north</span>
      <FaCompass />
    </button>
  );
}
