import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import TableSNCF from 'common/BootstrapSNCF/TableSNCF';
import ModalSNCF from 'common/BootstrapSNCF/ModalSNCF/ModalSNCF';
import ModalHeaderSNCF from 'common/BootstrapSNCF/ModalSNCF/ModalHeaderSNCF';
import ModalBodySNCF from 'common/BootstrapSNCF/ModalSNCF/ModalBodySNCF';
import ModalFooterSNCF from 'common/BootstrapSNCF/ModalSNCF/ModalFooterSNCF';
import * as allZonesActions from 'reducers/zones';
import { bindActionCreators } from 'redux';
import DatePickerSNCF, { PICKER_MODES } from 'common/BootstrapSNCF/DatePickerSNCF/DatePickerSNCF';
import Loader from 'common/Loader';
import InputSNCF from 'common/BootstrapSNCF/InputSNCF';
import { ADMIN_PATH, LOGS_PATH } from 'utils/router';
import ErrorBoundary from 'common/ErrorBoundaries/ErrorBoundary';

class RawZones extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    zonesActions: PropTypes.object.isRequired,
    zones: PropTypes.object.isRequired,
    tableHeaders: PropTypes.array.isRequired,
    filters: PropTypes.object,
    modalId: PropTypes.string.isRequired,
    pathName: PropTypes.string.isRequired,
    htmlId: PropTypes.string.isRequired,
    profile: PropTypes.object.isRequired,
  }

  static defaultProps ={
    filters: <></>,
  }

  constructor(props) {
    super(props);

    this.state = {
      modalHeader: '',
      modalBody: '',
      modalFooter: '',
      isLoading: true,
    };
  }

  componentDidMount = async () => {
    const { zonesActions, pathName } = this.props;
    try {
      this.setState({ isLoading: true });
      await zonesActions.getZones(pathName !== ADMIN_PATH);
      this.setState({ isLoading: false });
    } catch (e) {
      console.log(e);
    }
  }

  search = (value) => {
    const { zonesActions } = this.props;
    zonesActions.updateSearch(value);
  }

  onDelete = (id) => {
    const { t, zonesActions } = this.props;

    const modalHeader = (
      <div>
        <strong>{t('Logs.zones.confirmDelete')}</strong>
      </div>
    );
    const modalBody = (<></>);
    const modalFooter = (
      <ErrorBoundary>
        <div className="d-flex align-items-end justify-content-end" style={{ width: '420px' }}>
          <div className="btn-group dropdown">
            <button
              type="button"
              className="btn btn-sm btn-secondary mr-2"
              data-dismiss="modal"
            >
              <span>{t('Logs.zones.cancel')}</span>
            </button>
          </div>
          <div className="btn-group dropdown">
            <button
              type="button"
              data-dismiss="modal"
              className="btn btn-sm btn-danger"
              onClick={() => { zonesActions.deleteZone(id); }}
            >
              <span>{t('Logs.zones.delete')}</span>
            </button>
          </div>
        </div>
      </ErrorBoundary>
    );

    this.setState({ modalHeader, modalBody, modalFooter });
  }

  onValidate = (id) => {
    const {
      t, zonesActions, pathName,
    } = this.props;

    const modalHeader = (
      <div>
        <strong>{pathName === LOGS_PATH ? t('Logs.zones.confirmValidation') : t('Logs.zones.validationNotice')}</strong>
      </div>
    );
    const modalBody = (<></>);
    const modalFooter = (
      <ErrorBoundary>
        <div className="d-flex align-items-end justify-content-end" style={{ width: '420px' }}>
          <div className="btn-group dropdown">
            <button
              type="button"
              className="btn btn-sm btn-secondary mr-2"
              data-dismiss="modal"
            >
              <span>{t('Logs.zones.cancel')}</span>
            </button>
          </div>
          <div className="btn-group dropdown">
            <button
              data-dismiss="modal"
              type="button"
              className="btn btn-sm btn-primary"
              onClick={() => { zonesActions.updateStatus(pathName !== ADMIN_PATH, id, 1); }}
            >
              <span>{pathName === LOGS_PATH ? t('Logs.zones.submit') : t('Logs.zones.validate')}</span>
            </button>
          </div>
        </div>
      </ErrorBoundary>
    );

    this.setState({ modalHeader, modalBody, modalFooter });
  }

  getActions = (id) => {
    const { modalId, pathName } = this.props;

    const actions = pathName === ADMIN_PATH
      ? (
        <div className="cell-inner">
          <button
            className="btn btn-only-icon btn-transparent btn-color-gray btn-sm"
            type="button"
            title="Supprimer la zone"
            data-target={`#${modalId}`}
            data-toggle="modal"
            onClick={() => this.onDelete(id)}
          >
            <i className="icons-circle-delete icons-size-1x25 text-danger" aria-hidden="true" />
          </button>
        </div>
      )
      : (
        <div className="cell-inner">
          <button
            className="btn btn-only-icon btn-transparent btn-color-gray btn-sm"
            type="button"
            title="Soumettre la zone"
            data-target={`#${modalId}`}
            data-toggle="modal"
            onClick={() => this.onValidate(id)}
          >
            <i className="icons-checked icons-size-1x25 text-success" aria-hidden="true" />
          </button>
        </div>
      );
    return actions;
  }

  render() {
    const {
      t, zones, tableHeaders, modalId, htmlId, zonesActions, filters, pathName, profile,
    } = this.props;
    const {
      modalHeader, modalBody, modalFooter, isLoading,
    } = this.state;

    const {
      search, filteredItems, dateRange,
    } = zones;

    if (isLoading) {
      return (
        <div style={{ height: '50vh' }}>
          <Loader center />
        </div>
      );
    }

    const searchAppendOptions = {
      iconName: 'icons-search',
      onClick: () => {},
      name: 'zones-search',
    };

    return (
      <>
        <div className="row my-3">
          <div className="col-4 mr-5">
            <InputSNCF
              label={t('Logs.filters.search')}
              htmlId={`${htmlId}-search`}
              value={search}
              appendOptions={searchAppendOptions}
              onChange={(e) => this.search(e.target.value)}
              onClear={() => this.search('')}
              placeholder={t('Logs.filters.searchPlaceholder')}
              noMargin
            />
          </div>
          {filters}
          <div className="col-4 mr-5">
            <DatePickerSNCF
              htmlId={`${htmlId}-range`}
              mode={PICKER_MODES.range}
              date={dateRange}
              onChange={(date) => zonesActions.updateDateRange(date)}
              onClear={zonesActions.updateDateRange}
              placeholder={t('Logs.filters.datePickerPlaceholder')}
            />
          </div>
        </div>
        <div className="row" style={{ overflow: 'auto', maxHeight: '59vh' }}>
          <TableSNCF
            heads={tableHeaders}
            actions={this.getActions}
            content={filteredItems}
            modalId={pathName === ADMIN_PATH ? 'affectation-modal' : ''}
            itemsList={profile.users}
          />
        </div>
        <ModalSNCF htmlID={modalId}>
          <ModalHeaderSNCF>
            {modalHeader}
          </ModalHeaderSNCF>
          <ModalBodySNCF>
            {modalBody}
          </ModalBodySNCF>
          <ModalFooterSNCF>
            {modalFooter}
          </ModalFooterSNCF>
        </ModalSNCF>
      </>
    );
  }
}

const Zones = withTranslation()(RawZones);

const mapStateToProps = (state) => ({
  zones: state.zones,
  profile: state.profile,
});

const mapDispatchToProps = (dispatch) => ({
  zonesActions: bindActionCreators(allZonesActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Zones);
