import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FlyToInterpolator } from 'react-map-gl';
import { BiReset } from 'react-icons/bi';
import * as mapActions from 'reducers/map';
import { MAP_PATH } from 'utils/router';

export default function ButtonResetView() {
  const dispatch = useDispatch();
  const map = useSelector((state) => state.map);

  const resetOrientation = () => {
    const newViewport = {
      ...map.viewport,
      latitude: 46.88731499073388,
      longitude: 2.5682289198122756,
      zoom: 5.6650,
      bearing: 0,
      pitch: 0,
      transitionDuration: 500,
      transitionInterpolator: new FlyToInterpolator(),
    };
    localStorage.setItem('newViewport', JSON.stringify(newViewport));
    dispatch(mapActions.updateViewport(newViewport, MAP_PATH));
  };

  return (
    <button type="button" className="btn-rounded btn-rounded-white btn-resetview" onClick={resetOrientation}>
      <span className="sr-only">Reset north</span>
      <BiReset />
    </button>
  );
}
