import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import nextId from 'react-id-generator';

import { getMidiObjectBySource } from 'common/Map/Consts/MidiObjects';

const formatTitle = (result) => {
  const type = getMidiObjectBySource(result.properties.layer_slug).singular;
  const libelle = result.properties.RA_libelle;
  const libelleGare = result.properties.RA_libelle_gare;

  let title = type;

  if (libelle && libelle !== null) title += ` ${libelle}`;

  if (libelleGare && libelleGare !== null) title += ` - ${libelleGare}`;

  return title;
};

function SearchResults({ onResultClick }) {
  const { t } = useTranslation();
  const { searchResults } = useSelector((state) => state.map);

  if (searchResults.length === 0) {
    return (
      <>
        {t('Map.search.noResults')}
      </>
    );
  }

  return (
    <ul className="list-unstyled mb-0">
      {
        searchResults.map((result) => (
          <React.Fragment key={`search-result-${nextId()}`}>
            <li className="advanced-search-menu-item mt-0">
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  onResultClick(result);
                }}
              >
                {formatTitle(result)}
              </button>
            </li>
          </React.Fragment>
        ))
      }
    </ul>
  );
}

SearchResults.propTypes = {
  onResultClick: PropTypes.func.isRequired,
};

export default SearchResults;
