import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import * as allMapActions from 'reducers/map';
import * as allProfileActions from 'reducers/profile';
import * as allZonesActions from 'reducers/zones';
import * as allLogsActions from 'reducers/logs';
import { connect } from 'react-redux';

import Zones from 'components/Zones';
import History from 'components/History';
import { ZONE_TABLE_HEADERS, HISTORY_TABLE_HEADERS } from 'views/tableHeaders';

class RawLogs extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    mapActions: PropTypes.object.isRequired,
    zonesActions: PropTypes.object.isRequired,
    logsActions: PropTypes.object.isRequired,
    profileActions: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);

    this.historyModalId = 'roll-back-modal';
    this.zonesModalId = 'zones-modal';
  }

  componentDidMount = async () => {
    const {
      profileActions, mapActions, logsActions, zonesActions,
    } = this.props;

    mapActions.updateError(null, null);
    profileActions.updateError(null, null);
    zonesActions.updateError(null, null);
    logsActions.updateError(null, null);
  }

  resetErrors = () => {
    const { logsActions, zonesActions } = this.props;

    zonesActions.updateError(null, null);
    logsActions.updateError(null, null);
  }

  render() {
    const { t } = this.props;

    const zoneTableHeaders = ZONE_TABLE_HEADERS.filter((head) => !head.isAdmin);
    const historyTableHeaders = HISTORY_TABLE_HEADERS.filter((head) => !head.isAdmin);

    return (
      <>
        <div className="actionbar has-tabs">
          <div className="actionbar-head">
            <h1 className="mb-0">{t('Logs.titleLogs')}</h1>
          </div>
          <nav role="navigation" className="position-relative mt-2">
            <ul className="nav navtabs mb-0 dragscroll">
              <li className="navtabs-item pr-4">
                <a href="#zones" className="active" role="tab" data-toggle="tab" onClick={this.resetErrors}>
                  {t('Logs.tabs.zones')}
                </a>
              </li>
              <li className="navtabs-item pr-4">
                <a href="#historique" data-toggle="tab" onClick={this.resetErrors}>{t('Logs.tabs.history')}</a>
              </li>
            </ul>
          </nav>
        </div>
        <main role="main" className="mastcontainer bg-white">
          <div className="tab-content p-3">
            <div className="tab-pane fade show active" id="zones" role="tabpanel">
              <Zones
                tableHeaders={zoneTableHeaders}
                modalId={this.zonesModalId}
                pathName={window.location.pathname}
                htmlId="zones-search"
              />
            </div>
            <div className="tab-pane fade" id="historique" role="tabpanel">
              <History
                tableHeaders={historyTableHeaders}
                modalId={this.historyModalId}
                pathName={window.location.pathname}
                htmlId="history-search"
              />
            </div>
          </div>
        </main>
      </>
    );
  }
}

const Logs = withTranslation()(RawLogs);

const mapStateToProps = () => ({
});

const mapDispatchToProps = (dispatch) => ({
  mapActions: bindActionCreators(allMapActions, dispatch),
  profileActions: bindActionCreators(allProfileActions, dispatch),
  zonesActions: bindActionCreators(allZonesActions, dispatch),
  logsActions: bindActionCreators(allLogsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Logs);
