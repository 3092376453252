export const LAYER_VARIABLES = {
  SF_cv: 'cv',
  SF_id: 'id',
  SF_gmlId: 'gmlId',
  SF_ligne: 'ligne',
  SF_idGaia: 'idGaia',
  SF_codeTiv: 'codeTiv',
  SF_nomVoie: 'nomVoie',
  SF_pkVoieD: 'pkVoieD',
  SF_pkVoieF: 'pkVoieF',
  SF_codeNdvD: 'codeNdvD',
  SF_codeNdvF: 'codeNdvF',
  SF_codeVoie: 'codeVoie',
  SF_longueur: 'longueur',
  SF_objectid: 'objectid',
  SF_pkLigneD: 'pkLigneD',
  SF_pkLigneF: 'pkLigneF',
  pk_sncf_debut: 'pkSncfDe',
  pk_sncf_fin: 'pkSncfFi',
  SF_shapeLen: 'shapeLen',
  type_voie: 'typeVoie',
  TDV_mnemonique: 'categVoie',
  SF_codeLigne: 'codeLigne',
  SF_rgTroncon: 'rgTroncon',
  SF_geomMeasuredVoie: 'geomMeasuredVoie',
  SF_geomMeasuredLigne: 'geomMeasuredLigne',
  polymorphic_ctype_id: 'polymorphic_ctype_id',
  SF_tronconitineraireId: 'tronconitineraireId',
  RA_libelle: 'libelle',
  V_nom: 'nomVoie',
  L_code: 'codeLigne',
  pk_sncf: 'pk',
  OP_id: 'id',
  pk_debut_r: 'pkSncfDe',
  pk_fin_r: 'pkSncfFi',
  LIB_CI: 'libelle',
  PK_R: 'pk',
};
