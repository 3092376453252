import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { updateSideBarTab } from 'reducers/map';
import { MAP_MODES } from 'common/Map/const';
import { getMidiObjectByKey, MIDI_OBJECTS_KEYS } from 'common/Map/Consts/MidiObjects';
import SplitSideBar from 'components/Map/SideBar/SplitSideBar';

const SideBar = ({
  title, toolBar, content, addTools, onClose, ref,
}) => {
  const dispatch = useDispatch();
  const main = useSelector((state) => state.main);
  const map = useSelector((state) => state.map);
  const { t } = useTranslation();

  let displayGeoEditorTab = map.mode !== MAP_MODES.display;
  const displaySplitTab = map.mode === MAP_MODES.modification
                     && map.selectedObjectLayer !== undefined
                     && map.selectedObjectLayer.key === MIDI_OBJECTS_KEYS.tivSch;

  if (map.mode === MAP_MODES.creation) {
    const selectedObject = getMidiObjectByKey(map.selectedObjectToCreate.id);
    displayGeoEditorTab = selectedObject.isLinear;
  }

  return (
    <nav className={`sideBar-container${main.fullscreen ? ' fullscreen' : ''}`} ref={ref}>
      <div className="title-color">
        <div className="d-flex align-items-center justify-content-between ml-2">
          {title}
          <button className="btn btn-sm btn-transparent btn-color-white" type="button" onClick={onClose}>
            <i className="icons-close icons-size-x5" aria-hidden="true" />
          </button>
        </div>
        <div>
          {toolBar}
        </div>
      </div>
      <div className="sideBar-content">
        <div className="has-tabs">
          <nav role="navigation" className="ml-2">
            <ul className="nav navtabs sideBar-navbar dragscroll">
              <li className="navtabs-item pr-4">
                <a href="#geom" className={map.sidebarTab === '#geom' ? 'active' : ''} role="tab" data-toggle="tab" onClick={(e) => dispatch(updateSideBarTab(e.currentTarget.getAttribute('href')))}>
                  {t('Map.popupDetails.geom')}
                </a>
              </li>
              <li className="navtabs-item pr-4">
                <a href="#attributs" data-toggle="tab" onClick={(e) => dispatch(updateSideBarTab(e.currentTarget.getAttribute('href')))}>
                  {t('Map.popupDetails.attributs')}
                </a>
              </li>
              {displayGeoEditorTab && (
                <li className="navtabs-item pr-4">
                  <a href="#geoEditor" data-toggle="tab" onClick={(e) => dispatch(updateSideBarTab(e.currentTarget.getAttribute('href')))}>
                    {t('Map.popupDetails.userGeom')}
                  </a>
                </li>
              )}
              {displaySplitTab && (
                <li className="navtabs-item pr-4">
                  <a href="#split" data-toggle="tab" onClick={(e) => dispatch(updateSideBarTab(e.currentTarget.getAttribute('href')))}>
                    {t('Map.popupDetails.split')}
                  </a>
                </li>
              )}
            </ul>
          </nav>
        </div>
        <main role="main">
          <div className="tab-content mt-2">
            <div className="tab-pane fade show active" id="geom" role="tabpanel">
              {content}
            </div>
            <div className="tab-pane fade" id="attributs" role="tabpanel">
              {content}
            </div>
            <div className="tab-pane fade" id="geoEditor" role="tabpanel">
              {content}
            </div>
            <div className="tab-pane fade" id="split" role="tabpanel">
              <SplitSideBar />
            </div>
          </div>
        </main>
        {addTools}
      </div>
    </nav>
  );
};

SideBar.propTypes = {
  title: PropTypes.object.isRequired,
  toolBar: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired,
  addTools: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  ref: PropTypes.object.isRequired,
};

export default SideBar;
