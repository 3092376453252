import { store } from 'Store';
import * as mapActions from 'reducers/map';
import * as drawActions from 'reducers/draw';
import {
  MAP_URL, MAP_MODES, ELEMENT_TYPES, midiObjects,
} from 'common/Map/const';
import { FlyToInterpolator } from 'react-map-gl';
import { getFirstCoordinates } from 'utils/helpers';
import { EditingMode } from 'react-map-gl-draw';
import { ViewMode } from '@nebula.gl/edit-modes';
import { getMidiObjectByKey, MIDI_OBJECTS_KEYS } from 'common/Map/Consts/MidiObjects';

/**
 * Function to update a zone to be displayed in map
 * @param {object} zone - Zone to display
 */
export const updateSelectedZone = (zone) => {
  localStorage.removeItem('newViewport');
  const { map } = store.getState();

  const viewport = {
    ...map.viewport,
    latitude: zone.center_x,
    longitude: zone.center_y,
    zoom: zone.center_z,
  };

  console.log(viewport);

  const zoneGeoJSON = {
    type: 'Feature',
    geometry: zone.zone,
  };

  if (zone.owner_id) {
    // Update the zone data
    store.dispatch(mapActions.updateSelectedZone(zoneGeoJSON));

    // Update map mode to modification
    store.dispatch(mapActions.updateMode(MAP_MODES.modification));

    store.dispatch(drawActions.updateMode(new ViewMode()));
  } else {
    const feature = {
      ...zoneGeoJSON,
      properties: {
        renderType: zoneGeoJSON.geometry.type,
      },
    };
    console.log(feature);
    console.log(zone);

    // Delete selected zone in case it exists
    store.dispatch(drawActions.updateZone(zone));

    // Update the draw tool editor layer
    store.dispatch(drawActions.updateFeature(feature));

    // Switch to Editing mode
    store.dispatch(drawActions.updateMode(new EditingMode()));
  }

  // Redirect to map
  store.dispatch(mapActions.updateViewport(viewport, MAP_URL, true));
};

export const updateSelectedObject = (modification) => {
  if (
    modification.action_type !== 'M'
    || modification.new_gaia_object === null
    || modification.new_gaia_object.geomSch === null
  ) {
    return;
  }
  const { map: { viewport, featureSource, elements } } = store.getState();
  const { zoom: zoomViewport } = viewport;
  const { new_gaia_object: object, layer_name: layerName } = modification;
  const { OP_id: OPId, geomSch } = object;

  if (geomSch) {
    const geom = JSON.parse(geomSch);
    const [longitude, latitude, zoom] = getFirstCoordinates(geom, zoomViewport);

    const newViewport = {
      ...viewport,
      latitude,
      longitude,
      zoom,
    };

    // Update the object data
    store.dispatch(mapActions.updateFeatureInfoClick(OPId, featureSource));

    // show object layer if it's not selected yet
    const objectLayer = midiObjects.find((obj) => obj.sourceTable === layerName);
    if (!elements.selected.objects.includes(objectLayer)) {
      store.dispatch(mapActions.updateSelectedElement(objectLayer, ELEMENT_TYPES.objects, true));
    }

    // Update map mode to modification
    store.dispatch(mapActions.updateMode(MAP_MODES.display));

    // Redirect to map
    store.dispatch(mapActions.updateViewport(newViewport, MAP_URL));
  }
};

export const updateSelectedEvent = (event) => {
  const { map: { viewport } } = store.getState();
  const { geometry: geom } = event;

  const newViewport = {
    ...viewport,
    longitude: geom.coordinates[0],
    latitude: geom.coordinates[1],
    zoom: 12,
  };

  // show object layer if it's not selected yet

  // Redirect to map
  store.dispatch(mapActions.updateViewport(newViewport, MAP_URL));
};

export const updateSelectedProperty = (key, requiredLayer, keyList, path) => {
  const { map: { elements, selectedProperty, selectedObjectLayer } } = store.getState();

  let property = {
    key, requiredLayer, keyList, path,
  };
  if (selectedProperty && selectedProperty.key === key) {
    property = undefined;
  }
  store.dispatch(mapActions.updateSelectedProperty(property));

  // show object layer if it's not selected yet, and remove it when it's over
  if (requiredLayer && requiredLayer !== MIDI_OBJECTS_KEYS.tivSch) {
    const objectLayer = getMidiObjectByKey(requiredLayer);
    if (!elements.selected.objects.includes(objectLayer)) {
      store.dispatch(mapActions.updateSelectedElement(objectLayer, ELEMENT_TYPES.objects, true));
    } else if (property === undefined && objectLayer.key !== selectedObjectLayer.key) {
      store.dispatch(mapActions.updateSelectedElement(objectLayer, ELEMENT_TYPES.objects, false));
    }
  }
};

export const flyTo = (
  longitude, latitude, zoom, updateLocalViewport,
  transitionDuration = 1000, transitionInterpolator = new FlyToInterpolator(),
) => {
  const { map } = store.getState();
  const newViewport = {
    ...map.viewport,
    longitude,
    latitude,
    zoom,
    transitionDuration,
    transitionInterpolator,
  };
  updateLocalViewport(newViewport, transitionDuration);
};
